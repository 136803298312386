import React from 'react';

// import QImages from './data/QImages';

export default function ToggleCard({ data: { q, url } = {}, number }) {
  return (
    <>
      <div
        className={`relative box-border bg-cover bg-center bg-no-repeat my-4 mb-14 drop-shadow active`}>
        <div className="w-full h-full z-[-1] absolute question_diagonal_one bg-swiss-300"></div>
        <div className="w-full h-full z-[-1] absolute right-0 bottom-0 question_diagonal_two bg-swiss-150"></div>
        <div className="w-full h-full z-[-1] absolute left-0 bottom-0 question_diagonal_three bg-swiss-300"></div>
        <div className="w-full h-full z-[-1] absolute right-0 top-0 question_diagonal_four bg-swiss-300"></div>
        <div className="absolute top-0 left-0 -mt-1  w-8 h-8 text-center flex items-center justify-center bg-white number">
          {number}
        </div>
        <div className="px-12 py-6">
          <div className="text-white text-[1.125rem] leading-7">
            <p dangerouslySetInnerHTML={{ __html: q }}></p>
          </div>
        </div>
      </div>
      {/* <div className="">
        <QImages data={url.replace(/\[|\]/g, '').split(',')} />
      </div> */}
    </>
  );
}
