import React, { useContext } from 'react';
import { AdminContext } from '../contexts/admin.context';

import SwissLanding from './SwissLanding';
import { t } from 'i18next';
import { getTranslation } from '../utils/translation';

const RegistrationLandingPage = () => {
  const {
    themeId,
    teamType,
    tRegion,
    portalConfig: { hasRegistrationWebpage } = ({} = {})
  } = useContext(AdminContext);

  if (!hasRegistrationWebpage)
    return (
      <div className="h-screen flex items-center justify-center bg-white">
        <p className="text-3xl text-center p-4">{getTranslation(t, 'landing.notAvailable')}</p>
      </div>
    );

  if (themeId?.toLowerCase() === 'ch' && teamType?.toLowerCase() === 'team')
    return <SwissLanding themeId={themeId} teamType={teamType} tRegion={tRegion} />;

  if (themeId?.toLowerCase() === 'ch' && teamType?.toLowerCase() === 'school')
    return <SwissLanding themeId={themeId} teamType={teamType} tRegion={tRegion} />;

  return (
    <div className="h-screen flex items-center justify-center bg-white">
      <p className="text-3xl text-center p-4">{getTranslation(t, 'landing.underConstruction')}</p>
    </div>
  );
};

export default RegistrationLandingPage;
