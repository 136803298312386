import axios from 'axios';
import { toast } from 'react-toastify';

const WORLDSKILLS_API_URL = process.env.REACT_APP_WORLDSKILLS_API_URL;

export const teacherApi = axios.create({
  baseURL: process.env.REACT_APP_PSYFIERS_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const teacherApiDirect = axios.create({
  headers: {
    'Content-Type': 'application/json'
  }
});

export const setAuthToken = (token) => {
  teacherApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const setOrgHeaders = (orgPrefix, team) => {
  teacherApi.defaults.headers.common['orgPrefix'] = orgPrefix;
  teacherApi.defaults.headers.common['team'] = team;
};

teacherApi.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response?.status === 401) {
      try {
        localStorage.removeItem('token');
        const { orgPrefix, team } = teacherApi.defaults.headers.common;
        if (orgPrefix && team) {
          window.location.href = `/${orgPrefix}/${team}/login`;
        } else {
          // window.location.href = '/not-found';
        }
      } catch (error) {
        console.log(error);
        toast.error('Something went wrong. Please try again later.', error);
        // window.location.href = '/not-found';
      }
    }
    return Promise.reject(error);
  }
);

export const getPortalDetails = (prefix, type) => {
  return axios.get(
    `${WORLDSKILLS_API_URL}/public/get-portal-details/${prefix?.toLowerCase()}/${type?.toLowerCase()}`
  );
};

export const fetchTranslations = () => {
  return axios.get(`${WORLDSKILLS_API_URL}/public/translations/g-teachers-portal`);
};

export const checkRegisteredTeacher = (body, orgPrefix, team) => {
  return teacherApi.post(`/auth/teacher/user/exists/${orgPrefix}/${team}`, body);
};

export const registerTeacher = (body, orgPrefix, team) => {
  return teacherApi.post(`/auth/teacher/security/register/${orgPrefix}/${team}`, body);
};

export const registerTeacherEmailForSSKAdmin = (body) => {
  return axios.post(`${WORLDSKILLS_API_URL}/public/teacher-registration-email-ssk-admin`, body);
};

export const login = (body, orgPrefix, team) => {
  return teacherApi.post(`/auth/teacher/security/login/${orgPrefix}/${team}`, body);
};

export const forgetpasswordAPI = (body, orgPrefix, team) => {
  return teacherApi.post(`/auth/teacher/security/reset/sendtoken/${orgPrefix}/${team}`, body);
};

export const verifySecurityCodeAPI = (body, orgPrefix, team) => {
  return teacherApi.post(`/auth/teacher/security/reset/password/${orgPrefix}/${team}`, body);
};

export const changePassword = (body, orgPrefix, team) => {
  return teacherApi.post(`/auth/teacher/security/change/password/${orgPrefix}/${team}`, body);
};

export const getAllProjects = (orgPrefix, team) => {
  return teacherApi.get(`/public/projects/${orgPrefix}/${team}`);
};

export const getPublicProjectsOfTeacher = (orgPrefix, team, aid) => {
  return teacherApi.get(`/public/projects/${orgPrefix}/${team}/${aid}`);
};

export const getTanDetailsPublic = (orgPrefix, team, tan) => {
  return teacherApi.get(`/public/tan/${orgPrefix}/${team}/${tan}`);
};

export const getUserDetails = () => {
  return teacherApi.get('/teachers/user');
};

export const getAccountDetails = () => {
  return teacherApi.get('/teachers/account');
};

export const loginMFT = (body) => {
  return teacherApi.post('security/login', body);
};

export const updateUser = (body) => {
  return teacherApi.put('/teachers/user', body);
};

export const updateAccountDetails = (body) => {
  return teacherApi.put('/teachers/account', body);
};

export const createProject = (body) => {
  return teacherApi.post('/teachers/project', body);
};

export const getProjects = () => {
  return teacherApi.get('/teachers/project');
};

export const updateProject = (project) => {
  return teacherApi.put('/teachers/project', project);
};

export const deleteProject = (pid) => {
  return teacherApi.delete('/teachers/project', { params: { pid } });
};

export const getParticipantsCount = (pid) => {
  return teacherApi.get(`/teachers/project-participants/${pid}`);
};

export const addParticipants = (pid, count) => {
  return teacherApi.post(`/teachers/project-participants/${pid}`, { count });
};

export const getTANInfo = (pid, tan) => {
  return teacherApi.get(`/teachers/tan/${pid}`, {
    tan
  });
};

export const getUsedTANsList = (pid) => {
  return teacherApi.get(`/teachers/tan/${pid}`);
};

export const bookProfileTypeApi = (body) => {
  return teacherApi.post('/teachers/bookProfileType', body);
};

export const generateResultPDF = (params) => {
  return teacherApiDirect.get('results/generate/pdf', {
    params,
    responseType: 'blob'
  });
};

export const getAccessCodesListExcel = (params) => {
  return teacherApiDirect.get('project/excel/file', {
    params,
    responseType: 'blob'
  });
};

export const getAllResultsInCombinedPDF = (params) => {
  return teacherApiDirect.get('productionlist/combine/result', {
    params,
    responseType: 'blob'
  });
};

export const getAllResultsInSeparatePDFs = (params) => {
  return teacherApiDirect.get('productionlist/combine/result/zip', {
    params,
    responseType: 'blob'
  });
};

export const getSchools = () => {
  return axios.get(`${WORLDSKILLS_API_URL}/public/vocational-school`);
};

export const getStudentProfilesPDFZip = (body) => {
  return axios.post(WORLDSKILLS_API_URL + '/public/generate-student-pdf-zip', body);
};

export const generateStudentPDF = (url, id) => {
  return axios.post(`${WORLDSKILLS_API_URL}/public/generate-pdf`, {
    url,
    id,
    pdfType: true,
    viewportConfig: {
      width: 800,
      height: 1300
    },
    pdfConfig: {
      format: 'A4',
      landscape: false,
      fullPage: true
    }
  });
};

export const getAllWorkshops = () => {
  return teacherApi.get('/teachers/workshops/all-workshops');
};

export const getWorkshopDetails = (id) => {
  return teacherApi.get('/teachers/workshops', {
    params: { id }
  });
};

export const deleteWorkshop = (id) => {
  return teacherApi.delete('/teachers/workshops', {
    params: { id }
  });
};

export const createWorkshop = (body) => {
  return teacherApi.post('/teachers/workshops', body);
};

export const rescheduleWorkshop = (body) => {
  return teacherApi.put('/teachers/workshops', body);
};

export const updateWorkshopGroupSize = (body) => {
  return teacherApi.patch('/teachers/workshops', body);
};

export const resendWorkshopBookingEmail = (body) => {
  return teacherApi.post('/teachers/workshops/resend-booking-email', body);
};

export const getSummaryPDFLink = (id) => {
  return teacherApi.get('/teachers/workshops/workshop-summary-link', {
    params: { id }
  });
};

export const transferStudent = (orgPrefix, team, body) => {
  return teacherApi.post(`/public/transfer-student/${orgPrefix}/${team}`, body);
};

export const storeStats = (body) => {
  return axios.post(WORLDSKILLS_API_URL + '/public/stats', body);
};
