import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { AdminContext } from '../../contexts/admin.context';
import { AccountInfoSchema } from '../../schema/profile.schema';
import { getAccountDetails, updateAccountDetails } from '../../service';

import Button from '../common/Button';
import AdminInput from '../common/AdminInput';
import { ThumbsUpIcon } from '../icons';
import { getTranslation } from '../../utils/translation';

const AccountInfo = () => {
  const {
    setPopupInfo,
    themeId,
    user,
    setUser,

    teamType,
    themeConfig: { buttonRadius } = {},
    portalConfig: { regions, establishments, academies, registrationForm } = {}
  } = useContext(AdminContext);

  const [accountDetails, setAccountDetails] = useState({});

  const { t, i18n } = useTranslation();

  const fieldEnabled = (fieldName) => {
    return registrationForm.find((field) => field.id === fieldName)?.enabled;
  };

  const handleSubmit = async (formValues) => {
    let updatedAccountDetails = { ...accountDetails };

    try {
      updatedAccountDetails.companyName = formValues.companyName;
      updatedAccountDetails.addresses[0].street = formValues.region;
      updatedAccountDetails.addresses[0].postcode = formValues.postalCode;
      updatedAccountDetails.addresses[0].city = formValues.city;
      updatedAccountDetails.addresses[0].postbox = formValues.postbox;
      updatedAccountDetails = {
        ...updatedAccountDetails,
        specs: {
          ...updatedAccountDetails?.specs,
          academy: formValues.academy,
          establishment: formValues.establishment,
          schoolOrganization: formValues.schoolOrganization
        }
      };

      await updateAccountDetails(updatedAccountDetails);
      const copyUser = { ...user };
      copyUser.accounts[0].companyName = formValues.companyName;
      setUser({ ...copyUser, address: updatedAccountDetails.addresses });
      // show succcess popup
      setPopupInfo({
        isVisible: true,
        icon: ThumbsUpIcon,
        heading: getTranslation(t, 'accountInfo.ChangesUpdated')
      });
    } catch (e) {
      toast.error(getTranslation(t, 'accountInfo.errors.failedUpload'));
      console.error('Failed to update account details:', e);
    }
  };

  const formik = useFormik({
    initialValues: {
      accountId: '',
      companyName: '',
      establishment: '',
      region: '',
      academy: '',
      schoolOrganization: '',
      postalCode: '',
      city: ''
    },
    validationSchema: AccountInfoSchema,
    onSubmit: handleSubmit
  });

  // Load account info
  useEffect(() => {
    const fetchAccountDetails = async () => {
      if (!themeId) return;

      try {
        const response = await getAccountDetails();
        // set form values
        // set in state - to be used for API
        const { data: details } = response;
        setAccountDetails(details);

        // form values
        const formValues = {
          accountId: details.id,
          companyName: details.companyName,
          establishment: details?.specs?.establishment,
          region: details.addresses[0].street,
          academy: details?.specs?.academy,
          schoolOrganization: details?.specs?.schoolOrganization,
          postalCode: details.addresses[0]?.postcode,
          city: details.addresses[0]?.city,
          postalCode: details.addresses[0]?.postcode
        };
        formik.setValues(formValues, false);
      } catch (e) {
        toast.error(getTranslation(t, 'accountInfo.errors.failedUpload'));
        console.error('Failed to load account details:', e);
      }
    };

    fetchAccountDetails();
  }, [themeId, teamType]);

  return (
    <>
      <div className="mt-12 w-full">
        <h1
          className="text-2xl font-bold text-left text-cblack-primary mb-2.5"
          dangerouslySetInnerHTML={{
            __html: getTranslation(t, `accountInfo.profile.accountInfo.title`)
          }}
        />

        <div className="mt-5 max-w-xl">
          <form onSubmit={formik.handleSubmit} className="mb-8">
            <AdminInput
              label={getTranslation(
                t,
                `accountInfo.profile.accountInfo.formFields.accountId.label`
              )}
              placeholder={getTranslation(
                t,
                `accountInfo.profile.accountInfo.formFields.accountId.placeholder`
              )}
              type="text"
              name="accountId"
              formik={formik}
              disabled
            />

            {fieldEnabled('type') && (
              <>
                <label className="block mb-1 text-base font-bold text-cblack-primary">
                  {getTranslation(
                    t,
                    `accountInfo.profile.accountInfo.formFields.establishment.label`
                  )}
                </label>
                <select
                  className={`px-4 py-3.5 w-full mb-8 text-gray-primary bg-gray-100 rounded-md`}
                  style={{ borderRadius: buttonRadius }}
                  id="establishment"
                  name="establishment"
                  value={formik.values.establishment}
                  onChange={(event) => {
                    formik.setFieldValue('establishment', event.target.value);
                  }}>
                  {establishments.map((establishment) => (
                    <option
                      key={establishment._id}
                      value={establishment._id}
                      data-id={establishment._id}>
                      {establishment['name_' + i18n.language]}
                    </option>
                  ))}
                </select>
              </>
            )}
            <AdminInput
              label={getTranslation(
                t,
                `accountInfo.profile.accountInfo.formFields.companyName.label`
              )}
              placeholder={getTranslation(
                t,
                `accountInfo.profile.accountInfo.formFields.companyName.placeholder`
              )}
              type="text"
              name="companyName"
              formik={formik}
            />

            <label className="block mb-1 text-base font-bold text-cblack-primary">
              {getTranslation(t, `accountInfo.profile.accountInfo.formFields.region.label`)}
            </label>
            <select
              className={`px-4 py-3.5 w-full mb-8 text-gray-primary bg-gray-100 rounded-md`}
              style={{ borderRadius: buttonRadius }}
              id="region"
              name="region"
              value={formik.values.region}
              onChange={(event) => {
                const selectedOption = event.target.options[event.target.selectedIndex];
                const selectedRegionId = selectedOption.getAttribute('data-id');
                formik.setFieldValue('region', event.target.value);
                formik.setFieldValue('postbox', selectedRegionId);
              }}>
              {regions.map((region) => (
                <option key={region._id} value={region._id} data-id={region._id}>
                  {region['name_' + i18n.language]}
                </option>
              ))}
            </select>
            {fieldEnabled('academy') && (
              <>
                <label className="block mb-1 text-base font-bold text-cblack-primary">
                  {getTranslation(t, `accountInfo.profile.accountInfo.formFields.academy.label`)}
                </label>
                <select
                  className={`px-4 py-3.5 w-full mb-8 text-gray-primary bg-gray-100 rounded-md`}
                  style={{ borderRadius: buttonRadius }}
                  id="academy"
                  name="academy"
                  value={formik.values.academy}
                  onChange={(event) => {
                    formik.setFieldValue('academy', event.target.value);
                  }}>
                  {academies.map((academy) => (
                    <option key={academy._id} value={academy._id} data-id={academy._id}>
                      {academy['name_' + i18n.language]}
                    </option>
                  ))}
                </select>
              </>
            )}

            {fieldEnabled('postalCode') && (
              <AdminInput
                label={getTranslation(
                  t,
                  `accountInfo.profile.accountInfo.formFields.postalCode.label`
                )}
                placeholder={getTranslation(
                  t,
                  `accountInfo.profile.accountInfo.formFields.postalCode.placeholder`
                )}
                type="text"
                name="postalCode"
                formik={formik}
              />
            )}
            {fieldEnabled('city') && (
              <AdminInput
                label={getTranslation(t, `accountInfo.profile.accountInfo.formFields.city.label`)}
                placeholder={getTranslation(
                  t,
                  `accountInfo.profile.accountInfo.formFields.city.placeholder`
                )}
                type="text"
                name="city"
                formik={formik}
              />
            )}

            <Button buttonType="primary" type="submit">
              {getTranslation(t, `accountInfo.profile.accountInfo.actionButtons.save`)}
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AccountInfo;
