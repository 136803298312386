import React from 'react';
import { useTranslation } from 'react-i18next';
import { getTranslation } from '../../utils/translation';

const BaseFooter = () => {
  const { t } = useTranslation();

  return (
    <div className="flex bg-cgrey-300 text-sm justify-between py-7 sm:px-28 px-5">
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html: getTranslation(t, 'landing.baseFooter.copyright')
          }}
        />
      </div>
      <div className="flex sm:gap-10 gap-4">
        <div className="cursor-pointer">
          <a
            href={getTranslation(t, 'landing.baseFooter.imprint.link')}
            target="_blank"
            rel="noreferrer">
            <span
              dangerouslySetInnerHTML={{
                __html: getTranslation(t, 'landing.baseFooter.imprint.text')
              }}
            />
          </a>
        </div>
        <div className="cursor-pointer">
          <a
            href={getTranslation(t, 'landing.baseFooter.disclaimer.link')}
            target="_blank"
            rel="noreferrer">
            <span
              dangerouslySetInnerHTML={{
                __html: getTranslation(t, 'landing.baseFooter.disclaimer.text')
              }}
            />
          </a>
        </div>
        <div className="cursor-pointer">
          <a
            href={getTranslation(t, 'landing.baseFooter.dataProtection.link')}
            target="_blank"
            rel="noreferrer">
            <span
              dangerouslySetInnerHTML={{
                __html: getTranslation(t, 'landing.baseFooter.dataProtection.text')
              }}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default BaseFooter;
