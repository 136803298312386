import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminContext } from '../contexts/admin.context';

import { LockIcon } from '../components/icons';
import ChangePassword from './ChangePassword';
import { trackEvent } from '../utils';
import { getTranslation } from '../utils/translation';

const Welcome = () => {
  const { t } = useTranslation();
  const { themeId, user, setContentPopupInfo } = useContext(AdminContext);

  const showPasswordRecommendationPopup = () => {
    // show password popup
    setContentPopupInfo({
      isVisible: false,
      content: (
        <div className="flex flex-col items-center">
          <LockIcon />
          <h2 className="heading font-bold text-lg mt-4 mb-2">
            {getTranslation(t, 'welcome.changeYourPassword')}
          </h2>
          <p className="description text-base text-gray-500 mb-4">
            {getTranslation(t, 'welcome.changePasswordForFutureConnections')}
          </p>
          <ChangePassword isPopupDisplay={false} />
        </div>
      )
    });
  };

  useEffect(() => {
    try {
      trackEvent('page_view', {
        organization: themeId === 'ch' ? 'SSK' : 'WSF'
      });

      if (user) {
        const showPasswordRecommendation = user?.specs?.showPasswordUpdateRecommendation;

        if (!showPasswordRecommendation) {
          showPasswordRecommendationPopup();
        }
      }
    } catch (error) {
      console.error('ERROR', error);
    }
  }, [themeId]);

  return (
    <div className="flex flex-col items-start h-calc-vh overflow-y-auto">
      <div className="mb-5 w-full">
        <h1 className="text-2xl font-bold text-left text-cblack-primary mb-2.5">
          {getTranslation(t, 'welcome.header')}
        </h1>

        <div dangerouslySetInnerHTML={{ __html: getTranslation(t, 'welcome.body') }}></div>
      </div>
    </div>
  );
};

export default Welcome;
