import * as yup from 'yup';

export const LoginSchema = yup.object().shape({
  email: yup.string().email('validEmail').required('email'),
  password: yup.string().required('password')
});

export const forgetPasswordSchema = yup.object().shape({
  email: yup.string().email('validEmail').required('email')
});

export const securityCodeSchema = yup.object().shape({
  securityCode: yup.string().required('securityCode')
});
