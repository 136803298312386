import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { AdminContext } from '../contexts/admin.context';
import {
  ContactIcon,
  DownloadsIcon,
  FolderPlusIcon,
  HandshakeIcon,
  InfoCircleIcon,
  ServerIcon,
  SettingsLineIcon,
  HeadPhone,
  Calendar
} from './icons';

import Header from './Header';
import MenuItem from './common/MenuItem';
import MenuItemWithDropDown from './common/MenuItemWithDropdown';
import MenuItemContent from './common/MenuItemContent';
import { BookProfileTypeForm } from './forms';
import { hexToRgba } from '../utils/colors';
import { getTranslation } from '../utils/translation';

const menuItems = [
  { id: 'welcome' },
  {
    fixed: true,
    id: 'profile',
    nestedItems: [{ id: 'account' }, { id: 'password' }]
  },
  { id: 'guide' },
  { id: 'classes', nestedItems: [{ id: 'create-project' }, { id: 'projects' }] },
  { id: 'mission-future-class' },
  { id: 'mission-future-event' },
  { id: 'downloads' },
  { id: 'about-us' },
  { id: 'contact' }
];

const Sidebar = ({ openSidebar, setOpenSidebar }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [menuList, setMenuList] = useState([]);
  const [profileBooking, setProfileBooking] = useState(false);
  const {
    themeId,
    teamType,
    user,
    themeConfig: { primary, secondary, intensitySecondary } = {},
    portalConfig: {
      logo,
      schoolLogo,
      teamLogo,
      adminPanelTabs,
      hasClassStudentSetup,
      hasWorkshopPortal
    } = {},
    setContentPopupInfo
  } = useContext(AdminContext);

  const sidebarColor = hexToRgba(secondary, intensitySecondary);
  const basePath = `/${themeId}/${teamType}`;
  const currentPath = pathname.replace(basePath + '/', '');

  const getIcon = (id, isSelected) => {
    let Icon = null;
    switch (id) {
      case 'welcome':
        Icon = HandshakeIcon;
        break;

      case 'guide':
        Icon = ServerIcon;
        break;

      case 'create-project':
        Icon = FolderPlusIcon;
        break;

      case 'projects':
        Icon = SettingsLineIcon;
        break;

      case 'mission-future-class':
      case 'mission-future-event':
        Icon = InfoCircleIcon;
        break;

      case 'downloads':
        Icon = DownloadsIcon;
        break;

      case 'about-us':
        Icon = InfoCircleIcon;
        break;

      case 'contact':
        Icon = ContactIcon;
        break;

      case 'workshops':
        Icon = HeadPhone;
        break;

      case 'book-profile-type':
        Icon = Calendar;
        break;

      default:
        break;
    }

    return Icon ? <Icon isSelected={isSelected} /> : null;
  };

  useEffect(() => {
    const specificTabsList = adminPanelTabs?.[user?.address?.[0]?.street] ?? adminPanelTabs.default;

    const activeMenu = menuItems.filter((item) => {
      if (item.id === 'classes' && hasClassStudentSetup) return item;
      if (item.fixed) return item;
      if (specificTabsList.find((tab) => tab.id === item.id && tab.enabled)) {
        return item;
      }
    });

    const profileBookingSetup = specificTabsList.find(
      (item) => item.id === 'book-profile-type' && item.enabled
    );
    if (profileBookingSetup) {
      setProfileBooking(profileBookingSetup?.subOptions);
    }

    setMenuList(activeMenu);
  }, [user, themeId]);

  return (
    <div
      className={`${clsx(
        'fixed top-0 left-0 py-8 px-6 lg:px-2',
        'flex justify-between flex-col',
        'h-screen',
        'h-calc-6vh',
        'overflow-hidden',
        'overflow-y-auto',
        'no-sidebar',
        'bg-white flex-shrink-0 w-full z-40 sm:w-[366px] lg:flex lg:rounded-b lg:px-5 lg:shadow-lg'
      )} `}
      style={{ boxShadow: `2px 2px 12px ${primary}` }}>
      <div className="relative">
        <button
          className="absolute -top-2.5 left-[0px] p-2 w-8 h-8"
          style={{ display: openSidebar ? '' : 'none' }}
          onClick={() => setOpenSidebar(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            className="w-full h-full">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.6996 10.6996C11.267 10.1322 11.267 9.21223 10.6996 8.64481L7.73136 5.67655L10.8029 2.60502C11.3703 2.0376 11.3703 1.11763 10.8029 0.55021C10.2355 -0.0172106 9.3155 -0.0172106 8.74807 0.55021L5.67655 3.62174L2.48038 0.425566C1.91296 -0.141855 0.992986 -0.141855 0.425565 0.425565C-0.141855 0.992986 -0.141855 1.91296 0.425566 2.48038L3.62174 5.67655L0.528825 8.76946C-0.0385955 9.33688 -0.0385955 10.2569 0.528825 10.8243C1.09625 11.3917 2.01622 11.3917 2.58364 10.8243L5.67655 7.73136L8.64481 10.6996C9.21224 11.267 10.1322 11.267 10.6996 10.6996Z"
              fill="black"
            />
          </svg>
        </button>
        <Header
          logo={
            teamType === 'school' ? (schoolLogo ? schoolLogo : logo) : teamLogo ? teamLogo : logo
          }
          // heading={getTranslation(t, 'sideBar.header.title')}
          // subheading={getTranslation(t, `sideBar.header.${teamType}.subTitle`)}
        />

        <ul className="!p-0">
          {menuList.map((menuItem) => {
            const isSelected =
              menuItem.id === currentPath ||
              (menuItem.id === 'profile' && currentPath === 'account') ||
              (menuItem.id === 'profile' && currentPath === 'password') ||
              (menuItem.id === 'projects' && currentPath === 'create-project') ||
              (menuItem.id === 'projects' && currentPath === 'manage-project');

            const menuNestedItems = menuItem.nestedItems?.map((nestedItem) => {
              nestedItem.isSelected = nestedItem.id === currentPath;
              return nestedItem;
            });

            return menuItem.nestedItems ? (
              <MenuItemWithDropDown
                key={menuItem.id}
                sidebarColor={sidebarColor}
                basePath={basePath}
                isSelected={isSelected}
                dropDownItems={menuNestedItems}>
                <MenuItemContent isSelected={isSelected}>
                  {getTranslation(t, `sideBar.${menuItem.id}`)}
                </MenuItemContent>
              </MenuItemWithDropDown>
            ) : (
              <MenuItem
                key={menuItem.id}
                sidebarColor={sidebarColor}
                isSelected={isSelected}
                onClick={() => navigate(`${basePath}/${menuItem.id}`)}>
                <span className="block w-5">{getIcon(menuItem.id, isSelected)}</span>
                <MenuItemContent isSelected={isSelected}>
                  {getTranslation(t, `sideBar.${menuItem.id}`)}
                </MenuItemContent>
              </MenuItem>
            );
          })}
        </ul>

        {hasWorkshopPortal && (
          <MenuItem
            key={'workshops'}
            className="border border-[#000]"
            sidebarColor={sidebarColor}
            isSelected={currentPath === 'workshops'}
            onClick={() => navigate(`${basePath}/${'workshops'}`)}>
            <span className="block w-5">{getIcon('workshops', currentPath === 'workshops')}</span>
            <MenuItemContent isSelected={currentPath === 'workshops'}>
              {getTranslation(t, `sideBar.workshops`)}
            </MenuItemContent>
          </MenuItem>
        )}

        {profileBooking?.length > 0 && (
          <MenuItem
            key={'book-profile-type'}
            className="border border-[#000]"
            sidebarColor={sidebarColor}
            isSelected={currentPath === 'book-profile-type'}
            onClick={() => {
              setContentPopupInfo({
                isVisible: true,
                maxWidth: 'max-w-3xl',
                content: (
                  <BookProfileTypeForm
                    formDetails={profileBooking}
                    setContentPopupInfo={setContentPopupInfo}
                  />
                )
              });
            }}>
            <span className="block w-5">
              {getIcon('book-profile-type', currentPath === 'book-profile-type')}
            </span>
            <MenuItemContent isSelected={currentPath === 'book-profile-type'}>
              {getTranslation(t, `sideBar.bookProfileType`)}
            </MenuItemContent>
          </MenuItem>
        )}
      </div>

      <a
        className="text-black text-center mt-4 cursor-pointer px-4"
        href={getTranslation(t, 'sideBar.legal.url')}
        target="_blank"
        rel="noreferrer"
        dangerouslySetInnerHTML={{ __html: getTranslation(t, 'sideBar.legal.text') }}
      />
    </div>
  );
};

export default Sidebar;
