import { useTranslation } from 'react-i18next';
import { getTranslation } from '../../utils/translation';

function Partners({}) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center font-extrabold text-xl md:text-4xl">
      {/* <h2 className="text-center">{getTranslation(t, 'landing.partner.title')}</h2> */}
      <img className="w-96 md:w-[33%]" src={getTranslation(t, 'landing.partner.image')} />
    </div>
  );
}

export default Partners;
