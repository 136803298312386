import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { WorkshopContext } from '../contexts/workshop.context';

import QuantityField from './common/QuantityField';
import { ModalBackIcon } from './icons';
import Button from './common/Button';
import { getTranslation } from '../utils/translation';

const CreateTeam = ({ primary, secondary }) => {
  const { t } = useTranslation();

  const [people, setPeople] = useState(4);

  const { setActiveStep, workshopFixedData, setWorkshopData } = useContext(WorkshopContext);

  function openModalWorkshopType() {
    setWorkshopData((currValue) => ({
      ...currValue,
      groupSize: people
    }));
    setActiveStep('step1');
  }

  function openModallanguage() {
    setWorkshopData((currValue) => ({
      ...currValue,
      groupSize: people
    }));
    setActiveStep('step3');
  }

  return (
    <>
      <div className="modal-wrapper workshopGroups-modal md:px-9 md:pt-5  md:pb-8 p-3">
        <div className=" top-0 sm:left-[auto] left-6 " onClick={openModalWorkshopType}>
          <button className="p-3 self-start absolute left-6">
            <ModalBackIcon color={primary} />
          </button>
        </div>
        <div className="modal-inner-wrapper">
          <div className="wg-modal-header">
            <div className="text-center">
              <h2 className="text-2xl font-bold mb-2.5 sm:mt-0 mt-[70px]">
                {getTranslation(t, 'workshops.newWorkshop.selectGroupSize.title')}
              </h2>
              <p
                className="text-sm text-swiss-bw-200 w-4/6 mx-auto mb-9"
                dangerouslySetInnerHTML={{
                  __html: getTranslation(t, 'workshops.newWorkshop.selectGroupSize.subtext')
                }}
              />
            </div>
          </div>
          <div className="workshopGroups-row flex justify-center">
            <div className="workshopGroups-item md:w-2/3 lg:w-1/2 w-full">
              <div className="workshopGroups-item-title mb-4">
                <p className="text-sm font-semibold text-swiss-bw-300">
                  {getTranslation(t, 'workshops.newWorkshop.selectGroupSize.inputLabel')}
                </p>
              </div>
              <div className="workshopGroups-item-value bg-[#F6F6F6] p-1 min-h-[53px] text-lg leading-1 rounded-s-full">
                <QuantityField
                  primary={primary}
                  qty={people}
                  onIncrement={() => {
                    if (people < workshopFixedData.maxParticipants) {
                      setPeople(people + 1);
                    }
                  }}
                  onDecrement={() => {
                    if (people > 2) {
                      setPeople(people - 1);
                    }
                  }}
                />
              </div>
            </div>
            <div className="workshopGroups-item md:w-1/3 w-1/2">
              <div className="workshopGroups-item-title">
                <p className="text-sm font-semibold text-swiss-bw-300 mb-4">
                  {getTranslation(t, 'workshops.newWorkshop.selectGroupSize.timeLabel')}
                </p>
                <div className="flex items-center justify-end bg-[#F6F6F6] p-0 min-h-[53px] rounded-e-full">
                  <div className="workshopGroups-item-value text-lg font-semibold md:mr-10 mr-0 mt-1 mb-1">
                    {workshopFixedData.workshopDuration}{' '}
                    {getTranslation(t, 'workshops.newWorkshop.selectGroupSize.timeLabel2')}
                  </div>
                  <div className="workshopGroups-item">
                    <div className="workshopGroups-item-title"></div>
                    <div className="workshopGroups-item-value">
                      <Button
                        className="bg-swiss-300 text-white text-md h-full rounded-full mr-1 shadow-md md:min-w-[176px] w-[100px] font-bold md:py-2 md:px-8 active:shadow"
                        onClick={openModallanguage}
                        buttonType="primary">
                        {getTranslation(t, 'workshops.newWorkshop.selectGroupSize.buttonText')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="wg-note text-center mt-[50px]">
            <h3 className="text-base font-semibold mb-4" style={{ color: primary }}>
              {getTranslation(t, 'workshops.newWorkshop.selectGroupSize.noteLabel')}
            </h3>
            <p
              className="text-sm text-swiss-bw-300"
              dangerouslySetInnerHTML={{
                __html: getTranslation(t, 'workshops.newWorkshop.selectGroupSize.noteSubtext')
              }}
            />
          </div>
        </div>
      </div>

      <div className="flex gap-5 justify-center mb-[20px]">
        <h1 className="font-semibold text-sm p-1 text-swiss-bw-300">
          {getTranslation(t, 'workshops.newWorkshop.steps.1')}
        </h1>
        <h1
          className="font-semibold text-sm p-1 text-white rounded-md"
          style={{ backgroundColor: secondary }}>
          {getTranslation(t, 'workshops.newWorkshop.steps.2')}
        </h1>
        <h1 className="font-semibold text-sm p-1 text-swiss-bw-300">
          {getTranslation(t, 'workshops.newWorkshop.steps.3')}
        </h1>
        <h1 className="font-semibold text-sm p-1 text-swiss-bw-300">
          {getTranslation(t, 'workshops.newWorkshop.steps.4')}
        </h1>
      </div>
    </>
  );
};

export default CreateTeam;
