import React, { useContext } from 'react';
import Modal from 'react-modal';

import { AdminContext } from '../contexts/admin.context';
import { WorkshopContext } from '../contexts/workshop.context';
import useRouteUtils from '../utils/useRouteUtils';

import LanguageModel from '../components/LanguageModel';
import ScheduleModel from '../components/ScheduleModel';
import SuccessModel from '../components/SuccessModel';
import CreateTeam from '../components/CreateTeam';
import SelectWorkshopType from '../components/selectWorkshopType';

const NewWorkshop = (props) => {
  const { removeQueryParam } = useRouteUtils();

  const {
    themeId,
    themeConfig: { primary, secondary, buttonRadius } = {},
    portalConfig: { languages } = {}
  } = useContext(AdminContext);
  const { activeStep, workshopData, setWorkshopData, setActiveStep } = useContext(WorkshopContext);

  const handleCloseModal = () => {
    setWorkshopData((currValue) => ({
      ...currValue,
      teamName: '',
      groupSize: 8
    }));
    setActiveStep('step1');
    props.setIsOpen(false);

    // remove "type" param on close
    removeQueryParam('type', true);
  };

  const customStyles2 = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: '0',
      width: '100%',
      maxWidth: '1080px',
      maxHeight: '614px',
      transform: 'translate(-50%, -50%)',
      borderRadius: '10px',
      border: '0px !important',
      boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
      fontFamily: 'OpenSans'
    }
  };

  return (
    <div>
      <Modal
        isOpen={props.isOpen}
        style={customStyles2}
        onRequestClose={handleCloseModal}
        ariaHideApp={false}
        contentLabel="Workshop Modal">
        {activeStep === 'step1' && (
          <SelectWorkshopType
            closeModal={handleCloseModal}
            primary={primary}
            secondary={secondary}
            buttonRadius={buttonRadius}
            themeId={themeId}
          />
        )}

        {activeStep === 'step2' && (
          <CreateTeam primary={primary} secondary={secondary} buttonRadius={buttonRadius} />
        )}

        {activeStep === 'step3' && (
          <LanguageModel
            languages={languages}
            primary={primary}
            secondary={secondary}
            buttonRadius={buttonRadius}
          />
        )}

        {activeStep === 'step4' && (
          <ScheduleModel
            onUpdate={props.onUpdate}
            primary={primary}
            secondary={secondary}
            buttonRadius={buttonRadius}
          />
        )}

        {activeStep === 'step5' && (
          <SuccessModel
            closeModal={handleCloseModal}
            isUpdated={props?.isUpdated}
            primary={primary}
          />
        )}
      </Modal>
    </div>
  );
};

export default NewWorkshop;
