import React from 'react';

import { WORKSHOP_LIST_TYPE } from '../../data/default-values';
import { getTranslation } from '../../utils/translation';

const Tab = ({ type, activeTab, handleTabChange, label, primary }) => (
  <div
    className={`
        px-8 py-4 
        rounded-full 
        cursor-pointer 
        text-swiss-300 
      `}
    style={{
      backgroundColor: activeTab === type ? primary : '',
      color: activeTab === type ? 'white' : primary
    }}
    onClick={() => handleTabChange(type)}>
    {label}
  </div>
);

export const WorkshopTabs = ({ activeTab, handleTabChange, translateFn, primary }) => {
  const tabs = [
    {
      type: WORKSHOP_LIST_TYPE.UPCOMING,
      labelKey: 'workshops.tabs.upcoming'
    },
    { type: WORKSHOP_LIST_TYPE.PAST, labelKey: 'workshops.tabs.past' }
  ];

  return (
    <div className="flex rounded-full bg-[#00000008] w-fit mb-10 shadow shadow-swiss-100">
      {tabs.map((tab) => (
        <Tab
          key={tab.type}
          type={tab.type}
          activeTab={activeTab}
          handleTabChange={handleTabChange}
          label={getTranslation(translateFn, tab.labelKey)}
          primary={primary}
        />
      ))}
    </div>
  );
};
