import { useTranslation } from 'react-i18next';
import { getTranslation } from '../../utils/translation';

export default function CheckBoxInput({
  label,
  name,
  className,
  error,
  touched,
  color,
  fieldType,
  onChange,
  fieldValue,
  ...props
}) {
  const { t } = useTranslation();

  return (
    <label htmlFor={name} className={`${className} flex flex-col`}>
      <input
        className={`px-3 py-5 rounded w-full ${color} rounded-md text-20 font-normal landing-[138%] text-gray-primary mb-7`}
        id={name}
        name={name}
        value={fieldValue}
        onChange={onChange}
        type={fieldType}
        {...props}
      />
      {label && (
        <span
          className="block pl-10 sm:pl-7 landing-[138%] text-cblack-primary"
          dangerouslySetInnerHTML={{
            __html: label
          }}
        />
      )}
      {error && touched && (
        <p className="text text-sm text-red-500 ml-7">{getTranslation(t, `error.${error}`)}</p>
      )}
    </label>
  );
}

CheckBoxInput.defaultProps = {
  className: ''
};
