import { useTranslation } from 'react-i18next';
import { GroupIcon } from '../icons';
import { getTranslation } from '../../utils/translation';

function FutureTeamSupport({}) {
  const { t } = useTranslation();

  return (
    <div>
      <h2 className=" md:block font-extrabold md:text-4xl text-2xl">
        {getTranslation(t, 'landing.FutureTeamSupport.title1')}
      </h2>
      <div className=" md:flex mx-auto mb-10">
        <ol className="relative mt-10  md:ml-0 pl-0">
          <li className="flex flex-row justify-start items-center gap-5 mb-4">
            <div className="">
              <GroupIcon />
            </div>

            <p className="m-0 md:text-[1.215rem] text-base text-black-300">
              {getTranslation(t, 'landing.FutureTeamSupport.content1.point1')}
            </p>
          </li>
          <li className="flex flex-row justify-start items-center gap-5 mb-4">
            <div className="">
              <GroupIcon />
            </div>

            <p className="m-0 md:text-[1.215rem] text-base text-black-300">
              {getTranslation(t, 'landing.FutureTeamSupport.content1.point2')}
            </p>
          </li>
          <li className="flex flex-row justify-start items-center gap-5 mb-4">
            <div className="">
              <GroupIcon />
            </div>

            <p className="m-0 md:text-[1.215rem] text-base text-black-300">
              {getTranslation(t, 'landing.FutureTeamSupport.content1.point3')}
            </p>
          </li>
          <li className="flex flex-row justify-start items-center gap-5 mb-4">
            <div className="">
              <GroupIcon />
            </div>

            <p className="m-0 md:text-[1.215rem] text-base text-black-300">
              {getTranslation(t, 'landing.FutureTeamSupport.content1.point4')}
            </p>
          </li>
        </ol>
      </div>
      <h2 className=" md:block font-extrabold md:text-4xl text-2xl">
        {getTranslation(t, 'landing.FutureTeamSupport.title2')}
      </h2>
      <div className=" md:flex mx-auto">
        <ol className="relative mt-10  md:ml-0  pl-0">
          <li className="flex flex-row justify-start items-center gap-5 mb-4">
            <div className="">
              <GroupIcon />
            </div>

            <p className="m-0 md:text-[1.215rem] text-base text-black-300">
              {getTranslation(t, 'landing.FutureTeamSupport.content2.point1.data1')}
            </p>
          </li>
          <li className="flex flex-row justify-start items-center gap-5 mb-4">
            <div className="">
              <GroupIcon />
            </div>

            <p className="m-0 md:text-[1.215rem] text-base text-black-300">
              {getTranslation(t, 'landing.FutureTeamSupport.content2.point2.data1')}
            </p>
          </li>
          <li className="flex flex-row justify-start items-center gap-5 mb-4">
            <div className="">
              <GroupIcon />
            </div>

            <p className="m-0 md:text-[1.215rem] text-base text-black-300">
              {getTranslation(t, 'landing.FutureTeamSupport.content2.point3.data1')}
            </p>
          </li>
          <li className="flex flex-row justify-start items-center gap-5 mb-4">
            <div className="">
              <GroupIcon />
            </div>

            <p className="m-0 md:text-[1.215rem] text-base text-black-300">
              {getTranslation(t, 'landing.FutureTeamSupport.content2.point4.data1')}
            </p>
          </li>
        </ol>
      </div>
    </div>
  );
}

export default FutureTeamSupport;
