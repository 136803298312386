import React, { useRef, useState, useContext } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { AiOutlineWarning } from 'react-icons/ai';
import { AdminContext } from '../../contexts/admin.context';
import { getTranslation } from '../../utils/translation';

export default function AdminInput({
  name,
  label,
  subtext,
  error,
  formik,
  isDark = false,
  className,
  type,
  defaultMargin = 'mb-8',
  focus = true,
  ...props
}) {
  const { themeConfig: { buttonRadius } = {} } = useContext(AdminContext);

  const onChange = formik?.handleChange;
  const fieldValue = formik?.values[name];
  const fieldTouched = formik?.touched[name];
  const fieldError = formik?.errors[name];

  const [fieldType, setFieldType] = useState(type);

  const passwordButtonRef = useRef(null);

  const [password, setIsPassword] = useState({
    isPassword: type === 'password',
    isFieldVisible: false
  });
  const { t } = useTranslation();

  const togglePasswordVisibility = () => {
    setIsPassword({
      isPassword: true,
      isFieldVisible: !password.isFieldVisible
    });

    const updatedFieldType = password.isFieldVisible ? 'password' : 'text';
    setFieldType(updatedFieldType);

    // remove button focus
    passwordButtonRef.current.blur();
  };

  return (
    <div className={`${defaultMargin} signupinwrap`}>
      {label && (
        <label className="block mb-1 text-base font-bold text-cblack-primary">{label}</label>
      )}
      {subtext && <p className="text-sm text-gray-primary mb-2 leading-5">{subtext}</p>}
      <div className="relative">
        <input
          className={clsx(
            `px-4 py-3.5 w-full outline-none text-gray-primary text-base bg-gray-100 rounded-md ${className}`,
            {
              'bg-input': isDark
            },
            {
              'focus:ring-1': focus
            },
            {
              'focus:ring-black': focus
            },
            { 'bg-red-100': fieldError && fieldTouched }
          )}
          style={{ borderRadius: buttonRadius }}
          id={name}
          value={fieldValue}
          onChange={onChange}
          onBlur={formik?.handleBlur}
          type={fieldType}
          {...props}
        />
        {password.isPassword && fieldValue && (
          <button
            ref={passwordButtonRef}
            onClick={togglePasswordVisibility}
            type="button"
            className="p-1.5 absolute right-2 top-2.5 text-gray-800 hover:bg-gray-200 rounded">
            {password.isFieldVisible ? (
              <EyeSlashIcon className="w-5 h-5 " />
            ) : (
              <EyeIcon className="w-5 h-5 " />
            )}
          </button>
        )}
      </div>
      {fieldTouched && fieldError && (
        <p className="ml-1 text-sm text-red-600 flex items-center gap-2 mt-1 mr-1">
          <AiOutlineWarning />
          <span dangerouslySetInnerHTML={{ __html: getTranslation(t, `error.${fieldError}`) }} />
        </p>
      )}
    </div>
  );
}

AdminInput.defaultProps = {
  className: ''
};
