import React, { useState } from 'react';

import { CopyIcon } from '../icons';

const ClipboardCopyField = ({ textToCopy, isClickable = false, isColored = '' }) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000); // Revert back to the original state after 2 seconds
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <div className="flex items-center gap-2">
      {isClickable ? (
        <div className="my-4 p-4 bg-gray-100 text-gray-primary italic inline-flex items-center justify-between gap-4 rounded w-full max-w-clip ml-1 ring-1 ring-zinc-950/5">
          <a href={textToCopy} target="_blank" rel="noreferrer" className="truncate px-2">
            {textToCopy}
          </a>
        </div>
      ) : (
        <a
          href={textToCopy}
          target="_blank"
          rel="noreferrer"
          className="truncate px-2 text=[#515151] underline underline-offset-2">
          {textToCopy}
        </a>
      )}
      {isColored === '' ? (
        <button onClick={copyToClipboard}>
          {isCopied ? (
            <span className="p-1 rounded-full w-8 h-8 flex items-center justify-center shadow text-[#515151]">
              ✔
            </span>
          ) : (
            <CopyIcon />
          )}
        </button>
      ) : (
        <button
          className={`p-1 rounded-md w-12 flex items-center justify-center h-[59px] shadow text-[#515151]`}
          onClick={copyToClipboard}>
          {isCopied ? '✔' : <CopyIcon />}
        </button>
      )}
    </div>
  );
};

export default ClipboardCopyField;
