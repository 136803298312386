import React from 'react';
import ResponsiveVimeoVideo from '../responsiveVimeoVideo';
import { fetchCorrectTranslation } from '../../../utils/translation';

export default function SlideIFrame({ data }) {
  return (
    <div className="container mx-auto">
      <div className="img-slide w-full sm:w-4/5 mx-auto text-left py-6">
        <div className="flex flex-col md:flex-row items-center lg:gap-12 gap-0 ">
          <div className="flex h-fit my-auto align-center z-50 w-[90%] lg:w-[70%] max-w-md">
            <div
              className="bg-swiss-150 text-white p-4 md:p-8 mr-0 md:-mr-5 text-[12px] md:text-[1.125rem] leading-6 md:leading-7 rounded-sm md:rounded-md"
              dangerouslySetInnerHTML={{
                __html: fetchCorrectTranslation(data.content)
              }}></div>
          </div>
          <div className="px-4 md:px-0 img-desktop">
            {data.itemTitle && (
              <p
                className="text-center mb-4 font-semibold text-lg"
                dangerouslySetInnerHTML={{ __html: fetchCorrectTranslation(data.itemTitle) }}
              />
            )}
            <div className="img-desktop">
              <ResponsiveVimeoVideo url={fetchCorrectTranslation(data.url)} />
            </div>
          </div>
          <div className="sm:px-4 px-0 md:px-0 mt-4 md:mt-0 w-full md:hidden">
            {data.itemTitle && (
              <p className="text-center mt-8 font-semibold text-lg">
                {fetchCorrectTranslation(data.itemTitle)}
              </p>
            )}
            <div className="w-full h-[220px]">
              <iframe
                className="mx-auto h-full w-full"
                src={fetchCorrectTranslation(data.url)}
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
