import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

import { getTranslation } from '../../utils/translation';

const CookieBanner = ({ fontFamily = 'OpenSans', team }) => {
  const { t } = useTranslation();

  const [cookieBannerVisible, setCookieBannerVisible] = useState(false);

  useEffect(() => {
    if (!Cookies.get('mf-ssk-cookies')) {
      setCookieBannerVisible(true);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set('mf-ssk-cookies', true, { expires: 365 });
    setCookieBannerVisible(false);

    if (team === 'school') {
      ReactGA.initialize('G-303V1X68RV');
    }
    if (team === 'team') {
      ReactGA.initialize('G-8PWP2R2JH9');
    }
  };

  const handleDecline = () => {
    Cookies.set('mf-ssk-cookies', false, { expires: 365 });
    setCookieBannerVisible(false);
  };

  if (!cookieBannerVisible) return null;

  return (
    <div
      className={`bg-white text-cblack-secondary fixed left-0 bottom-0 right-0 w-full z-40 p-4`}
      style={{ fontFamily, boxShadow: '0 0 .5em rgba(0,0,0,.25)' }}>
      <div className="container mx-auto">
        <div
          className={'text-swiss-button-secondary-500 font-bold text-lg'}
          dangerouslySetInnerHTML={{ __html: getTranslation(t, 'cookieBanner.heading') }}
        />
        <div
          className="font-normal text-base mt-2"
          dangerouslySetInnerHTML={{ __html: getTranslation(t, 'cookieBanner.text') }}
        />
        <div className="mt-4 flex gap-4 flex-wrap items-center justify-center">
          <button
            className={
              'block font-semibold text-xs bg-swiss-button-secondary-500 px-[16px] py-[6px] text-white rounded-md outline-none border-transparent border-2 hover:bg-white hover:text-swiss-button-secondary-500 shadow-none hover:shadow-grayBorder hover:underline'
            }
            onClick={handleAccept}>
            <span dangerouslySetInnerHTML={{ __html: getTranslation(t, 'cookieBanner.accept') }} />
          </button>
          <button
            className={
              'block font-semibold text-xs bg-white px-[16px] py-[6px] text-swiss-button-secondary-500 rounded-md outline-none border-swiss-button-secondary-500 border-2 hover:bg-swiss-button-secondary-500 hover:text-white shadow-none hover:shadow-grayBorder hover:underline'
            }
            onClick={handleDecline}>
            <span dangerouslySetInnerHTML={{ __html: getTranslation(t, 'cookieBanner.decline') }} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;
