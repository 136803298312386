import React, { useState, useEffect, useContext } from 'react';
import { AdminContext } from '../../contexts/admin.context';
import { useTranslation } from 'react-i18next';
import { getTranslation } from '../../utils/translation';

function UserGuide() {
  const [buttonLinks, setButtonLinks] = useState([]);

  const {
    themeId,
    teamType,
    tRegion,
    portalConfig: { adminPanelTabs } = {}
  } = useContext(AdminContext);

  const { t } = useTranslation();

  useEffect(() => {
    try {
      const region = tRegion && tRegion !== '' ? tRegion : 'default';
      const guideTab = adminPanelTabs?.[region]?.find((tab) => tab.id === 'guide');
      if (!guideTab) return;

      const buttons = t(`${teamType}.guide`, { returnObjects: true })?.links || [];
      setButtonLinks(buttons);
    } catch (error) {
      console.log(error);
    }
  }, [adminPanelTabs]);

  return (
    <div className="w-full mini-scrollbar overflow-y-auto">
      <section
        className="mb-8"
        dangerouslySetInnerHTML={{ __html: getTranslation(t, 'guide.body') }}
      />

      <div className="mb-8">
        <p
          className="font-bold"
          dangerouslySetInnerHTML={{ __html: getTranslation(t, 'guide.header') }}
        />
        {buttonLinks.map(({ title, url, info }, index) => {
          if (
            (title?.[tRegion] !== undefined && !title?.[tRegion]) ||
            (title?.[themeId] !== undefined && !title?.[themeId])
          )
            return null;

          const finalTitle = title?.[tRegion] || title?.[themeId] || title?.default;

          if (!finalTitle || !url || !info) return null;

          return (
            <div key={index} className="mt-4">
              <a
                href={url?.[tRegion] ?? url?.[themeId] ?? url?.default}
                target="_blank"
                className="inline-block bg-cwhite-secondary px-5 py-2 rounded-md text-black text-lg font-semibold underline mb-2"
                rel="noreferrer">
                <span
                  dangerouslySetInnerHTML={{
                    __html: title?.[tRegion] ?? title?.[themeId] ?? title?.default
                  }}
                />
              </a>

              <p
                className="mt-2 text-cblack-primary font-normal text-base"
                dangerouslySetInnerHTML={{
                  __html: info?.[tRegion] ?? info?.[themeId] ?? info?.default
                }}
              />
            </div>
          );
        })}
      </div>

      <div dangerouslySetInnerHTML={{ __html: getTranslation(t, 'guide.bottom') }} />
    </div>
  );
}

export default UserGuide;
