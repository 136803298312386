import * as yup from 'yup';

export const signupSchema = yup.object().shape({
  type: yup.string(),
  // .required('type'),
  name: yup.string(),
  // .required('name'),
  address: yup.string(),
  // .required('address'),
  city: yup.string(),
  // .required('city'),
  academy: yup.string(),
  // .required('academy'),
  postalCode: yup.string(),
  // .required('postalCode'),
  firstName: yup.string().required('firstName'),
  lastName: yup.string(),
  // .required('lastName'),
  schoolOrganization: yup.lazy(
    (value) =>
      typeof value === 'string'
        ? yup.string()
        : // .required('schoolOrganization')
          yup.object().shape({
            label: yup.string(),
            value: yup.string()
            // .required('schoolOrganization')
          })
    // .required('schoolOrganization')
  ),
  schoolOrganizationOther: yup.string().when('schoolOrganization', {
    is: (schoolOrganization) => schoolOrganization && schoolOrganization?.value === 'other',
    then: () => yup.string()
    // .required('schoolOrganization')
    // otherwise: yup.string()
  }),
  functionTask: yup.string(),
  // .required('functionTask'),
  email: yup.string().email('validEmail'),
  // .required('email'),
  phone: yup.string(),
  // .required('phone'),
  canton: yup.string(),
  // .required('canton'),
  language: yup.string(),
  // .required('language'),
  wantAccess: yup.boolean().required().oneOf([true], 'wantAccess'),
  isTeacher: yup.boolean().required().oneOf([true], 'isTeacher')
});
