import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminContext } from '../../contexts/admin.context';
import { getTranslation } from '../../utils/translation';

const Loader = (props) => {
  const { t } = useTranslation();
  const { themeConfig: { secondary } = {} } = useContext(AdminContext);
  const { progress, setLoading, setProgress } = props;

  useEffect(() => {
    const progressInterval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + 10;
        return newProgress >= 90 ? 90 : newProgress;
      });
    }, 500);

    if (progress === 100) {
      setProgress(100);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }

    return () => {
      clearInterval(progressInterval);
    };
  }, [progress]);

  return (
    <div className="flex justify-center items-center w-9/12 mx-auto h-full flex-col">
      <h1 className="text-2xl text-center font-bold text-cblack-primary">
        {getTranslation(t, 'loader.header')}
      </h1>
      <p className="text-xl text-center font-normal text-gray-primary mt-6">
        {getTranslation(t, 'loader.content')}
      </p>

      <div className="mt-8">
        <svg
          width="188"
          height="188"
          viewBox="0 0 188 188"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M94 0.25C42.3032 0.25 0.25 42.3032 0.25 94C0.25 145.697 42.3032 187.75 94 187.75C145.697 187.75 187.75 145.697 187.75 94C187.75 42.3032 145.697 0.25 94 0.25ZM94 15.875C137.243 15.875 172.125 50.7566 172.125 94C172.125 137.243 137.243 172.125 94 172.125C50.7566 172.125 15.875 137.243 15.875 94C15.875 50.7566 50.7566 15.875 94 15.875ZM86.1875 31.5V101.812H140.875V86.1875H101.812V31.5H86.1875Z"
            fill={secondary}
          />
        </svg>
      </div>

      <div className="w-6/12 rounded-full h-2.5 bg-gray-300 mt-8">
        <div
          className=" h-2.5 rounded-full"
          style={{ width: `${progress}%`, backgroundColor: secondary }}></div>
      </div>
    </div>
  );
};

export default Loader;
