import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { trackEvent } from '../utils';
import { getTranslation } from '../utils/translation';

const MissionFuture = () => {
  const { t } = useTranslation();

  useEffect(() => {
    trackEvent('page_view', {
      organization: 'WSF'
    });
  }, []);

  return (
    <div className="flex flex-col items-start h-calc-vh overflow-y-auto">
      <div className="mb-5 w-full">
        <h1 className="text-2xl font-bold text-left text-cblack-primary mb-2.5">
          {getTranslation(t, 'missionFutureClass.header')}
        </h1>
      </div>
      <div
        className="w-full mini-scrollbar overflow-y-auto pb-2"
        dangerouslySetInnerHTML={{ __html: getTranslation(t, 'missionFutureClass.body') }}
      />
    </div>
  );
};

export default MissionFuture;
