import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { WorkshopContext } from '../contexts/workshop.context';

import { getTranslation } from '../utils/translation';
import {
  EnglishIconSvg,
  FrenchIconSvg,
  GermanIconSvg,
  ItalianIconSvg,
  ModalBackIcon
} from './icons';

const LanguageModel = ({ languages, primary, secondary, buttonRadius }) => {
  const { t } = useTranslation();

  const [self_mod, set_self_mod] = useState(true);
  const { setActiveStep, setWorkshopData } = useContext(WorkshopContext);

  function openCreateWorkshopModel() {
    setActiveStep('step2');
  }

  function openModalreschedule(language) {
    setWorkshopData((currVal) => ({
      ...currVal,
      languageId: language
    }));
    setActiveStep('step4');
  }

  const LanguageButton = (language) => {
    let LanguageIcon = null;
    switch (language) {
      case 'en':
        LanguageIcon = EnglishIconSvg;
        break;

      case 'de':
        LanguageIcon = GermanIconSvg;
        break;

      case 'it':
        LanguageIcon = ItalianIconSvg;
        break;

      case 'fr':
        LanguageIcon = FrenchIconSvg;
        break;

      default:
        break;
    }

    return (
      <button
        className={`language-box md:w-1/3 w-[75%] md:mb-3 bg-gray-100 shadow hover:shadow-md active:shadow items-center justify-center flex py-2 px-3 cursor-pointer`}
        style={{ borderRadius: buttonRadius }}
        onClick={() => openModalreschedule(language)}>
        <LanguageIcon />
        <label className="text-xl font-semibold ml-[15px] cursor-pointer">
          {getTranslation(t, `workshops.newWorkshop.selectLanguage.lang.${language}`)}
        </label>
      </button>
    );
  };

  return (
    <div className="modal-wrapper language-modal md:px-9 md:pt-5 md:pb-8 p-3">
      <button className="absolute left-6" onClick={() => openCreateWorkshopModel()}>
        <ModalBackIcon color={primary} />
      </button>
      <div className="modal-inner-wrapper  flex flex-col items-center ">
        <div className="la-modal-header">
          <h2 className="text-2xl font-bold text-swiss-bw-100 mb-2.5">
            {getTranslation(t, 'workshops.newWorkshop.selectLanguage.title')}
          </h2>
        </div>
        <div className="la-modal-subheader mb-[50px]">
          <p className="text-sm text-swiss-bw-200 ">
            {getTranslation(t, 'workshops.newWorkshop.selectLanguage.subtext')}
          </p>
        </div>
        <div className="language-list flex md:flex-nowrap flex-wrap md:w-[75%] w-full m-auto mb-[50px] gap-4 justify-center">
          {languages.includes('de') && LanguageButton('de')}
          {languages.includes('fr') && LanguageButton('fr')}
          {languages.includes('it') && LanguageButton('it')}
          {languages.includes('en') && LanguageButton('en')}
        </div>
        <div
          className="la-modal-content text-sm font-normal text-swiss-bw-200 text-center"
          dangerouslySetInnerHTML={{
            __html: getTranslation(t, 'workshops.newWorkshop.selectLanguage.note1')
          }}></div>
        {self_mod && (
          <div
            className="la-modal-content text-[16px] font-semibold landing-[22px] text-swiss-bw-200 text-center mb-[50px]"
            style={{ marginTop: '30px' }}>
            {getTranslation(t, 'workshops.newWorkshop.selectLanguage.note2')}
          </div>
        )}

        <div className="flex gap-5 justify-center mb-[20px]">
          <h1 className="font-semibold text-sm p-1 text-swiss-bw-300">
            {getTranslation(t, 'workshops.newWorkshop.steps.1')}
          </h1>
          <h1 className="font-semibold text-sm p-1 text-swiss-bw-300">
            {getTranslation(t, 'workshops.newWorkshop.steps.2')}
          </h1>
          <h1
            className="font-semibold text-sm p-1 text-white rounded-md"
            style={{ backgroundColor: secondary }}>
            {getTranslation(t, 'workshops.newWorkshop.steps.3')}
          </h1>
          <h1 className="font-semibold text-sm p-1 text-swiss-bw-300">
            {getTranslation(t, 'workshops.newWorkshop.steps.4')}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default LanguageModel;
