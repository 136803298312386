import { useContext } from 'react';

import { AdminContext } from '../../contexts/admin.context';

import Button from './Button';
import Spinner from './Spinner';
import { DownloadIconWithBar } from '../icons';

export const DownloadButton = ({
  onClick,
  isLoading = false,
  children,
  buttonType = 'outline',
  bg = ''
}) => {
  const { themeConfig: { primary } = {} } = useContext(AdminContext);

  return (
    <Button type="button" buttonType={buttonType} bg={bg} onClick={onClick}>
      {isLoading ? (
        <span className="flex gap-2 items-center">
          {typeof isLoading !== 'boolean' && !isNaN(isLoading) && (
            <span
              className="block w-32 h-4 rounded-lg border p-1 bg-white"
              style={{
                borderColor: buttonType === 'primary' ? '#fff' : primary
              }}>
              <span
                className="block h-full rounded-lg bg-white"
                style={{
                  width: `${isLoading}%`,
                  backgroundColor: primary
                }}
              />
            </span>
          )}
          <Spinner size={16} color={buttonType === 'primary' ? '#fff' : primary} />
        </span>
      ) : (
        <span className="flex gap-2 items-center">
          {children}
          <DownloadIconWithBar color={buttonType === 'primary' ? '#fff' : primary} />
        </span>
      )}
    </Button>
  );
};
