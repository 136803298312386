import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import clsx from 'clsx';

import { getAllWorkshops, getSummaryPDFLink, deleteWorkshop } from '../service';
import { LANGUAGE_STRINGS, WORKSHOP_LIST_TYPE } from '../data/default-values';
import { getTimeZoneDiffBasedDateAndTime } from '../utils/date';
import { WorkshopProvider } from '../contexts/workshop.context';
import { downloadPDFWithURL } from '../utils/download';
import { AdminContext } from '../contexts/admin.context';

import FullScreenLoader from '../components/common/fullScreenLoader';
import ConfirmWorkshopDeletePopup from '../components/popups/confirmWorkshopDeletePopup';
import { Trash, Setting, PlusIcon, DownloadsIcon } from '../components/icons';
import { WorkshopTabs } from '../components/workshop/workshopTabs';
import Spinner from '../components/common/Spinner';
import NewWorkshop from './NewWorkshop';
import { trackEvent } from '../utils';
import { getTranslation } from '../utils/translation';

const Workshops = () => {
  const { t } = useTranslation();

  const { themeId, teamType, themeConfig: { primary } = {} } = useContext(AdminContext);

  const basePath = `/${themeId}/${teamType}`;

  const [loading, setLoading] = useState(true);
  const [allWorkshops, setAllWorkshops] = useState([]);
  const [workshops, setWorkshops] = useState([]);
  const [deleteWorkshopId, setDeleteWorkshopId] = useState(null);
  const [isLoadingPDFIndex, setIsLoadingPDFIndex] = useState(-1);
  const [isDeleteWorkshopLoading, setIsDeleteWorkshopLoading] = useState(false);
  const [isWorkshopModalOpen, setIsWorkshopModalOpen] = useState(false);
  const [isDeleteWorkshopModelOpen, setIsDeleteWorkshopModalOpen] = useState(false);

  const [activeTab, setActiveTab] = useState(WORKSHOP_LIST_TYPE.UPCOMING);

  const getAdjustedWorkshopDate = (workshopDate, timeZoneDiff) => {
    const date = new Date(workshopDate);
    return date;
  };

  // SET WORKSHOP BASED ON SELECTED TAB i.e. UPCOMING OR PAST
  const updateWorkshops = (type, workshopsList) => {
    const previousDatObject = new Date();
    const lastDay = previousDatObject.getDate() - 1;
    previousDatObject.setDate(lastDay);

    if (type === WORKSHOP_LIST_TYPE.PAST) {
      setWorkshops(
        workshopsList.filter((workshop) => {
          const adjustedDate = getAdjustedWorkshopDate(
            workshop.workshopDate,
            workshop.timeZoneDiff
          );
          return adjustedDate < new Date(previousDatObject);
        })
      );
    } else {
      setWorkshops(
        workshopsList.filter((workshop) => {
          const adjustedDate = getAdjustedWorkshopDate(
            workshop.workshopDate,
            workshop.timeZoneDiff
          );
          return (
            adjustedDate > new Date(previousDatObject) ||
            adjustedDate === new Date(previousDatObject)
          );
        })
      );
    }
  };

  const handleTabChange = (value) => {
    setActiveTab(value);
    updateWorkshops(value, allWorkshops);
  };

  const fetchAllWorkshops = async () => {
    try {
      const response = await getAllWorkshops();
      const { workshops } = response.data;

      const formattedWorkshops = workshops.map((workshop) => {
        const { workshopDate, timeZoneDiff } = workshop;
        const { date, time } = getTimeZoneDiffBasedDateAndTime({
          date: workshopDate,
          timeZoneDiff
        });

        workshop.date = date;
        workshop.time = time;
        workshop.language = LANGUAGE_STRINGS[workshop.languageId];
        return workshop;
      });

      setAllWorkshops(formattedWorkshops);
      updateWorkshops(activeTab, formattedWorkshops);
    } catch (error) {
      toast.error(getTranslation(t, 'workshops.errors.failedLoadWorkshops'));
      console.error('ERROR', error);
    } finally {
      setLoading(false);
    }
  };

  // GET ALL WORKSHOPS ON LOAD
  useEffect(() => {
    fetchAllWorkshops();

    trackEvent('page_view', {
      organization: 'SSK'
    });
  }, []);

  const handleWorkshopDelete = async () => {
    try {
      setIsDeleteWorkshopLoading(true);
      const response = await deleteWorkshop(deleteWorkshopId);

      if (response?.status !== 204) {
        toast.error(getTranslation(t, 'workshops.errors.deleteWorkshop'));
        return;
      }

      const allWorkshops = JSON.parse(JSON.stringify(workshops));
      const allWorkshopsExceptDeleted = allWorkshops.filter(
        (workshop) => workshop._id !== deleteWorkshopId
      );

      setWorkshops(allWorkshopsExceptDeleted);

      setIsDeleteWorkshopModalOpen(false);

      toast.success(getTranslation(t, 'workshops.success.deleteWorkshop'));
    } catch (error) {
      toast.error(getTranslation(t, 'workshops.errors.deleteWorkshop'));
    } finally {
      setIsDeleteWorkshopLoading(false);
    }
  };

  const handleWorkshopModalClose = () => {
    setIsDeleteWorkshopModalOpen(false);
  };

  const showDeletionConfirmationModal = (workshopId) => {
    setDeleteWorkshopId(workshopId);
    setIsDeleteWorkshopModalOpen(true);
  };

  const handleNewWorkshopClick = () => {
    setIsWorkshopModalOpen(true);
  };

  const handleSummaryDownloadClick = async (workshopId, index) => {
    try {
      setIsLoadingPDFIndex(index);
      const {
        data: { wordCloudSummaryLink, communicationSummaryLink, fullSummaryLink }
      } = await getSummaryPDFLink(workshopId);

      if (!wordCloudSummaryLink && !communicationSummaryLink && !fullSummaryLink) {
        toast.info(getTranslation(t, 'workshops.info.summaryPDFNotAvailable'));
        return;
      }

      const workshopTeamName = workshops.find((workshop) => workshop._id === workshopId)?.teamName;

      if (wordCloudSummaryLink)
        await downloadPDFWithURL(
          wordCloudSummaryLink,
          workshopTeamName + ' - WordCloudSummary.pdf'
        );
      if (communicationSummaryLink)
        await downloadPDFWithURL(
          communicationSummaryLink,
          workshopTeamName + ' - CommunicationSummary.pdf'
        );
      if (fullSummaryLink)
        await downloadPDFWithURL(fullSummaryLink, workshopTeamName + ' - FullSummary.pdf');
    } catch (error) {
      toast.error(getTranslation(t, 'workshops.errors.failSummaryPDF'));
      console.log('Failed to download summary PDF:', error);
    } finally {
      setIsLoadingPDFIndex(-1);
    }
  };

  return (
    <>
      <WorkshopProvider>
        <NewWorkshop
          isOpen={isWorkshopModalOpen}
          setIsOpen={setIsWorkshopModalOpen}
          onUpdate={fetchAllWorkshops}
        />
      </WorkshopProvider>
      {isDeleteWorkshopModelOpen && (
        <ConfirmWorkshopDeletePopup
          isLoading={isDeleteWorkshopLoading}
          isModalOpen={isDeleteWorkshopModelOpen}
          handleCloseModal={handleWorkshopModalClose}
          handleDeleteWorkshop={handleWorkshopDelete}
          primary={primary}
        />
      )}
      <div className="flex flex-col items-start h-calc-vh overflow-y-auto">
        <div className="mb-5 w-full">
          <h1 className="text-2xl font-bold text-left text-cblack-primary mb-2.5">
            {getTranslation(t, 'workshops.pageTitle')}
          </h1>
        </div>
        <div className="w-full mt-8">
          <button
            className="flex items-center justify-center gap-3 bg-[#00000008] font-semibold p-4 rounded-md mb-12 shadow hover:shadow-md active:shadow ml-1 mt-1"
            onClick={handleNewWorkshopClick}>
            <PlusIcon bg={primary} />
            <span> {getTranslation(t, 'workshops.newWorkshopBtn')}</span>
          </button>
          <WorkshopTabs
            activeTab={activeTab}
            handleTabChange={handleTabChange}
            translateFn={t}
            primary={primary}
          />
          {activeTab === WORKSHOP_LIST_TYPE.UPCOMING && (
            <p>{getTranslation(t, 'workshops.infoTitle')}</p>
          )}
          {workshops.length > 0 && (
            <div className="md:w-full w-screen overflow-x-auto pr-6">
              <table className="table-auto w-full border-separate border-spacing-y-6">
                <thead className="font-semibold">
                  <tr className="text-center">
                    <td className="text-sm">
                      {getTranslation(t, 'workshops.tableHeader.teamName')}
                    </td>
                    <td className="text-sm">{getTranslation(t, 'workshops.tableHeader.type')}</td>
                    <td className="text-sm">
                      {getTranslation(t, 'workshops.tableHeader.groupSize')}
                    </td>
                    <td className="text-sm">{getTranslation(t, 'workshops.tableHeader.date')}</td>
                    <td className="text-sm">{getTranslation(t, 'workshops.tableHeader.time')}</td>
                    <td className="text-sm">
                      {getTranslation(t, 'workshops.tableHeader.language')}
                    </td>
                    <td className="text-sm">
                      {activeTab === WORKSHOP_LIST_TYPE.UPCOMING
                        ? getTranslation(t, 'workshops.tableHeader.delete')
                        : getTranslation(t, 'workshops.tableHeader.download')}
                    </td>
                    {activeTab === WORKSHOP_LIST_TYPE.UPCOMING && <td></td>}
                  </tr>
                </thead>
                <tbody>
                  {workshops.map((workshop, index) => {
                    return (
                      <tr className="text-center" key={workshop._id}>
                        <td
                          className="py-4 rounded-l-lg min-w-1k text-white px-2 text-xs"
                          style={{ backgroundColor: primary, borderColor: primary }}>
                          {workshop.teamName} <br /> {workshop?.moderatorName}
                        </td>
                        <td
                          className="py-4 border-1 border-r-0 rounded-l-none min-w-1k px-2 text-xs"
                          style={{ borderColor: primary }}>
                          {getTranslation(t, `workshops.tableHeader.${workshop?.type ?? 'online'}`)}
                        </td>
                        <td
                          className="py-4 border-1 border-r-0 min-w-1k px-2 text-xs"
                          style={{ borderColor: primary }}>
                          {workshop.groupSize}
                        </td>
                        <td
                          className="py-4 border-1 border-r-0 min-w-1k px-2 text-xs"
                          style={{ borderColor: primary }}>
                          {workshop.date}
                        </td>
                        <td
                          className="py-4 border-1 border-r-0 min-w-1k px-2 text-xs"
                          style={{ borderColor: primary }}>
                          {workshop?.workshopTimeString ?? workshop?.time}
                        </td>
                        <td
                          className="py-4 border-1 border-r-0 min-w-1k px-2 text-xs"
                          style={{ borderColor: primary }}>
                          {workshop.language}
                        </td>
                        <td
                          className={clsx(
                            'py-4 border-1 min-w-1k rounded-r-lg t-center px-2 text-xs'
                          )}
                          style={{
                            borderColor: primary,
                            background:
                              activeTab === WORKSHOP_LIST_TYPE.PAST ? primary : 'transparent'
                          }}>
                          {activeTab === WORKSHOP_LIST_TYPE.PAST ? (
                            <button
                              className="flex items-center justify-center gap-1 text-white w-full"
                              style={{ backgroundColor: 'transparent' }}
                              onClick={() => handleSummaryDownloadClick(workshop._id, index)}>
                              {isLoadingPDFIndex !== index && <DownloadsIcon type="text-white" />}
                              {isLoadingPDFIndex === index && <Spinner color="#FFFFFF" />}
                              <span>{getTranslation(t, 'workshops.teamProfileDownloadBtn')}</span>
                            </button>
                          ) : (
                            <button onClick={() => showDeletionConfirmationModal(workshop._id)}>
                              <Trash color={primary} />
                            </button>
                          )}
                        </td>
                        {activeTab === WORKSHOP_LIST_TYPE.UPCOMING && (
                          <td className="py-4 px-2 text-xs">
                            <Link to={`${basePath}/workshops/${workshop._id}`}>
                              <Setting color={primary} />
                            </Link>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>

      {loading && <FullScreenLoader />}
    </>
  );
};

export default Workshops;
