export const EnglishIconSvg = () => {
  return (
    <i>
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M22 44C34.1503 44 44 34.1503 44 22C44 9.84974 34.1503 0 22 0C9.84974 0 0 9.84974 0 22C0 34.1503 9.84974 44 22 44Z"
          fill="#F0F0F0"
        />
        <path
          d="M4.54774 8.60596C2.81962 10.8543 1.51655 13.4458 0.757812 16.261H12.2028L4.54774 8.60596Z"
          fill="#0052B4"
        />
        <path
          d="M43.2422 16.2609C42.4834 13.4458 41.1803 10.8543 39.4522 8.60596L31.7974 16.2609H43.2422Z"
          fill="#0052B4"
        />
        <path
          d="M0.757812 27.7393C1.51664 30.5544 2.81971 33.1458 4.54774 35.3941L12.2025 27.7393H0.757812Z"
          fill="#0052B4"
        />
        <path
          d="M35.3937 4.54783C33.1454 2.81971 30.554 1.51664 27.7388 0.757812V12.2027L35.3937 4.54783Z"
          fill="#0052B4"
        />
        <path
          d="M8.60596 39.4522C10.8543 41.1803 13.4458 42.4833 16.2609 43.2422V31.7974L8.60596 39.4522Z"
          fill="#0052B4"
        />
        <path
          d="M16.2608 0.757812C13.4457 1.51664 10.8543 2.81971 8.60596 4.54774L16.2608 12.2026V0.757812Z"
          fill="#0052B4"
        />
        <path
          d="M27.7393 43.2422C30.5544 42.4833 33.1458 41.1803 35.3941 39.4522L27.7393 31.7974V43.2422Z"
          fill="#0052B4"
        />
        <path
          d="M31.7974 27.7393L39.4522 35.3942C41.1803 33.1459 42.4834 30.5544 43.2422 27.7393H31.7974Z"
          fill="#0052B4"
        />
        <path
          d="M43.8138 19.1305H24.8697H24.8696V0.186227C23.9302 0.0639375 22.9726 0 22 0C21.0273 0 20.0698 0.0639375 19.1305 0.186227V19.1303V19.1304H0.186227C0.0639375 20.0698 0 21.0274 0 22C0 22.9727 0.0639375 23.9302 0.186227 24.8695H19.1303H19.1304V43.8138C20.0698 43.9361 21.0273 44 22 44C22.9726 44 23.9302 43.9361 24.8695 43.8138V24.8697V24.8696H43.8138C43.9361 23.9302 44 22.9727 44 22C44 21.0274 43.9361 20.0698 43.8138 19.1305Z"
          fill="#D80027"
        />
        <path
          d="M27.7393 27.7393L37.5564 37.5565C38.0079 37.1052 38.4386 36.6333 38.8496 36.1441L30.4447 27.7393H27.7393V27.7393Z"
          fill="#D80027"
        />
        <path
          d="M16.2606 27.7393H16.2604L6.44336 37.5563C6.8947 38.0078 7.36659 38.4386 7.85574 38.8495L16.2606 30.4445V27.7393Z"
          fill="#D80027"
        />
        <path
          d="M16.2607 16.2608V16.2606L6.44358 6.44336C5.99206 6.8947 5.56134 7.36659 5.15039 7.85574L13.5553 16.2607H16.2607V16.2608Z"
          fill="#D80027"
        />
        <path
          d="M27.7393 16.2608L37.5565 6.44349C37.1052 5.99198 36.6333 5.56126 36.1441 5.15039L27.7393 13.5553V16.2608Z"
          fill="#D80027"
        />
      </svg>
    </i>
  );
};

export const GermanIconSvg = () => {
  return (
    <i>
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_628_2308)">
          <path
            d="M1.36768 29.6522C4.47612 38.0298 12.5401 44.0001 21.9993 44.0001C31.4585 44.0001 39.5225 38.0298 42.6309 29.6522L21.9993 27.7393L1.36768 29.6522Z"
            fill="#FFDA44"
          />
          <path
            d="M21.9993 0C12.5401 0 4.47612 5.97025 1.36768 14.3479L21.9993 16.2608L42.6309 14.3478C39.5225 5.97025 31.4585 0 21.9993 0Z"
            fill="black"
          />
          <path
            d="M1.36789 14.3477C0.483598 16.731 -0.000488281 19.3087 -0.000488281 21.9998C-0.000488281 24.6908 0.483598 27.2686 1.36789 29.6519H42.6312C43.5155 27.2686 43.9995 24.6908 43.9995 21.9998C43.9995 19.3087 43.5155 16.731 42.6311 14.3477H1.36789Z"
            fill="#D80027"
          />
        </g>
        <defs>
          <clipPath id="clip0_628_2308">
            <rect width="44" height="44" fill="white" transform="translate(-0.000488281)" />
          </clipPath>
        </defs>
      </svg>
    </i>
  );
};

export const ItalianIconSvg = () => {
  return (
    <i>
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_628_2313)">
          <path
            d="M21.9995 44C34.1498 44 43.9995 34.1503 43.9995 22C43.9995 9.84974 34.1498 0 21.9995 0C9.84925 0 -0.000488281 9.84974 -0.000488281 22C-0.000488281 34.1503 9.84925 44 21.9995 44Z"
            fill="#F0F0F0"
          />
          <path
            d="M43.9997 21.9998C43.9997 12.5406 38.0295 4.47661 29.6519 1.36816V42.6315C38.0295 39.5229 43.9997 31.459 43.9997 21.9998Z"
            fill="#D80027"
          />
          <path
            d="M-0.000488281 21.9998C-0.000488281 31.459 5.96976 39.5229 14.3474 42.6314V1.36816C5.96976 4.47661 -0.000488281 12.5406 -0.000488281 21.9998Z"
            fill="#6DA544"
          />
        </g>
        <defs>
          <clipPath id="clip0_628_2313">
            <rect width="44" height="44" fill="white" transform="translate(-0.000488281)" />
          </clipPath>
        </defs>
      </svg>
    </i>
  );
};

export const FrenchIconSvg = () => {
  return (
    <i>
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_628_2318)">
          <path
            d="M21.999 44C34.1493 44 43.999 34.1503 43.999 22C43.999 9.84974 34.1493 0 21.999 0C9.84876 0 -0.000976562 9.84974 -0.000976562 22C-0.000976562 34.1503 9.84876 44 21.999 44Z"
            fill="#F0F0F0"
          />
          <path
            d="M43.9992 21.9998C43.9992 12.5406 38.029 4.47661 29.6514 1.36816V42.6315C38.029 39.5229 43.9992 31.459 43.9992 21.9998Z"
            fill="#D80027"
          />
          <path
            d="M-0.000976562 21.9998C-0.000976562 31.459 5.96936 39.5229 14.3469 42.6314V1.36816C5.96936 4.47661 -0.000976562 12.5406 -0.000976562 21.9998Z"
            fill="#0052B4"
          />
        </g>
        <defs>
          <clipPath id="clip0_628_2318">
            <rect width="44" height="44" fill="white" transform="translate(-0.000976562)" />
          </clipPath>
        </defs>
      </svg>
    </i>
  );
};
