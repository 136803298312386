export const availableLanguages = ['en', 'fr', 'de', 'it'];

let orgPrefix = '';
let teamType = '';
let region = '';

export const setOrgPrefix = (prefix) => {
  orgPrefix = prefix;
};

export const setTranslationTeamType = (type) => {
  teamType = type;
};

export const setRegion = (reg) => {
  region = reg;
};

export const fetchCorrectTranslation = (translation) => {
  if (translation?.[region] !== undefined && !translation?.[region]) return null;
  if (translation?.[orgPrefix] !== undefined && !translation?.[orgPrefix]) return null;

  return translation?.[region]
    ? translation?.[region]
    : translation?.[orgPrefix]
    ? translation?.[orgPrefix]
    : translation?.default;
};

export const getTranslation = (
  t,
  key,
  array = false,
  index = 0,
  subKey = '',
  returnObject = false
) => {
  let translation = t(`${teamType}.${key}`, { returnObjects: true });

  if (returnObject) return translation;

  if (array) translation = translation?.[index]?.[subKey] ?? translation?.[index];

  const returningTranslation = fetchCorrectTranslation(translation);

  if (returningTranslation === null) return null;

  return returningTranslation && returningTranslation !== ''
    ? returningTranslation
    : `${teamType}.${key}`?.toString();
};
