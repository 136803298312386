import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { trackEvent } from '../utils';
import { getTranslation } from '../utils/translation';

const AboutUs = () => {
  const { t } = useTranslation();

  useEffect(() => {
    trackEvent('page_view', {
      organization: 'SSK'
    });
  }, []);

  return (
    <div className="flex flex-col items-start h-calc-vh overflow-y-auto">
      <div className="mb-5 w-full">
        <div className="w-9/12 block">
          <h1 className="text-2xl text-cblack-primary font-bold mb-2.5">
            {getTranslation(t, 'aboutUs.title')}
          </h1>
        </div>
        <div
          className="w-full mt-8"
          dangerouslySetInnerHTML={{ __html: getTranslation(t, 'aboutUs.body') }}></div>
      </div>
      {/* TO render these classes for youtube player */}
      <span className="hidden aspect-h-9 aspect-w-16 w-full" />
    </div>
  );
};

export default AboutUs;
