import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { AdminContext } from '../contexts/admin.context';

import { forgetPasswordSchema } from '../schema/login.schema';
import Button from '../components/common/Button';
import AdminInput from '../components/common/AdminInput';
import { forgetpasswordAPI } from '../service';
import { useTranslation } from 'react-i18next';
import Spinner from '../components/common/Spinner';
import { CrossBackButton } from '../components';
import { getTranslation } from '../utils/translation';

const ForgetPassword = () => {
  const { themeId: theme, teamType } = useContext(AdminContext);

  const navigate = useNavigate();
  const basePath = `/${theme}/${teamType}`;

  const { t } = useTranslation();

  const submitHandler = async (formValues) => {
    try {
      const formattedFormValues = {
        id: formValues.email
      };
      const response = await forgetpasswordAPI(formattedFormValues, theme, teamType);
      if (response.status === 203 || response.status === 200)
        navigate(basePath + '/security-code', { state: { email: formValues.email } });
    } catch (error) {
      if (error.response) toast.error("les informations d'identification invalides");
      console.error('ERROR', error);
    } finally {
      formik.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: forgetPasswordSchema,
    onSubmit: submitHandler
  });

  return (
    <>
      {/* <AuthHeader theme={theme} /> */}
      <div className={`flex flex-col md:flex-row`}>
        <CrossBackButton />
        <div className="mx-2 md:mx-auto md:max-w-3xl relative min-h-screen">
          <div className="bg-cblack mt-32 pb-2">
            <h1 className="text-4xl font-bold md:text-cback mb-4">
              {getTranslation(t, 'forgetPassword.title')}
            </h1>
            <p>{getTranslation(t, 'forgetPassword.description1')}</p>
            <p>{getTranslation(t, 'forgetPassword.description2')}</p>
          </div>

          <form className="py-8 mx-2" onSubmit={formik.handleSubmit}>
            <div className="flex flex-col justify-between">
              <AdminInput
                className={'bg-gray-100'}
                label={getTranslation(t, 'forgetPassword.input.email.label')}
                placeholder={getTranslation(t, 'forgetPassword.input.email.placeholder')}
                type="email"
                name="email"
                formik={formik}
                isDark={true}
              />
            </div>
            <div className="mt-6 flex justify-start">
              <Button buttonType={'login'} type="submit" disabled={formik?.isSubmitting}>
                {formik?.isSubmitting && <Spinner color="#ffffff" />}
                {getTranslation(t, 'forgetPassword.button')}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
