import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';

import Button from '../common/Button.jsx';
import LanguageSelectDropdown from '../common/languageSelectDropdown.jsx';
import Menu from './Menu/index.js';

import { getTranslation } from '../../utils/translation.js';
import { languageOption } from '../../utils/data.js';
import { MenuIcon, SSMFLogo } from '../../assets/logos/index.js';

export default function Header({ themeId, teamType, className = '', colorScheme = 'chswiss-500' }) {
  const { lang } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [showMenu, setShowMenu] = useState(false);
  const [parsedMenuLinks, setParsedMenuLink] = useState([]);

  const [selectedLanguage, setSelectedLanguage] = useState({
    label: 'DE',
    value: 'de'
  });

  const handleChange = (e) => {
    setSelectedLanguage(e);
    i18n.changeLanguage(e.value);

    try {
      localStorage.setItem('lang', e.value);
    } catch (error) {
      console.log('LS unavailable');
    }
  };

  const onRedirectHandle = () => {
    navigate(`/${themeId}/${teamType}/login`);
  };

  useEffect(() => {
    try {
      const storedLang = lang?.toLowerCase() ?? localStorage.getItem('lang');
      const changedLang = languageOption.filter((item) => {
        return item.value == storedLang;
      });
      if (changedLang?.[0]) {
        setSelectedLanguage(changedLang[0]);
        i18n.changeLanguage(storedLang);
      }
    } catch (error) {
      console.log('LS unavailable');
    }
  }, [lang]);

  useEffect(() => {
    try {
      const menuData = t(`${teamType}.landing.navigation_data`, { returnObjects: true })?.mobile;

      setParsedMenuLink(menuData);
    } catch (error) {
      console.log('error loading menu items', error);
    }
  }, [t]);

  return (
    <>
      <Menu
        menuData={parsedMenuLinks}
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        colorScheme="swiss"
      />

      {
        <header
          className={`header-section ${className} bg-white fixed top-0 left-0 z-50 bg-${colorScheme} w-full shadow-xl`}>
          <div className=" sm:py-3 py-0 px-5 sm:px-0 bg-swiss-300 md:bg-white flex justify-between items-center">
            <div className="hidden sm:block lg:w-[280px]">
              <div className="sm:absolute sm:top-0 sm:left-0 header-logo">
                <SSMFLogo className={'block w-full sm:max-w-[280px] h-auto'} />
              </div>
            </div>
            <div className="md:relative md:left-[105px] lg:left-0 xl:relative xl:left-0 hidden gap-5 md:flex">
              <HashLink
                scroll={(el) =>
                  el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                  })
                }
                to={'#signup'}>
                <div
                  className="cursor-pointer hover:bg-swiss-100 px-8 py-2 rounded-full"
                  dangerouslySetInnerHTML={{
                    __html: getTranslation(t, 'landing.header_data.point1')
                  }}
                />
              </HashLink>
            </div>
            <div className="flex items-center pr-4 lg:pr-16 w-full md:w-auto">
              <div className="hidden md:flex gap-4 h-12 items-center justify-center mr-4">
                <Button buttonType="ch-landing" onClick={() => onRedirectHandle()}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: getTranslation(t, 'landing.header_data.point3')
                    }}
                  />
                </Button>
                <Button
                  buttonType="ch-landing-secondary"
                  isExternalLink
                  link={getTranslation(t, 'landing.header_data.link4')}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: getTranslation(t, 'landing.header_data.point4')
                    }}
                  />
                </Button>
                <div className="max-w-28 mt-4">
                  <LanguageSelectDropdown
                    backgroundColor="white"
                    value={selectedLanguage}
                    options={languageOption}
                    onChange={handleChange}
                    themeId="ch"
                  />
                </div>
              </div>
              <div
                className="block w-full md:w-auto"
                onClick={() => {
                  setShowMenu(true);
                }}>
                <div className="md:hidden relative right-0 top-0 h-[65px] flex items-center justify-between pl-3">
                  <SSMFLogo className={`h-[200px] w-auto translate-y-[72px]`} />
                  <MenuIcon color="#FFFFFF" />
                </div>
              </div>
            </div>
          </div>
        </header>
      }
    </>
  );
}
