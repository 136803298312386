import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheck } from 'react-icons/fa';
import { getTranslation } from '../../utils/translation';

export default function TeamFuture({ teamType }) {
  const { t } = useTranslation();

  return (
    <div>
      <div className="relative mb-10">
        <div className="relative mt-[65px] md:mt-[68px]">
          <img
            className="md:block w-full h-full object-cover object-center"
            alt="Mission Future Team"
            src={getTranslation(t, 'landing.team_future.background_image')}
          />
        </div>
      </div>
      {teamType === 'school' && (
        <div className="mx-3">
          <div className="bg-swiss-50 py-3 px-1 sm:px-5 w-fit mx-auto mt-5 rounded-md flex gap-x-3 items-center justify-center mb-20">
            <FaCheck
              className="text-white bg-swiss-300 h-6 w-6 text-xs rounded-full p-1"
              size={10}
            />
            <span className="font-bold">
              {getTranslation(t, 'landing.team_future.caption1')}{' '}
              <span className="font-normal">
                {getTranslation(t, 'landing.team_future.caption2')}
              </span>
            </span>
          </div>
        </div>
      )}
      <div className="px-10 lg:px-0 m-auto relative max-w-[1000px]">
        <div className="mb-20 relative">
          <div
            className="sm:text-4xl text-3xl font-extrabold md:mb-20 mb-5 text-center text-cblack-secondary"
            dangerouslySetInnerHTML={{ __html: getTranslation(t, 'landing.team_future.titile') }}
          />
          <img
            src="/images/back-swiss.png"
            className="absolute top-[-60px] right-[10%] md:right-[25%] z-[-1] max-w-[200px] md:max-w-[500px]"
            alt=""
          />
          <div className="flex gap-10 flex-col md:flex-row mx-auto">
            <div className="flex gap-5 flex-col mx-auto w-full md:w-2/4 text-cblack-secondary">
              <p
                dangerouslySetInnerHTML={{
                  __html: getTranslation(t, 'landing.team_future.description1')
                }}
                className="md:leading-7 md:text-[20px] text-base text-center md:text-left"></p>
              <p
                dangerouslySetInnerHTML={{
                  __html: getTranslation(t, 'landing.team_future.description2')
                }}
                className="md:leading-7 md:text-[20px] text-base text-center md:text-left"></p>
              {getTranslation(t, 'landing.team_future.description3') && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: getTranslation(t, 'landing.team_future.description3')
                  }}
                  className="md:leading-7 md:text-[20px] text-base text-center md:text-left"></p>
              )}
            </div>

            <div className="block w-full md:w-2/4 mt-5 md:mt-0 text-center md:text-left">
              <img
                src={getTranslation(t, 'landing.team_future.profile_image')}
                className="mx-auto"
                alt=""
              />
              {getTranslation(t, 'landing.team_future.preview') &&
                getTranslation(t, 'landing.team_future.preview.button') &&
                getTranslation(t, 'landing.team_future.preview.url') && (
                  <div className="my-5 px-3 text-center justify-center flex">
                    <a
                      href={getTranslation(t, 'landing.team_future.preview.url')}
                      target="_blank"
                      rel="noreferrer"
                      className="font-bold text-md bg-swiss-300 px-[25px] py-[12px] text-white rounded-full flex items-center justify-center gap-2 hover:opacity-80 outline-none hover:bg-swiss-button-600 border-transparent hover:border-swiss-300 border-2">
                      {getTranslation(t, 'landing.team_future.preview.button')}
                    </a>
                  </div>
                )}
            </div>
          </div>
        </div>

        {teamType === 'school' && (
          <div className="bg-swiss-50 text-center py-10 px-2 rounded-xl my-20">
            <h1
              className="font-bold text-4xl"
              dangerouslySetInnerHTML={{
                __html: getTranslation(t, 'landing.team_future.infoBox.title')
              }}
            />
            <ul className="text-left max-w-3xl mx-auto flex flex-col items-center md:items-start justify-start gap-5 mt-3 !px-2">
              {[...new Array(3)].map((_, index) => (
                <li
                  key={index}
                  className="flex items-center flex-col md:flex-row gap-y-2 md:gap-x-2">
                  <span className="bg-swiss-300 rounded-full ">
                    <FaCheck className="text-white  h-7 w-7 text-xs p-1" size={10} />
                  </span>
                  <span
                    className="text-center md:text-left"
                    dangerouslySetInnerHTML={{
                      __html: getTranslation(t, `landing.team_future.infoBox.info${index + 1}`)
                    }}
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
