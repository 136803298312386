import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';

import { AdminContext } from '../../contexts/admin.context';
import { AddParticipantsSchema } from '../../schema/project.schema';
import { addParticipants } from '../../service';

import Button from '../common/Button';
import NumberInput from '../common/NumberInput';
import { getTranslation } from '../../utils/translation';

const AddParticipants = ({ projectId, onAdd }) => {
  const { t } = useTranslation();

  const { setContentPopupInfo } = useContext(AdminContext);

  const handleSubmit = async (formValues) => {
    try {
      const response = await addParticipants(projectId, formValues.noOfParticipants);
      if (response.status === 200) {
        toast.success(getTranslation(t, 'addParticipants.success'));
        // hide popup
        onAdd(formValues.noOfParticipants);
        setContentPopupInfo({ isVisible: false });
        return;
      }
      toast.error(getTranslation(t, 'addParticipants.fail'));
    } catch (e) {
      toast.error(getTranslation(t, 'addParticipants.fail'));
      console.error('Failed to add participants:', e);
    }
  };

  const formik = useFormik({
    initialValues: {
      noOfParticipants: 1
    },
    validationSchema: AddParticipantsSchema,
    onSubmit: handleSubmit
  });

  return (
    <div className="flex flex-col items-center justify-center">
      <h2 className="text-xl font-semibold">{getTranslation(t, 'addParticipants.heading')}</h2>
      <p className="text-base text-gray-500">{getTranslation(t, 'addParticipants.info')}</p>
      <form onSubmit={formik.handleSubmit} className="mb-8">
        <div className="my-8">
          <NumberInput isDark={true} name="noOfParticipants" min="0" formik={formik} />
        </div>
        <div className="flex justify-center">
          <Button buttonType="primary" type="submit">
            {getTranslation(t, 'addParticipants.add')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddParticipants;
