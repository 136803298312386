import clsx from 'clsx';

const MenuItemContent = ({ isSelected, children }) => {
  return (
    <span
      className={clsx('text-lg font-semibold', {
        'text-[#515151]': !isSelected
      })}>
      {children}
    </span>
  );
};

export default MenuItemContent;
