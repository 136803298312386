import Select from 'react-select';

export default function LanguageSelectDropdown({
  themeId,
  className,
  label,
  error,
  touched,
  options,
  backgroundColor = '',
  styleType = 'default', // or mini  // some different styles for internal
  ...props
}) {
  return (
    <div className="signupinwrap ">
      {label && (
        <label className="block mb-1 text-base font-bold text-cblack-primary">{label}</label>
      )}
      <Select
        styles={{
          control: (base) => ({
            ...base,
            borderRadius: '999px',
            padding: styleType === 'mini' ? '1px' : '6px',
            borderColor: '#333',
            borderWidth: '0.5px',
            backgroundColor: backgroundColor
              ? backgroundColor
              : themeId === 'ch'
              ? '#e5efef'
              : 'white'
          }),
          menu: (provided) => ({
            ...provided,
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.45)',
            borderRadius: '10px',
            padding: '10px 0',
            top: styleType === 'mini' ? '30px' : '50px'
          }),
          option: (provided, state) => ({
            ...provided,
            fontWeight: 'bold',
            cursor: 'pointer',
            color:
              themeId === 'ch'
                ? state.isFocused
                  ? '#7fafaf'
                  : '#005f61'
                : state.isFocused
                ? 'gray'
                : 'black'
          }),
          menuList: (provided) => ({
            ...provided,
            backgroundColor: 'white'
          })
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: themeId === 'ch' ? 'white' : 'gray',
            primary: themeId === 'ch' ? 'white' : 'black'
          }
        })}
        className={`mb-4 rounded-full ${className}`}
        options={options}
        {...props}
      />
      {error && touched && <p className="text text-red-500">{error}</p>}
    </div>
  );
}
