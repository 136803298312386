import { createContext, useState } from 'react';

import { WORKSHOP_DEFAULT_VALUE, WORKSHOP_FIXED_VALUES } from '../data/default-values';

export const WorkshopContext = createContext();

export const WorkshopProvider = (props) => {
  const [workshopData, setWorkshopData] = useState(WORKSHOP_DEFAULT_VALUE);
  const [workshopFixedData, setWorkshopFixedData] = useState(WORKSHOP_FIXED_VALUES);
  const [activeStep, setActiveStep] = useState('step1');

  return (
    <WorkshopContext.Provider
      value={{
        workshopData,
        activeStep,
        workshopFixedData,
        setWorkshopFixedData,
        setWorkshopData,
        setActiveStep
      }}>
      {props.children}
    </WorkshopContext.Provider>
  );
};
