import React, { useContext } from 'react';
import { STATIC_CONTENT_LINK } from '../../data/default-values';
import { WhiteDownloadsIcon, InfoCircleIcon } from '../icons';
import { AdminContext } from '../../contexts/admin.context';

const DownloadItem = ({ name, link, directLink, setShowText, isInfo = false, index = 0 }) => {
  const { themeConfig: { secondary } = {} } = useContext(AdminContext);

  const formattedLink = directLink ? link : STATIC_CONTENT_LINK + link;
  const handleItemDownload = () => {
    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = formattedLink;
    link.target = '_blank';
    if (formattedLink) link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();

    // clean up "a" element
    document.body.removeChild(link);
  };

  const handleShowInfo = () => {
    setShowText(index);
  };

  return (
    <div className="rounded-lg px-3 py-3 border-2 border-[#51515185] mb-4 flex gap-2 items-center justify-between">
      <div className="bg-gray-100 p-4 rounded-full flex items-center gap-2 justify-start w-full">
        {isInfo && (
          <button type="button" onClick={handleShowInfo}>
            <InfoCircleIcon />
          </button>
        )}
        <span className="text-lg text-gray-primary w-42">{name}</span>
      </div>
      <button
        className="px-4 py-3.5 rounded-full"
        style={{ backgroundColor: secondary }}
        type="button"
        onClick={handleItemDownload}>
        <WhiteDownloadsIcon />
      </button>
    </div>
  );
};

export default DownloadItem;
