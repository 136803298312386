import clsx from 'clsx';

const MenuItem = ({ isSelected = false, sidebarColor, className = '', onClick, children }) => {
  return (
    <li
      onClick={onClick ? onClick : null}
      className={clsx(
        `rounded md:px-4 px-2 py-3 mb-2 flex gap-5 justify-start items-center cursor-pointer duration-300 ease-in-out relative`,
        { 'bg-white': !isSelected },
        className
      )}
      style={{
        backgroundColor: isSelected ? sidebarColor : '',
        color: isSelected ? '#000000' : ''
      }}>
      {children}
    </li>
  );
};

export default MenuItem;
// group
