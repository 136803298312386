import React from 'react';
import { useTranslation } from 'react-i18next';
import ResponsiveVimeoVideo from './responsiveVimeoVideo';
import { getTranslation } from '../../utils/translation';

export default function TeamFutureVideo() {
  const { t } = useTranslation();

  return (
    <div>
      <div className="md:my-[135px] my-[40px]">
        <div className="text-cblack-secondary font-semibold text-base  md:text-[22px] text-center mb-16">
          {getTranslation(t, 'landing.team_future.video_title')}
        </div>
        <div className="md:max-w-[800px] max-w-[75%] mx-auto">
          <ResponsiveVimeoVideo url={getTranslation(t, 'landing.team_future.videoUrl')} />
        </div>
      </div>
    </div>
  );
}
