import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { WorkshopContext } from '../contexts/workshop.context';
import Button from './common/Button';
import { getTranslation } from '../utils/translation';

const SuccessModel = ({ isUpdated, closeModal, primary }) => {
  const { t } = useTranslation();
  const { setActiveStep } = useContext(WorkshopContext);

  const openNewWorkShop = () => {
    setActiveStep('step1');
  };

  const onCloseModal = () => {
    closeModal();
  };

  return (
    <div className="px-[60px] pb-[69px] pt-5">
      <button
        onClick={() => {
          onCloseModal();
        }}
        className="flex items-center justify-center bg-gray-100 p-3 w-10 h-10 rounded-full self-start mb-8">
        <svg
          className="w-5 h-5"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.17969 20.2447L20.2464 1.17806M20.2464 20.2447L1.17969 1.17806"
            stroke={primary}
            strokeWidth="3"></path>
        </svg>
      </button>
      <h1 className="text-2xl font-bold pb-[30px] flex items-center">
        <span className="text-[26px] mr-3.5">&#127881;</span>{' '}
        {isUpdated
          ? getTranslation(t, 'workshops.popups.booked.update.title')
          : getTranslation(t, 'workshops.popups.booked.title')}
      </h1>
      <div className="text-swiss-bw-200">
        <div className="mb-5">
          <p
            dangerouslySetInnerHTML={{
              __html: getTranslation(t, 'workshops.popups.booked.text1')
            }}
          />
        </div>
        <p
          className="mb-[30px]"
          dangerouslySetInnerHTML={{
            __html: getTranslation(t, 'workshops.popups.booked.text2')
          }}
        />
        <Button
          onClick={openNewWorkShop}
          className="font-bold rounded-full py-4 px-8 text-white"
          buttonType="primary">
          {getTranslation(t, 'workshops.popups.booked.buttonText')}
        </Button>
      </div>
    </div>
  );
};

export default SuccessModel;
