import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from '../components/landing/SwissHeader';
import TeamFuture from '../components/landing/TeamFuture';
import TargetGroup from '../components/landing/TargetGroup';
import FutureTeamSupport from '../components/landing/FutureTeamSupport';
import Tools from '../components/landing/Tools';
import WorkFlow from '../components/landing/WorkFlow';
import Signup from './signup';
import Partners from '../components/landing/Partner';
import CardPopup from '../components/common/CardPopup';
import TeamFutureVideo from '../components/landing/TeamFutureVideo';
import LandingContact from '../components/landing/LandingContact';
import Footer from '../components/landing/Footer';
import { BaseFooter } from '../components';

import { trackEvent } from '../utils';
import { languageOption } from '../utils/data';
import { getTranslation } from '../utils/translation';

import { PopupCrossIcon } from '../components/icons';

const SwissLanding = ({ themeId, teamType, tRegion }) => {
  const { lang } = useParams();

  const { t, i18n } = useTranslation();

  const [showImage, setShowImage] = useState(false);
  const [imageName, setImageName] = useState('');
  const [showpopUp, setShowPopup] = useState(false);

  const setImagePopup = (imageName) => {
    setImageName(imageName);
    setShowImage(!showImage);
  };

  useEffect(() => {
    trackEvent('page_view', {
      organization: 'SSK'
    });

    if (languageOption.find((item) => item.value === lang?.toLowerCase())) {
      i18n.changeLanguage(lang);
      try {
        localStorage.setItem('lang', lang);
      } catch (error) {
        console.log('LS unavailable');
      }
    }
  }, [lang]);

  useEffect(() => {
    if (teamType === 'team') document.title = `SwissSkills mission future Team`;
    if (teamType === 'school') document.title = `SwissSkills mission future School`;
  }, []);

  function handlePopUp() {
    setShowPopup(!showpopUp);
  }

  return (
    <div className="ch">
      {showImage && (
        <div className="fixed md:top-[15%] top-0 md:left-[50%] md:-translate-x-2/4 z-[99] md:z-50 w-full md:w-[76%] h-full md:h-[80%] px-2.5 md:px-10 bg-swiss-370 md:rounded-[29px]">
          <span
            onClick={() => setShowImage(!showImage)}
            className="inline-block font-medium px-3 mb-5 pt-6 w-[60px] cursor-pointer">
            <PopupCrossIcon />
          </span>
          <div className="h-full md:h-[80%] overflow-auto ch-blur">
            <img
              className="z-50 w-[98%] md:w-[96%] md:border-2 md:border-swiss-300 md:rounded-[18px] "
              src={imageName}
              alt=""
            />
          </div>
        </div>
      )}
      <Header themeId={themeId} teamType={teamType} />
      <div id="team-future" className="md:pt-0 m-auto">
        <TeamFuture teamType={teamType} />
      </div>
      {teamType?.toLowerCase() === 'team' && <TeamFutureVideo />}
      {teamType?.toLowerCase() === 'team' && (
        <div id="target-group" className="pt-0 sm:pt-20 sm:pb-20 mt-10">
          <TargetGroup handlePopUp={handlePopUp} />
          <h2
            className="mt-8 sm:text-4xl text-2xl font-extrabold mb-5 text-center text-cblack-secondary"
            dangerouslySetInnerHTML={{ __html: getTranslation(t, 'landing.TargetGroup.title1') }}
          />
          <p
            className="text-center md:pb-6 pb-10 mt-[20px] text-base md:text-lg text-cblack-secondary"
            dangerouslySetInnerHTML={{ __html: getTranslation(t, 'landing.TargetGroup.title2') }}
          />
        </div>
      )}
      <div
        id="future-support"
        className="mt-20 md:px-10 max-w-[85%] lg:max-w-[1000px] lg:px-0 xl:px-0  m-auto ">
        <CardPopup
          showPopup={showpopUp}
          children={<FutureTeamSupport />}
          popupDisplayHandler={handlePopUp}
        />
      </div>
      <div
        id="tools"
        className="diagonal-section mt-20 diagonal-section-top diagonal-section-bottom--flip-x pt-24 bg-swiss-100 md:pt-24 lg:pt-10">
        <Tools
          themeId={themeId}
          teamType={teamType}
          tRegion={tRegion}
          setImagePopup={setImagePopup}
        />
      </div>
      <div id="workflow" className="">
        <WorkFlow />
      </div>
      <div
        id="signup"
        className="diagonal-section diagonal-section-top diagonal-section-bottom--flip-x  bg-swiss-100">
        <div className="mx-auto max-w-[1200px] px-2 md:px-14 lg:px-0 py-12">
          <Signup isHeading={false} from={'signup'} submitButtonType={'ch-landing'} />
        </div>
      </div>
      <div id="contact" className="pt-24">
        <LandingContact />
      </div>
      <div id="partner" className="pt-24">
        <Partners />
      </div>
      <div className="pt-24">
        <Footer />
        <BaseFooter />
      </div>
    </div>
  );
};

export default SwissLanding;
