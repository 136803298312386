import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';

const FullScreenLoader = () => (
  <div
    className="fixed top-0 left-0 w-screen h-screen bg-white bg-opacity-50 flex justify-center items-center z-50"
    data-testid="loader">
    <ClimbingBoxLoader color="#767676" loading size={30} />
  </div>
);

export default FullScreenLoader;
