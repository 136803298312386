export const formatDate = (dateString, fullYear = false) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed in JavaScript
  const year = String(date.getFullYear()).slice(fullYear ? 0 : 2);

  return `${day}/${month}/${year}`;
};

export const getTimeZoneDiffBasedDateAndTime = (data) => {
  // Parse the provided date-time
  let date = new Date(data.date);

  // Adjust the date-time using the timeZoneDiff
  let adjustedTimeInMilliseconds = date.getTime() - data.timeZoneDiff * 60 * 60 * 1000; // Subtracting the timeZoneDiff here
  let adjustedDate = new Date(adjustedTimeInMilliseconds);

  // Extract the day, month, and year from the adjusted date-time
  let day = adjustedDate.getUTCDate();
  let month = adjustedDate.getUTCMonth() + 1; // Months are 0-indexed
  let year = adjustedDate.getUTCFullYear();

  // Extract the hours and minutes from the adjusted date-time
  let hours = adjustedDate.getUTCHours();
  let minutes = adjustedDate.getUTCMinutes();

  // Format the hours and minutes in 24-hour format
  let formattedHours = hours.toString().padStart(2, '0');
  let formattedMinutes = minutes.toString().padStart(2, '0');

  // Construct the final formatted date-time string
  let endHour = (hours + 1) % 24; // Add 1 hour to the start hour and modulo to handle the midnight case.
  let formattedDateTime = `${formattedHours}:${formattedMinutes} – ${endHour
    .toString()
    .padStart(2, '0')}:${formattedMinutes}`;

  let formattedDate = `${day.toString().padStart(2, '0')}/${month
    .toString()
    .padStart(2, '0')}/${year}`;

  return {
    date: formattedDate,
    time: formattedDateTime
  };
};

export const convertTo12Hrs = (time24hr) => {
  // Parse the input time string
  const timeParts = time24hr.split(':');
  const hours = parseInt(timeParts[0], 10);
  const minutes = parseInt(timeParts[1], 10);

  // Convert to 12-hour format
  const period = hours < 12 ? 'AM' : 'PM';
  const hours12 = hours % 12 || 12; // Handle midnight (00:00) as 12 AM

  // Create the 12-hour formatted time string without seconds
  const time12hr = `${hours12}:${String(minutes).padStart(2, '0')} ${period}`;

  return time12hr;
};

export const getTimesList = () => {
  // Generate an array of times from 00:00 to 23:30 in 30-minute intervals
  const timesList = Array.from({ length: 48 }, (_, index) => {
    const hour = Math.floor(index / 2);
    const minute = index % 2 === 0 ? '00' : '30';
    return `${String(hour).padStart(2, '0')}:${minute}`;
  });

  // Print the array of times
  return timesList;
};
