import React, { useContext } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { LoginSchema } from '../schema/login.schema';
import { AdminContext } from '../contexts/admin.context';
import AdminInput from '../components/common/AdminInput';
import Button from '../components/common/Button';
import Spinner from '../components/common/Spinner';
import { login, setAuthToken, storeStats } from '../service';
import { getTranslation } from '../utils/translation';

const GenericLogin = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { themeId, teamType } = useContext(AdminContext);

  const basePath = `/${themeId}/${teamType}`;

  const submitHandler = async (formValues) => {
    try {
      const formattedFormValues = {
        id: formValues.email,
        password: formValues.password
      };
      const response = await login(formattedFormValues, themeId, teamType);
      setAuthToken(response.data);
      try {
        localStorage.setItem('token', response.data);
      } catch (error) {
        console.log('LS unavailable');
      }

      try {
        storeStats({
          application: 'teachers_portal',
          event: 'login',
          pageURL: window?.location?.pathname,
          pageTitle: document.title,
          orgPrefix: themeId?.toLowerCase(),
          teamType: teamType?.toLowerCase()
        });
      } catch (error) {
        console.log('Error storing stats', error);
      }

      // navigate to welcome screen
      navigate(`${basePath}/welcome`);
    } catch (error) {
      toast.error(getTranslation(t, 'login.error.toast'));
      console.error('ERROR', error);
    } finally {
      formik.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: LoginSchema,
    onSubmit: submitHandler
  });

  return (
    <div
      className="flex flex-col items-center justify-center w-full min-h-screen"
      data-testid="login-form">
      <div className="max-w-90 w-96 relative h-full">
        <h1
          className="text-4xl font-bold md:text-cback underline decoration-slate-500 decoration-2 underline-offset-8 md:decoration-0 md:decoration-white"
          dangerouslySetInnerHTML={{ __html: getTranslation(t, 'login.title') }}
        />

        <form className="py-8 mx-2" onSubmit={formik.handleSubmit}>
          <div className="flex flex-col justify-between">
            <AdminInput
              label={getTranslation(t, 'login.email')}
              placeholder={getTranslation(t, 'login.emailPlaceholder')}
              type="email"
              name="email"
              formik={formik}
              isDark={true}
            />
            <AdminInput
              label={getTranslation(t, 'login.password')}
              placeholder={getTranslation(t, 'login.passwordPlaceholder')}
              type="password"
              name="password"
              formik={formik}
              isDark={true}
            />
            <Link to={`${basePath}/forget-password`} className="text-sm">
              {getTranslation(t, 'login.forgotPassword')}
            </Link>
            <div className="border-b my-4 border-black" />
          </div>
          <div className="mt-8 flex justify-center">
            <Button
              buttonType="login"
              data-testid="submit-button"
              disabled={formik?.isSubmitting}
              cl>
              {formik?.isSubmitting && <Spinner color="#ffffff" />}
              {!formik?.isSubmitting && getTranslation(t, 'login.button')}
            </Button>
          </div>
        </form>

        <a
          href={getTranslation(t, 'login.switchLogin.link')}
          className="text-base font-normal text-center mt-4"
          dangerouslySetInnerHTML={{ __html: getTranslation(t, 'login.switchLogin.infoText') }}
        />
      </div>
    </div>
  );
};

export default GenericLogin;
