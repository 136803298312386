import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import {
  deleteWorkshop,
  getWorkshopDetails,
  resendWorkshopBookingEmail,
  updateWorkshopGroupSize
} from '../service';
import { getTimeZoneDiffBasedDateAndTime } from '../utils/date';

import FullScreenLoader from '../components/common/fullScreenLoader';
import Button from '../components/common/Button';
import { Trash, BackIcon, PlusIcon2, MinusIcon, EditIcon } from '../components/icons';
import { LANGUAGE_STRINGS } from '../data/default-values';
import { WorkshopContext } from '../contexts/workshop.context';
import { AdminContext } from '../contexts/admin.context';

import NewWorkshop from './NewWorkshop';
import Spinner from '../components/common/Spinner';
import ClipboardCopyField from '../components/common/ClipboardCopyField';
import ConfirmWorkshopDeletePopup from '../components/popups/confirmWorkshopDeletePopup';
import { getTranslation } from '../utils/translation';

const WorkshopInfo = () => {
  const { id: workshopId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [workshop, setWorkshop] = useState({});
  const [isWorkshopModalOpen, setIsWorkshopModalOpen] = useState(false);
  const [isResendEmailLoading, setIsResendEmailLoading] = useState(false);
  const [isDeleteWorkshopLoading, setIsDeleteWorkshopLoading] = useState(false);
  const [isDeleteWorkshopModelOpen, setIsDeleteWorkshopModalOpen] = useState(false);
  const [isUpdated, setUpdated] = useState(false);

  const { setActiveStep, setWorkshopData } = useContext(WorkshopContext);
  const { themeId, teamType, user, themeConfig: { primary } = {} } = useContext(AdminContext);
  const basePath = `/${themeId}/${teamType}`;

  const fetchWorkshopDetails = async () => {
    try {
      const response = await getWorkshopDetails(workshopId);

      const { workshop } = response.data;

      // FORMAT WORKSHOP
      const { workshopDate, timeZoneDiff } = workshop;
      const { date, time } = getTimeZoneDiffBasedDateAndTime({
        date: workshopDate,
        timeZoneDiff
      });
      workshop.date = date;
      workshop.time = time;
      workshop.language = LANGUAGE_STRINGS[workshop.languageId];

      setWorkshop(workshop);
    } catch (error) {
      toast.error(getTranslation(t, 'errors.failedLoadWorkshopDetails'));
      console.error('ERROR', error);
    } finally {
      setLoading(false);
    }
  };

  // GET WORKSHOP DETAILS ON LOAD
  useEffect(() => {
    fetchWorkshopDetails();
  }, [workshopId]);

  const handleWorkshopDelete = () => {
    setIsDeleteWorkshopModalOpen(true);
  };

  const handleDeleteConfirmation = async () => {
    try {
      setIsDeleteWorkshopLoading(true);
      const response = await deleteWorkshop(workshop._id);

      if (response?.status !== 204) {
        toast.error(getTranslation(t, 'workshops.errors.deleteWorkshop'));
        return;
      }

      toast.success(getTranslation(t, 'workshops.success.deleteWorkshop'));
      navigate('/workshops');
    } catch (error) {
      toast.error(getTranslation(t, 'workshops.errors.deleteWorkshop'));
      console.log('Failed to delete workshop: ', error);
    } finally {
      setIsDeleteWorkshopLoading(false);
    }
  };

  const handleBackClick = () => {
    navigate(basePath + '/workshops');
  };

  const handleGroupSizeChange = async (type) => {
    try {
      // CHECK IF WORKSHOP EDITING IS ALLOWED
      if (isWorkshopEditAllowed()) {
        toast.info(getTranslation(t, 'workshops.info.editTimePassed'));
        return;
      }
      const response = await updateWorkshopGroupSize({
        id: workshop._id,
        type
      });

      if (response.status !== 200) {
        toast.error('Failed to update group size');
        return;
      }

      if (type === 'increase') {
        setWorkshop({ ...workshop, groupSize: Number(workshop.groupSize) + 1 });
      } else {
        setWorkshop({ ...workshop, groupSize: Number(workshop.groupSize) - 1 });
      }
      toast.success('Group size updated');
    } catch (error) {
      console.log('Failed to update group size: ', error);
      toast.error('Failed to update group size');
    }
  };

  const isWorkshopEditAllowed = () => {
    // Convert workshopDate to a Date object
    const workshopStartDate = new Date(workshop.workshopDate);

    // Adjust for the timeZoneDiff
    // workshopStartDate.setHours(
    //   workshopStartDate.getHours() + workshop.timeZoneDiff
    // );

    // Calculate the time 24 hours after the workshop starts
    const editDeadline = new Date(workshopStartDate); // Add 24 hours

    // Get the current time
    const currentTime = new Date();

    // If more than 24 hours have passed since the workshop started, editing is not allowed
    return currentTime > editDeadline;
  };

  const handleEditWorkshopClick = () => {
    // CHECK IF WORKSHOP EDITING IS ALLOWED
    if (isWorkshopEditAllowed()) {
      toast.info(getTranslation(t, 'workshops.info.editTimePassed'));
      return;
    }

    setActiveStep('step4');
    setWorkshopData({
      ...workshop,
      teamName: workshop.teamName,
      moderatorName: workshop?.moderatorName,
      groupSize: workshop.groupSize,
      languageId: workshop.languageId,
      workshopId: workshop._id
    });
    setIsWorkshopModalOpen(true);
    setUpdated(true);
  };

  const handleWorkshopModalClose = () => {
    setWorkshopData((currValue) => ({
      ...currValue,
      workshopId: undefined
    }));
    setIsDeleteWorkshopModalOpen(false);
  };

  const handleResendEmailClick = async () => {
    try {
      if (!user) {
        navigate(-1);
      }

      const { firstname: userName } = user;

      setIsResendEmailLoading(true);
      await resendWorkshopBookingEmail({ id: workshopId, userName });
      toast.success(
        getTranslation(t, 'workshops.info.bookingEmailSuccess') ??
          'Successfully resent the booking email'
      );
    } catch (error) {
      toast.error(
        getTranslation(t, 'workshops.info.bookingEmailFail') ?? 'Failed to resend booking email'
      );
      console.log('Failed to resend booking email:', error);
    } finally {
      setIsResendEmailLoading(false);
    }
  };

  return (
    <>
      <NewWorkshop
        isOpen={isWorkshopModalOpen}
        isUpdated={isUpdated}
        setIsOpen={setIsWorkshopModalOpen}
        onUpdate={fetchWorkshopDetails}
      />
      {isDeleteWorkshopModelOpen && (
        <ConfirmWorkshopDeletePopup
          isLoading={isDeleteWorkshopLoading}
          isModalOpen={isDeleteWorkshopModelOpen}
          handleCloseModal={handleWorkshopModalClose}
          handleDeleteWorkshop={handleDeleteConfirmation}
        />
      )}
      <div className="flex flex-col items-start h-calc-vh overflow-y-auto">
        <div className="mb-5 w-full">
          <div className="flex items-center gap-5">
            <button onClick={handleBackClick}>
              <BackIcon className="!mr-0 mb-2" />
            </button>
            <h1 className="text-2xl font-bold text-left text-cblack-primary mb-2.5">
              {getTranslation(t, 'workshops.workshopPage.pageTitle')}
            </h1>
          </div>
          <p className="text-lg text-left text-gray-primary mb-2.5"></p>
        </div>
        <div className="w-full mt-8">
          <Button
            buttonType="primary"
            onClick={handleResendEmailClick}
            className="font-bold rounded-full py-4 px-8 text-white ">
            {getTranslation(t, 'workshops.workshopPage.resendEmailBtnText')}
            {isResendEmailLoading && (
              <span className="ml-2">
                <Spinner color="#FFFFFF" />
              </span>
            )}
          </Button>
          <div className="md:w-full w-screen overflow-x-auto pr-6 mb-6">
            <table className="table-auto w-full border-separate border-spacing-y-6">
              <thead className="font-semibold">
                <tr className="text-center">
                  <td className="text-sm">{getTranslation(t, 'workshops.tableHeader.teamName')}</td>
                  <td className="text-sm">{getTranslation(t, 'workshops.tableHeader.type')}</td>
                  <td className="text-sm">
                    {getTranslation(t, 'workshops.tableHeader.groupSize')}
                  </td>
                  <td className="text-sm">{getTranslation(t, 'workshops.tableHeader.date')}</td>
                  <td className="text-sm">{getTranslation(t, 'workshops.tableHeader.time')}</td>
                  <td className="text-sm">{getTranslation(t, 'workshops.tableHeader.language')}</td>
                  <td className="text-sm">{getTranslation(t, 'workshops.tableHeader.delete')}</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                <tr className="text-center">
                  <td
                    className="py-4 rounded-l-lg min-w-1k bg-swiss-300 text-white px-2 text-xs"
                    style={{ backgroundColor: primary, borderColor: primary }}>
                    {workshop.teamName} <br /> {workshop?.moderatorName}
                  </td>
                  <td
                    className="py-4 border-1 border-r-0 rounded-l-none min-w-1k px-2 text-xs"
                    style={{ borderColor: primary }}>
                    {getTranslation(t, `workshops.tableHeader.${workshop?.type ?? 'online'}`)}
                  </td>
                  <td
                    className="py-4 border-1 min-w-1k border-r-0 px-2 text-xs"
                    style={{ borderColor: primary }}>
                    <div className="flex items-center justify-center gap-4">
                      <button
                        className="bg-white w-6 h-6 rounded-full cursor-pointer flex items-center justify-center"
                        onClick={() => handleGroupSizeChange('decrease')}>
                        <MinusIcon color={primary} />
                      </button>
                      {workshop.groupSize}
                      <button
                        className="bg-white w-6 h-6 rounded-full cursor-pointer flex items-center justify-center"
                        onClick={() => handleGroupSizeChange('increase')}>
                        <PlusIcon2 color={primary} />
                      </button>
                    </div>
                  </td>
                  <td
                    className="py-4 border-1 border-r-0 min-w-1k px-2 text-xs"
                    style={{ borderColor: primary }}>
                    {workshop.date}
                  </td>
                  <td
                    className="py-4 border-1 border-r-0 min-w-1k px-2 text-xs"
                    style={{ borderColor: primary }}>
                    {workshop?.workshopTimeString}
                  </td>
                  <td
                    className="py-4 border-1 border-r-0 min-w-1k px-2 text-xs"
                    style={{ borderColor: primary }}>
                    {workshop.language}
                  </td>
                  <td
                    className="py-4 border-1 min-w-1k rounded-r-lg t-center px-2 text-xs"
                    style={{ borderColor: primary }}>
                    <button onClick={handleWorkshopDelete}>
                      <Trash color={primary} />
                    </button>
                  </td>
                  <td className="py-4 px-2 text-xs" style={{ borderColor: primary }}>
                    <button
                      className="flex items-center justify-center w-10 h-10 rounded-full"
                      style={{ backgroundColor: primary }}
                      onClick={handleEditWorkshopClick}>
                      <EditIcon />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="text-gray-primary">
            <div className="mb-6">
              <div className="text-cblack-primary text-lg font-bold mb-2">
                <p
                  dangerouslySetInnerHTML={{
                    __html: getTranslation(t, 'workshops.workshopPage.links.participants.title')
                  }}
                />
              </div>
            </div>
            <div className="mb-6">
              <div>
                <p
                  className="font-bold"
                  dangerouslySetInnerHTML={{
                    __html: getTranslation(
                      t,
                      'workshops.workshopPage.links.participants.workshopAccessLink.title'
                    )
                  }}
                />
                <ClipboardCopyField
                  textToCopy={
                    workshop?.type !== 'inPerson'
                      ? workshop.links?.participantLink
                      : workshop?.inPersonWorkshopLinks?.participantLink
                  }
                  isClickable={true}
                  isColored={primary}
                />
              </div>
            </div>

            <div className="mb-6">
              <p
                className="font-bold"
                dangerouslySetInnerHTML={{
                  __html: getTranslation(
                    t,
                    'workshops.workshopPage.links.moderators.workshopAccessLink.title'
                  )
                }}
              />

              <ClipboardCopyField
                textToCopy={
                  workshop?.type !== 'inPerson'
                    ? workshop.links?.moderatorLink
                    : workshop?.inPersonWorkshopLinks?.moderatorLink
                }
                isClickable={true}
                isColored={primary}
              />
            </div>
          </div>
        </div>
      </div>

      {loading && <FullScreenLoader />}
    </>
  );
};

export default WorkshopInfo;
