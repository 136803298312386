import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { RxCross2 } from 'react-icons/rx';
import { AdminContext } from '../../contexts/admin.context';

export default () => {
  const { themeConfig: { primary } = {} } = useContext(AdminContext);

  const navigate = useNavigate();
  return (
    <button
      onClick={() => navigate(-1)}
      className={`w-10 h-10 rounded-full flex justify-center items-center mt-8 ml-3`}
      style={{ backgroundColor: primary }}>
      <RxCross2 className={'text-white'} />
    </button>
  );
};
