import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { getProjects } from '../service';
import { AdminContext } from '../contexts/admin.context';

import Button from '../components/common/Button';
import Loader from '../components/common/Loader';
import { ClassTable } from '../components';
import ProjectCard from '../components/project/ProjectCard';
import { trackEvent } from '../utils';
import { InfoCircleIcon } from '../components/icons';
import { hexToRgba } from '../utils/colors';
import { formatDate } from '../utils/date';
import { getTranslation } from '../utils/translation';
import ClipboardCopyField from '../components/common/ClipboardCopyField';

const ManageExistingProject = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [classes, setClasses] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const {
    themeId,
    teamType,
    setContentPopupInfo,
    user,
    portalConfig: { classServices } = {},
    themeConfig: { secondaryVariant, intensitySecondary }
  } = useContext(AdminContext);

  const basePath = `/${themeId}/${teamType}`;

  const buildTableHeaders = () => {
    const headers = [];

    // Fixed headers
    headers.push(<ClassTableHeader t={t} setContentPopupInfo={setContentPopupInfo} id="name" />);
    headers.push(<ClassTableHeader t={t} setContentPopupInfo={setContentPopupInfo} id="created" />);
    headers.push(
      <ClassTableHeader t={t} setContentPopupInfo={setContentPopupInfo} id="projectType" />
    );

    // Master admin features
    Object.keys(classServices)?.forEach((key) => {
      if (key === 'transferStudent') return; // Displayed outside table
      const { enabled } = classServices[key];

      if (enabled) {
        headers.push(<ClassTableHeader t={t} setContentPopupInfo={setContentPopupInfo} id={key} />);
      }
    });

    return headers;
  };

  const buildTaleRows = () => {
    const rows = [];

    classes.forEach((classItem) => {
      const row = [];

      row.push(<p className="text-base">{classItem?.specs?.description}</p>);
      row.push(<p className="text-base">{formatDate(classItem.created)}</p>);

      // Project Type
      row.push(
        <ProjectCard
          project={classItem}
          serviceId={'projectType'}
          classes={classes}
          setClasses={setClasses}
        />
      );

      Object.keys(classServices)?.forEach((key) => {
        if (key === 'transferStudent') return; // Displayed outside table
        const { enabled } = classServices[key];
        if (enabled)
          row.push(
            <ProjectCard
              project={classItem}
              serviceId={key}
              classes={classes}
              setClasses={setClasses}
            />
          );
      });

      rows.push(row);
    });

    return rows;
  };

  const fetchClasses = async () => {
    try {
      setLoading(true);
      const response = await getProjects();
      if (response?.status === 200) {
        setClasses(response.data);
        setProgress(100);
        return;
      }
      toast.error(t('errors.failed-load-courses'));
    } catch (error) {
      setProgress(100);
      console.error('ERROR', error);
    }
  };

  // Get classes
  useEffect(() => {
    fetchClasses();
  }, []);

  useEffect(() => {
    trackEvent('page_view', {
      organization: themeId === 'ch' ? 'SSK' : 'WSF'
    });
  }, []);

  return (
    <>
      <div className="flex flex-col items-start">
        <div className="mb-5 w-full">
          <h1
            className="text-2xl font-bold text-left text-cblack-primary mb-2.5"
            dangerouslySetInnerHTML={{ __html: getTranslation(t, 'manageProjects.header') }}
          />
        </div>
        {isLoading ? (
          <Loader progress={progress} setLoading={setLoading} setProgress={setProgress} />
        ) : (
          <>
            <div
              className="overflow-x-auto w-full lg:pr-36 mini-scrollbar thick horizontal p-4 pl-0"
              style={{ transform: 'rotateX(180deg)' }}>
              <div style={{ transform: 'rotateX(180deg)' }}>
                <ClassTable
                  headers={buildTableHeaders()}
                  rows={buildTaleRows()}
                  headerBgDark={hexToRgba(secondaryVariant, '100%')}
                  headerBg={hexToRgba(secondaryVariant, intensitySecondary)}
                  pagination={5}
                />
              </div>
            </div>

            <div className="mt-4">
              <Button buttonType={'primary'} onClick={() => navigate(basePath + '/create-project')}>
                {getTranslation(t, 'manageProjects.createNewButton')}
              </Button>
            </div>

            {classServices?.transferStudent?.enabled && (
              <div className="mt-4">
                <div
                  className="my-1 text-lg"
                  dangerouslySetInnerHTML={{
                    __html: getTranslation(t, 'manageProjects.transferStudent')
                  }}
                />

                <ClipboardCopyField
                  textToCopy={`${window.location.origin}${basePath}/transfer-student/${user?.accounts?.[0]?.id}`}
                  isClickable={true}
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

const ClassTableHeader = ({ t, setContentPopupInfo, id }) => {
  const headerText = {
    tableHeading: getTranslation(t, `class.table.${id}.tableHeading`),
    heading: getTranslation(t, `class.table.${id}.popupHeading`),
    content: getTranslation(t, `class.table.${id}.popupContent`)
  };

  if (headerText?.heading?.includes(`class.table.${id}.popupHeading`)) headerText.heading = null;
  if (headerText?.content?.includes(`class.table.${id}.popupContent`)) headerText.content = null;

  const showInfo = () => {
    setContentPopupInfo({
      isVisible: true,
      content: (
        <div className="w-full text-left p-6">
          <h1 className="text-xl font-semibold">{headerText?.heading}</h1>
          <p>{headerText?.content}</p>
        </div>
      )
    });
  };

  return (
    <div className="flex gap-4 items-center font-semibold text-black">
      <span
        className="text-left"
        dangerouslySetInnerHTML={{ __html: headerText?.tableHeading ?? id }}
      />
      {headerText?.heading && headerText?.content && (
        <button onClick={showInfo}>
          <InfoCircleIcon />
        </button>
      )}
    </div>
  );
};

export default ManageExistingProject;
