import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { PDFDocument } from 'pdf-lib';
import axios from 'axios';

export const downloadFile = (data, fileName) => {
  try {
    const href = URL.createObjectURL(data);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  } catch (error) {
    // Normally download with URL
    const link = document.createElement('a');
    link.href = data;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
  }
};

export const downloadPDFWithURL = async (url, fileName) => {
  try {
    const parsedURL = JSON.parse(url) || url;
    const response = await fetch(parsedURL);
    const content = await response.blob();
    downloadFile(content, fileName);
  } catch (error) {
    try {
      const response = await fetch(url);
      const content = await response.blob();
      downloadFile(content, fileName);
    } catch (error) {
      console.error(error);
    }
    console.error(error);
  }
};

const fetchFileContent = async (url, updateDownloadPercentage) => {
  const response = await axios({
    url,
    method: 'GET',
    responseType: 'blob',
    onDownloadProgress: (progressEvent) => {
      let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      updateDownloadPercentage(percentCompleted);
    }
  });

  return response.data;
};

export const downloadPDFsFromURLAsZip = async (
  zipURL,
  fileName = 'archive',
  updateDownloadPercentage
) => {
  const zipBlob = await fetchFileContent(zipURL, updateDownloadPercentage);
  saveAs(zipBlob, `${fileName}.zip`);
};

export const combinePDFsFromURLs = async (zipURL, fileName = 'files', updateDownloadPercentage) => {
  const zipBlob = await fetchFileContent(zipURL, updateDownloadPercentage);
  const zipFile = await JSZip.loadAsync(zipBlob);
  const fileNames = Object.keys(zipFile.files);

  // Load each PDF from the zip
  const pdfDocs = await Promise.all(
    fileNames
      .filter((fileName) => fileName.endsWith('.pdf'))
      .map(async (fileName) => {
        const file = await zipFile.file(fileName).async('arrayBuffer');
        return await PDFDocument.load(file);
      })
  );

  const mergedPdfDoc = await PDFDocument.create();

  // Merge the PDFs
  for (let i = 0; i < pdfDocs.length; i++) {
    const pdfDoc = pdfDocs[i];
    const copiedPages = await mergedPdfDoc.copyPages(pdfDoc, pdfDoc.getPageIndices());
    for (let j = 0; j < copiedPages.length; j++) {
      const copiedPage = copiedPages[j];
      mergedPdfDoc.addPage(copiedPage);
    }
  }

  // Save and download the merged PDF
  const mergedPdfBytes = await mergedPdfDoc.save();
  const mergedBlob = new Blob([mergedPdfBytes], { type: 'application/pdf' });

  saveAs(mergedBlob, `${fileName}.pdf`);
};
