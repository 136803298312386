import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import MenuItem from './MenuItem';
import { DownArrowIcon } from '../icons';
import { getTranslation } from '../../utils/translation';

const MenuItemWithDropDown = ({
  isSelected,
  sidebarColor,
  basePath,
  dropDownItems = [],
  children
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [areNestedItemsVisible, setAreNestedItemsVisible] = useState(false);

  const handleMenuItemClick = () => {
    setAreNestedItemsVisible(!areNestedItemsVisible);
  };

  return (
    <>
      <MenuItem isSelected={isSelected} sidebarColor={sidebarColor} onClick={handleMenuItemClick}>
        <span className="block w-5">
          <DownArrowIcon isSelected={isSelected} />
        </span>
        {children}
      </MenuItem>

      {areNestedItemsVisible && (
        <ul className="rounded ease-in-out transform duration-300 !list-none">
          {dropDownItems.map(({ id, isSelected: isItemSelected }) => (
            <li
              key={id}
              onClick={() => navigate(`${basePath}/${id}`)}
              className={clsx(
                'text-lg py-2 px-3 cursor-pointer text-gray-900 rounded-md ml-14 ',
                'sidebar-subdropdown',
                {
                  'sidebar-subdropdown-selected': isItemSelected
                }
              )}
              style={{ border: isItemSelected ? '1px solid #515151' : '1px solid transparent' }}>
              <span className="selection-circle" />
              {getTranslation(t, `sideBar.${id}`)}
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default MenuItemWithDropDown;
