import React, { useState, useEffect } from 'react';
import Carousel from 'nuka-carousel';
import { useTranslation } from 'react-i18next';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';

import SlideIcons from './SlideIcons';
import SlideImage from './SlideImage';
import SlideIFrame from './SlideIFrame';
import { getTranslation } from '../../../utils/translation';

export default function Slider({ themeId, tRegion, updateTitle = () => {}, setImagePopup }) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [parsedSlides, setParsedSlides] = useState([]);

  const handleSlideMovement = (inc) => {
    updateTitle(inc);
  };

  useEffect(() => {
    try {
      const slides = getTranslation(t, 'landing.slider.data', false, 0, 0, true);

      setParsedSlides(
        slides.map((slide) => {
          return {
            type: slide?.type?.[tRegion]
              ? slide?.type[tRegion]
              : slide?.type?.[themeId]
              ? slide?.type[themeId]
              : slide?.type.default,
            data: slide?.data,
            content: !slide?.content
              ? undefined
              : slide?.content?.[tRegion]
              ? slide?.content[tRegion]
              : slide?.content?.[themeId]
              ? slide?.content[themeId]
              : slide?.content.default
          };
        })
      );
    } catch (error) {
      console.log('error parsing slides', error);
    }
  }, [t]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Adjust the delay time as needed
  }, []);

  return (
    <section id="quels-slider" className="bg-swiss-100 relative py-16 md:py-6 xl:py-16">
      {isLoading ? (
        <></>
      ) : (
        <Carousel
          showArrows
          showStatus={false}
          adaptiveHeight={true}
          emulateTouch
          infiniteLoop
          afterSlide={(i) => {
            handleSlideMovement(i);
          }}
          preventMovementUntilSwipeScrollTolerance
          wrapAround={false}
          renderCenterLeftControls={({ previousSlide, slideCount, currentSlide }) => {
            if (currentSlide === 0) {
              return <></>;
            }
            return (
              <div
                onClick={() => {
                  previousSlide();
                }}>
                <button className="text-red absolute text-2xl lg:left-5 md:left-0 sm:left-10 left-[0px] md:top-2/4 top-[-100px] z-50 rounded-full p-3 w-12 h-12 md:w-16 md:h-16 flex items-center justify-center md:p-5 bg-swiss-150 text-white">
                  <AiOutlineArrowLeft color="" className="w-6 sm:w-auto" size={26} />
                </button>
              </div>
            );
          }}
          renderCenterRightControls={({ nextSlide, slideCount, currentSlide }) => {
            if (currentSlide === slideCount - 1) {
              return <></>;
            }
            return (
              <div
                onClick={() => {
                  nextSlide();
                }}>
                <button className="absolute text-2xl lg:right-5 sm:right-10   md:right-0 right-[0px] md:top-2/4 top-[-100px] rounded-full w-12 h-12 md:w-16 md:h-16 flex items-center justify-center p-3 md:p-5 bg-swiss-150 text-white">
                  <AiOutlineArrowRight size={26} />
                </button>
              </div>
            );
          }}>
          {parsedSlides.map((slide, i) => {
            switch (slide.type) {
              case 'html':
                return (
                  <div
                    key={i}
                    className="container mx-auto justify-center"
                    dangerouslySetInnerHTML={{ __html: slide?.content }}
                  />
                );
              case 'img':
                return <SlideImage key={i} setImagePopup={setImagePopup} data={slide.data} />;
              case 'iframe':
                return <SlideIFrame key={i} data={slide.data} />;
              default:
                return <SlideIcons key={i} />;
            }
          })}
        </Carousel>
      )}
    </section>
  );
}
