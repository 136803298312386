import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { WorkshopContext } from '../contexts/workshop.context';

import { ModalCloseIcon, OnlineWorkshopIcon, InPersonWorkshopIcon } from './icons';
import { getTranslation } from '../utils/translation';

const SelectWorkshopType = ({ closeModal, primary, secondary, buttonRadius, themeId }) => {
  const { t } = useTranslation();

  const { setActiveStep, setWorkshopData } = useContext(WorkshopContext);

  function openModalCreateTeam(selectedType) {
    setWorkshopData((currValue) => ({
      ...currValue,
      type: selectedType
    }));
    setActiveStep('step2');
  }

  return (
    <>
      <div className="modal-wrapper workshopGroups-modal md:px-9 md:pt-5  md:pb-8 p-3">
        <div className="modal-close top-0 sm:left-[auto] left-6 " onClick={closeModal}>
          <button className="bg-gray-100 p-3 rounded-full self-start absolute left-6">
            <ModalCloseIcon color={primary} />
          </button>
        </div>
        <div className="modal-inner-wrapper">
          <div className="wg-modal-header">
            <div className="text-center">
              <h2 className="text-2xl font-bold mb-2.5 sm:mt-0 mt-[70px]">
                {getTranslation(t, 'workshops.newWorkshop.selectType.title')}
              </h2>
            </div>
          </div>
          <div className="flex justify-center items-center gap-4 my-10">
            {/* {themeId === 'ch' && (
              <button
                className={`px-6 py-2 bg-gray-100 ${buttonRadius} flex items-center gap-2 font-semibold`}
                onClick={() => openModalCreateTeam('online')}>
                <OnlineWorkshopIcon color={primary} />{' '}
                {getTranslation(t, 'workshops.newWorkshop.selectType.online')}
              </button>
            )} */}
            <button
              className={`px-6 py-2 bg-gray-100 flex items-center gap-2 font-semibold`}
              style={{ borderRadius: buttonRadius }}
              onClick={() => openModalCreateTeam('inPerson')}>
              <InPersonWorkshopIcon color={primary} />{' '}
              {getTranslation(t, 'workshops.newWorkshop.selectType.inPerson')}
            </button>
          </div>
        </div>
      </div>

      <div className="flex gap-5 justify-center mb-[20px]">
        <h1
          className="font-semibold text-sm p-1 text-white rounded-md"
          style={{ backgroundColor: secondary }}>
          {getTranslation(t, 'workshops.newWorkshop.steps.1')}
        </h1>
        <h1 className="font-semibold text-sm p-1 text-swiss-bw-300">
          {getTranslation(t, 'workshops.newWorkshop.steps.2')}
        </h1>
        <h1 className="font-semibold text-sm p-1 text-swiss-bw-300">
          {getTranslation(t, 'workshops.newWorkshop.steps.3')}
        </h1>
        <h1 className="font-semibold text-sm p-1 text-swiss-bw-300">
          {getTranslation(t, 'workshops.newWorkshop.steps.4')}
        </h1>
      </div>
    </>
  );
};

export default SelectWorkshopType;
