import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { generateResultPDF, getTANInfo } from '../service';
import { downloadFile } from '../utils/download';

import Button from './common/Button';
import Spinner from './common/Spinner';
import { PDF_TEMPLATE_NAMES } from '../data/default-values';
import { AdminContext } from '../contexts/admin.context';

const CharInput = ({ field, form, inputRef, pasteValue, focusNext, ...props }) => {
  const handleInputChange = (e) => {
    const { value } = e.target;
    form.setFieldValue(field.name, value);
    if (value) {
      focusNext();
    }
  };
  const { themeId } = useContext(AdminContext);
  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text');
    pasteValue(pastedData, form);
  };

  useEffect(() => {
    if (props.autoFocus) {
      inputRef.current.focus();
    }
  }, [props.autoFocus, inputRef]);

  const inputClasses = `${
    themeId === 'ch' ? 'border border-swiss-300' : 'border'
  } w-20 h-24 rounded text-5xl text-center leading-none text-gray-primary mr-2`;

  return (
    <input
      className={inputClasses}
      {...field}
      {...props}
      ref={inputRef}
      maxLength="1"
      onChange={handleInputChange}
      onPaste={handlePaste}
    />
  );
};

const AccessCodeForm = ({ projectId, accessCodeTpl }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const inputRefs = Array.from({ length: 6 }, () => useRef(null));
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const { themeId, teamType, user } = useContext(AdminContext);

  const buttonText =
    teamType == 'team'
      ? t('ch.team.manageProject.list.student-converter.action-button-text')
      : t('manage-project.list.student-converter.action-button-text');

  const focusInput = (index) => {
    if (index < inputRefs.length) {
      inputRefs[index].current.focus();
    }
  };

  const pasteValue = (value, form) => {
    const newValue = value.slice(0, 6).split('');
    newValue.forEach((char, index) => {
      if (index < inputRefs.length) {
        form.setFieldValue(`char${index + 1}`, char);
        focusInput(index + 1);
      }
    });
  };

  const handleSubmit = async (formValues, { resetForm }) => {
    const { char1, char2, char3, char4, char5, char6 } = formValues;

    setIsLoading(true);
    const accessCode = char1 + char2 + char3 + char4 + char5 + char6;

    const userAccountId = user?.accounts?.[0]?.id;

    try {
      const response = await getTANInfo(projectId, accessCode);

      if (response.status === 200) {
        const { data: tanInfo } = response;

        if (tanInfo.active) {
          throw new Error('Not applicable');
        }

        try {
          const id = `${accessCode}-${userAccountId}-${projectId}`;

          const response = await generateResultPDF({
            id,
            tpl: accessCodeTpl,
            withStatusCodes: true
          });
          if (response.status === 200) {
            if (response.data.message) {
              toast.error(t('errors.failed-download'));
              return;
            }
            downloadFile(response.data, 'Result.pdf');
            // clear form values
            resetForm();
            return;
          }
          toast.error(t('errors.failed-download'));
        } catch (e) {
          toast.error(t('errors.failed-download'));
          console.error('Failed to download result:', e);
        }
      }
    } catch (e) {
      toast.error(t('errors.not-applicable'));
      console.error('Failed to download result:', e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Formik
      initialValues={{
        char1: '',
        char2: '',
        char3: '',
        char4: '',
        char5: '',
        char6: ''
      }}
      validationSchema={Yup.object({
        char1: Yup.string().required(),
        char2: Yup.string().required(),
        char3: Yup.string().required(),
        char4: Yup.string().required(),
        char5: Yup.string().required(),
        char6: Yup.string().required()
      })}
      onSubmit={handleSubmit}>
      {({ setFieldValue }) => (
        <Form className="mt-4" id="access-code-form" data-theme={themeId}>
          {Array.from({ length: 6 }, (_, index) => (
            <Field
              key={index}
              name={`char${index + 1}`}
              type="text"
              inputRef={inputRefs[index]}
              focusNext={() => focusInput(index + 1)}
              pasteValue={(value) => pasteValue(value, { setFieldValue })}
              component={CharInput}
            />
          ))}
          <div className="mt-3">
            <Button type="submit" buttonType={'primary'}>
              {isLoading ? <Spinner color="#FFF" /> : buttonText}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AccessCodeForm;
