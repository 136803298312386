import clsx from 'clsx';
import React from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import Button from '../common/Button';
import Spinner from '../common/Spinner';
import { getTranslation } from '../../utils/translation';

const ConfirmWorkshopDeletePopup = ({
  isModalOpen = false,
  isLoading = false,
  handleDeleteWorkshop,
  handleCloseModal,
  wrapperClass = '',
  primary
}) => {
  const { t } = useTranslation();

  const customStyles2 = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: '0',
      width: '100%',
      maxWidth: '1080px',
      maxHeight: '614px',
      transform: 'translate(-50%, -50%)',
      borderRadius: '10px',
      border: '0px !important',
      boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
    }
  };

  return (
    <Modal
      isOpen={isModalOpen}
      style={customStyles2}
      onRequestClose={handleCloseModal}
      ariaHideApp={false}
      contentLabel="Example Modal">
      <div className={`${wrapperClass} px-[60px] pb-[69px] pt-5`}>
        <button
          className="flex items-center justify-center bg-gray-100 p-3 rounded-full self-start mb-7 w-10 h-10 ring-1"
          style={{ backgroundColor: primary }}
          onClick={() => {
            handleCloseModal();
          }}>
          <svg
            className="w-5 h-5"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.17969 20.2447L20.2464 1.17806M20.2464 20.2447L1.17969 1.17806"
              stroke="#fff"
              strokeWidth="3"></path>
          </svg>
        </button>
        <h1 className="text-2xl font-bold pb-[30px] flex items-center">
          {getTranslation(t, 'workshops.popups.delete.title')}
        </h1>
        <p
          className="mb-[30px]"
          dangerouslySetInnerHTML={{
            __html: getTranslation(t, 'workshops.popups.delete.subtitle')
          }}
        />
        <div className="flex gap-6 text-swiss-bw-200">
          <Button
            buttonType="primary"
            onClick={() => handleCloseModal()}
            className="text-white border-2 font-bold rounded-full py-3 px-8">
            {getTranslation(t, 'workshops.popups.delete.cancelBtn')}
          </Button>
          <Button
            buttonType="primary"
            onClick={() => !isLoading && handleDeleteWorkshop()}
            className={clsx('font-bold rounded-full py-3 px-8 text-white', {
              'cursor-not-allowed': isLoading
            })}>
            {getTranslation(t, 'workshops.popups.delete.confirmBtn')}
            {isLoading && (
              <span className="ml-2">
                <Spinner color="#FFFFFF" />
              </span>
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmWorkshopDeletePopup;
