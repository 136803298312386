import React, { useContext, useState } from 'react';
import { AdminContext } from '../../contexts/admin.context';
import { XIcon } from '../icons';
import Button from './Button';

export default function ConfirmationPopup({
  heading,
  description,
  icon = null,
  action = {
    head: '',
    text: '',
    type: 'danger',
    onClick: null,
    icon: null
  },
  textBox = {
    show: false,
    text: ''
  },
  actionList = null,
  actionListClick = null,
  footerNotes,
  onHide
}) {
  const { themeConfig: { primary } = {} } = useContext(AdminContext);
  const [textareaContent, setTextareaContent] = useState('');

  // Update the textarea onChange handler to set the content state
  const handleTextareaChange = (e) => {
    setTextareaContent(e.target.value);
  };

  const Icon = icon;

  return (
    <div className="z-50 backdrop-blur-sm fixed top-0 left-0 w-full h-screen flex items-center justify-center">
      <div className="p-4 bg-white w-full h-auto lg:w-10k flex flex-col items-center rounded shadow-lg">
        <button
          className="p-4 rounded-full text-white self-start shadow-lg"
          style={{ backgroundColor: primary }}
          onClick={onHide}>
          <XIcon className="w-3 h-3" color="white" />
        </button>
        {icon && (
          <div className="self-center my-16">
            <Icon />
          </div>
        )}
        {action.head !== '' && <h4 className="font-bold">{action.head}</h4>}
        <h2 className="heading">{heading}</h2>
        <p className="description text-sm">{description}</p>
        {textBox.show && (
          <div className="mt-8 flex flex-col">
            <h4 className="font-semibold">{textBox.text}</h4>
            <textarea
              value={textareaContent}
              onChange={handleTextareaChange}
              rows="4"
              className="block p-2.5 w-full max-w-md text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"></textarea>
          </div>
        )}
        {actionList && (
          <div className="mt-4 flex flex-col items-center">
            {actionList.map((actionItem) => (
              <button
                key={actionItem._id}
                type="button"
                onClick={() => actionListClick(actionItem._id)}
                className="flex justify-between my-2 px-4 py-3 rounded-md bg-cwhite-primary text-xl text-cblack-primary w-64 hover:bg-cwhite-400 text-left">
                {actionItem.name}
              </button>
            ))}
          </div>
        )}
        {action.text && (
          <div className="mt-16">
            <Button
              buttonType="primary"
              onClick={() => (textBox.show ? action.onClick(textareaContent) : action.onClick())}>
              {action.text}
            </Button>
          </div>
        )}
        {footerNotes && <p className="text-sm mt-4 text-gray-400 max-w-md">{footerNotes}</p>}
      </div>
    </div>
  );
}
