import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import { AdminContext } from '../contexts/admin.context';

import OverlapHeading from '../components/OverlapHeading';
import { GenericSignupForm } from '../components/forms';
import { signupSchema } from '../schema/signup.schema';
import {
  checkRegisteredTeacher,
  registerTeacher,
  registerTeacherEmailForSSKAdmin,
  getSchools,
  storeStats
} from '../service';

import SignUpPopup from '../components/SignupPopup';
import { getSortedList, trackEvent } from '../utils';
import { hexToRgba } from '../utils/colors';
import { getTranslation } from '../utils/translation';

const initialValues = {
  type: '',
  name: '',
  address: '',
  city: '',
  academy: '',
  postalCode: '',
  firstName: '',
  lastName: '',
  schoolOrganization: '',
  schoolOrganizationOther: '',
  jsonFunction: '',
  email: '',
  phone: '',
  canton: '',
  language: '',
  isTeacher: false,
  wantAccess: false
};

const Signup = ({ isHeading = '', from = 'iframe', submitButtonType = 'primary' }) => {
  const { t, i18n } = useTranslation();
  const [searchParam] = useSearchParams();

  const {
    themeId,
    teamType,
    themeConfig: { secondaryVariant, intensitySecondary } = {},
    portalConfig: {
      regions,
      establishments,
      academies,
      hasRegistrationFormIframe,
      registrationForm,
      languages
    } = {}
  } = useContext(AdminContext);

  const [schools, setSchools] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [isStatus, setIsStatus] = useState('');
  const [languageOptions, setLanguageOptions] = useState([]);

  const currentLang = searchParam.get('lang');

  useEffect(() => {
    trackEvent('page_view', {
      organization: 'SSK'
    });

    if (currentLang) {
      const selectedLang = languageOptions.filter(
        (item) => item.value.toLowerCase() === currentLang.toLowerCase()
      );
      if (selectedLang.length < 1) return;

      i18n.changeLanguage(currentLang);
      try {
        localStorage.setItem('lang', currentLang);
      } catch (error) {
        console.log(error);
      }

      if (selectedLang.length > 0) formik.setFieldValue('language', selectedLang[0]?.value);
    } else formik.setFieldValue('language', i18n.language);
  }, [currentLang, languageOptions, i18n.language]);

  useEffect(() => {
    if (languages) {
      const langOptions = languages.map((item) => {
        return { label: item?.toUpperCase(), value: item };
      });
      setLanguageOptions(langOptions);
    }
  }, [languages]);

  const checkIfAllRequiredFieldsAreDone = (data) => {
    let copyRegistrationForm = _.cloneDeep([...registrationForm]);
    const jsonFunctionIndex = copyRegistrationForm.findIndex((item) => item.id === 'functionTask');
    copyRegistrationForm[jsonFunctionIndex].id = 'jsonFunction';

    const requiredFields = copyRegistrationForm?.filter((item) => item.required);
    const requiredFieldsValues = requiredFields.map((item) => {
      const entryDone = data[item.id];
      if (!entryDone) {
        formik.errors[item?.id] = item.id;
        formik.touched[item?.id] = true;
        return '';
      } else {
        formik.errors[item?.id] = '';
        formik.touched[item?.id] = false;
        return entryDone;
      }
    });

    return requiredFieldsValues.includes('');
  };

  const formSubmitHandler = async (data) => {
    const parsedData = { ...data };

    if (checkIfAllRequiredFieldsAreDone(parsedData)) return;

    if (!parsedData.schoolOrganization) parsedData.schoolOrganization = { _id: 'other' };

    let body = {
      account: {
        companyName: parsedData.name
          ? parsedData?.name
          : parsedData.schoolOrganization?._id !== 'other'
          ? parsedData.schoolOrganization?.name || parsedData?.schoolOrganization
          : parsedData?.schoolOrganizationOther
          ? parsedData?.schoolOrganizationOther
          : 'Unnamed Organization',
        phone: parsedData.phone,
        specs: {
          currency: 'EUR',
          schoolOrganization:
            parsedData.schoolOrganization?.label ||
            parsedData?.schoolOrganization?.name ||
            parsedData?.schoolOrganization,
          establishment: parsedData.type,
          academy: parsedData.academy
        },
        addresses: [
          {
            city: parsedData.city ? parsedData.city : 'any',
            countryId: themeId?.toUpperCase(),
            isBilling: true,
            postcode: parsedData.postalCode ? parsedData.postalCode : '12345',
            street: parsedData.canton
              ? parsedData.canton.label
                ? parsedData.canton.label
                : parsedData.canton
              : regions[0]?._id
          }
        ]
      },
      user: {
        firstname: parsedData.firstName,
        lastname: parsedData.lastName,
        id: parsedData.email,
        languageId: parsedData?.language?.toLowerCase(),
        roles: [],
        salutation: '-',
        specs: {
          jsonFunction: data.jsonFunction,
          role: parsedData.role,
          isTeacher: parsedData.isTeacher,
          missionFutureAccess: parsedData.wantAccess
        }
      }
    };

    try {
      const { data: accountExists } = await checkRegisteredTeacher(
        {
          id: data.email
        },
        themeId,
        teamType
      );

      if (accountExists) {
        throw new Error('Email already exists');
      }

      await registerTeacher(body, themeId, teamType);

      if (
        process.env.NODE_ENV !== 'development' &&
        themeId === 'ch' &&
        process.env.REACT_APP_TEST_CASE !== 'true'
      ) {
        try {
          const submitData = {
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            school:
              data.schoolOrganization?.label ||
              data?.schoolOrganization?.name ||
              data?.schoolOrganization,
            functions: data.jsonFunction,
            phone: data.phone,
            canton: regions.find((region) => region._id === data.canton)?.[`name_${i18n.language}`],
            language: data.language
          };

          await registerTeacherEmailForSSKAdmin({
            ...submitData
          });
        } catch (error) {
          console.error('Error sending email to SSK Admin', error);
        }
      }

      formik.resetForm();
      setIsStatus('success');
      setShowPopup(true);

      storeStats({
        application: 'teacher_registration',
        event: 'form_submission',
        pageURL: window?.location?.pathname,
        pageTitle: document.title,
        orgPrefix: themeId?.toLowerCase(),
        teamType: teamType?.toLowerCase()
      });
    } catch (e) {
      console.log('Signup error', e);
      setIsStatus('failure');
      setShowPopup(true);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: signupSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: formSubmitHandler
  });

  useEffect(() => {
    getSchoolsData();
  }, []);

  const getSchoolsData = async () => {
    try {
      const { data } = await getSchools();
      setSchools([
        {
          _id: 'other',
          name: getTranslation(t, 'signup.placeholder.schoolOrganizationOther')
        },
        ...data
      ]);
    } catch (e) {
      console.error('ERROR', e);
    }
  };

  // Check if iFrame is allowed
  if (from === 'iframe' && !hasRegistrationFormIframe) return null;

  return (
    <div
      className="signup md:pt-0 pt-14"
      style={{ background: from === 'iframe' && hexToRgba(secondaryVariant, intensitySecondary) }}>
      <OverlapHeading
        backgroundHeading={isHeading}
        foreGroundHeading={getTranslation(t, `signup.${from}.subtitle`)}
        foreGroundHeadingColor={'text-cblack-secondary'}
        foregroundHeadingClassName={
          'sm:text-4xl text-2xl font-extrabold mb-5 text-center text-cblack-secondary'
        }
        backgroundHeadingClassName={
          'lg:!text-[200px] lg:leading-[156px] !opacity-60 bg !text-swiss-400'
        }
        isBackgroundHeadingUpperCase={false}
        foreheadHeightCenter={isHeading ? true : false}
        showBackgroundHeadingonMobile={true}
        center={true}
        hasTopMargin={false}
      />
      <p
        className="mx-auto w-full text-base md:text-lg text-cblack-secondary font-normal text-center py-10"
        dangerouslySetInnerHTML={{ __html: getTranslation(t, `signup.${from}.description`) }}
      />

      <GenericSignupForm
        teamType={teamType}
        from={from}
        formik={formik}
        regions={getSortedList(regions, i18n.language)}
        establishments={getSortedList(establishments, i18n.language)}
        academies={getSortedList(academies, i18n.language)}
        schools={schools}
        languageOption={languageOptions}
        registrationForm={registrationForm}
        submitButtonType={submitButtonType}
      />

      <SignUpPopup showPopup={showPopup} setShowPopup={setShowPopup} isStatus={isStatus} />
    </div>
  );
};

export default Signup;
