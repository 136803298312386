import React from 'react';

import { TimeSlotButton } from './timeSlotButton';
import { getTranslation } from '../../utils/translation';

export const TimeSlotList = ({
  timeSlots,
  nextPrev,
  selectTimeSlot,
  timeZone,
  onChangeSlots,
  translationFn,
  primary,
  secondary
}) => (
  <div className="timeslot-list-block text-lg pb-1">
    <div className="timeslot-list-header flex items-center  justify-between mb-[12px]">
      <div className="w-[40%] flex gap-2 font-semibold text-sm">
        {getTranslation(translationFn, 'workshops.newWorkshop.timeZoneLabel')}{' '}
        <span>{timeZone}</span>
      </div>
      <div>
        <button
          className="bg-gray-100 rounded-full px-4 py-1 text-sm font-semibold cursor-pointer hover:shadow-md active:shadow"
          style={{ color: primary }}
          onClick={() => {
            onChangeSlots(nextPrev ? 'next' : 'back');
          }}>
          {nextPrev ? (
            <>
              {getTranslation(
                translationFn,
                'workshops.newWorkshop.selectDateAndTime.moreSlotsLabel'
              )}
              <span>{' > '}</span>
            </>
          ) : (
            <>
              <span>{' < '}</span>
              {getTranslation(
                translationFn,
                'workshops.newWorkshop.selectDateAndTime.moreSlotsLabel'
              )}
            </>
          )}
        </button>
      </div>
    </div>
    <div className="timeslot-list inline-block gap-4 text-base">
      {timeSlots.map((timeSlot, index) => {
        const display = (index > 23 ? !nextPrev : nextPrev) ? '' : 'none';
        return (
          <TimeSlotButton
            key={index}
            timeSlot={timeSlot}
            index={index}
            selectTimeSlot={selectTimeSlot}
            display={display}
            translationFn={translationFn}
            primary={primary}
            secondary={secondary}
          />
        );
      })}
    </div>
  </div>
);
