import { useNavigate, useLocation } from 'react-router-dom';

/**
 * Provides utility functions for route manipulation.
 */
function useRouteUtils() {
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * Removes the specified query parameter from the URL.
   *
   * @param {string} paramName - The name of the query parameter to remove.
   * @param {boolean} shouldReplace - If true, replaces the current entry in the history stack.
   */
  function removeQueryParam(paramName, shouldReplace = false) {
    const currentQuery = new URLSearchParams(location.search);
    currentQuery.delete(paramName);

    const updatedSearch = currentQuery.toString();

    if (shouldReplace) {
      navigate(location.pathname + '?' + updatedSearch, { replace: true });
    } else {
      navigate(location.pathname + '?' + updatedSearch);
    }
  }

  return { removeQueryParam };
}

export default useRouteUtils;
