import React, { useContext } from 'react';
import clsx from 'clsx';
import { PlusIcon, MinusIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { AdminContext } from '../../contexts/admin.context';
import { getTranslation } from '../../utils/translation';

const NumberInputButton = ({ onClick, children }) => {
  const { themeConfig: { primary } = {} } = useContext(AdminContext);

  return (
    <button
      onClick={onClick}
      type="button"
      className="block disabled:opacity-60 py-3 rounded font-bold text-md border plus-button text-white px-4"
      style={{ backgroundColor: primary }}>
      {children}
    </button>
  );
};

const NumberInput = ({
  name,
  label,
  subtext,
  error,
  formik,
  isDark = false,
  className,
  ...props
}) => {
  const onChange = formik?.handleChange;
  const fieldValue = formik?.values[name];
  const fieldError = formik?.errors[name];
  const { t } = useTranslation();

  const handleIncrement = () => {
    let updatedValue = 0;
    const parsedFieldValue = fieldValue === undefined ? 0 : parseInt(fieldValue);
    updatedValue = parsedFieldValue + 1;
    formik.setFieldValue(name, updatedValue);
  };

  const handleDecrement = () => {
    let updatedValue = 0;
    const parsedFieldValue = fieldValue === undefined ? 0 : parseInt(fieldValue);
    updatedValue = parsedFieldValue - 1;
    if (updatedValue <= 0) updatedValue = 1;
    formik.setFieldValue(name, updatedValue);
  };

  return (
    <div className="mb-8">
      {label && (
        <label className="block mb-1 text-base font-bold text-cblack-primary">{label}</label>
      )}
      {subtext && <p className="text-sm text-gray-primary mb-2 leading-5">{subtext}</p>}
      <div className="flex flex-items gap-1">
        <input
          className={clsx(
            'bg-gray-100 px-4 py-3.5 rounded w-20 outline-none text-gray-primary text-base focus:ring-1 focus:ring-black',
            { 'bg-red-100': fieldError }
          )}
          id={name}
          value={fieldValue}
          type="number"
          onChange={onChange}
          {...props}
        />
        <NumberInputButton onClick={handleIncrement}>
          <PlusIcon className="w-6 h-6" />
        </NumberInputButton>
        <NumberInputButton onClick={handleDecrement}>
          <MinusIcon className="w-6 h-6" />
        </NumberInputButton>
      </div>
      {fieldError && (
        <p className="ml-1 text-sm text-red-600">{getTranslation(t, `error.${fieldError}`)}</p>
      )}
    </div>
  );
};

export default NumberInput;
