import React from 'react';
import clsx from 'clsx';

export default function OverlapHeading({
  backgroundHeadingColor,
  foreGroundHeadingColor,
  backgroundHeading,
  foreGroundHeading,
  backgroundHeadingClassName,
  foregroundHeadingClassName,
  foreheadHeightCenter = false,
  center = true,
  hasTopMargin = true,
  isBackgroundHeadingUpperCase = true,
  showBackgroundHeadingonMobile = false,
  isFullWidth = true,
  bigBackground
}) {
  const bHeadingColor =
    typeof backgroundHeadingColor === 'string' ? backgroundHeadingColor : 'text-cgold-500';
  const fHeadingColor =
    typeof foreGroundHeadingColor === 'string' ? foreGroundHeadingColor : 'text-black';

  return (
    <div
      className={clsx(
        'relative',
        `${center ? 'mb-0 text-center' : 'mb-6'}`,
        { 'md:mt-14': hasTopMargin },
        {
          'w-full': isFullWidth
        },
        {
          'h-16 md:h-auto': bigBackground
        }
      )}>
      {backgroundHeading && (
        <h2
          className={clsx(
            'md:block mx-10 md:text-9xl md:leading-[6.8rem] text-5xl leading-[2.4rem] opacity-40 font-bold',
            { 'text-center': center },
            { 'text-8xl': bigBackground },
            bHeadingColor,
            { 'uppercase lg:text-9xl': isBackgroundHeadingUpperCase },
            { 'lg:text-[9rem] leading-none': !isBackgroundHeadingUpperCase },
            { hidden: !showBackgroundHeadingonMobile },
            backgroundHeadingClassName
          )}>
          {backgroundHeading}
        </h2>
      )}
      <div
        className={clsx(
          { 'md:absolute md:inset-0 flex w-full': backgroundHeading },
          { 'md:block md:static ': !backgroundHeading },
          `${center ? 'items-end' : 'items-center'}`,
          `${foreheadHeightCenter ? '!mb-0' : ''}`,
          'justify-center md:justify-start',
          `${showBackgroundHeadingonMobile ? 'absolute inset-0 mb-0' : 'relative'}`
        )}>
        <h2
          className={clsx(
            'text-xl leading-[1rem] md:text-4xl md:leading-[2.25rem] font-bold text-center w-full pb-2',
            center ? 'md:text-center' : '',
            fHeadingColor,
            foregroundHeadingClassName
          )}
          dangerouslySetInnerHTML={{ __html: foreGroundHeading }}></h2>
      </div>
    </div>
  );
}
