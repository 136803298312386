export default class timeSlot {
  time = '';
  showTime = '';
  isFirstClick = false;
  isBooked = false;
  isStartOfWorkshop = false;
  isReserved = false;

  constructor(time, showTime) {
    this.time = time;
    this.showTime = showTime;
  }
}
