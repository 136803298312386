import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import AdminInput from '../common/AdminInput';
import AdminTextArea from '../common/AdminTextarea';
import Button from '../common/Button';
import InputSelect from '../common/InputSelect';
import { getTranslation } from '../../utils/translation';
import { bookProfileTypeSchema } from '../../schema/profileBooking.schema';
import { getTimesList } from '../../utils/date';
import { toast } from 'react-toastify';
import { POPUP_DEFAULT_VALUES } from '../../data/default-values';
import { bookProfileTypeApi } from '../../service';

const initialValues = {
  date: '',
  time: '',
  location: '',
  professionName: '',
  comments: ''
};

const BookProfileTypeForm = ({ formDetails, setContentPopupInfo }) => {
  const { t, i18n } = useTranslation();

  const fieldEnaled = (fieldName) => {
    return formDetails.find((field) => field.id === fieldName)?.enabled;
  };

  const formSubmitHandler = async (values) => {
    try {
      const receiverAddress = formDetails.find((field) => field.id === 'reciever-address')?.value;

      await bookProfileTypeApi({ ...values, receiverAddress, lang: i18n.language });

      toast.success(getTranslation(t, 'bookProfileType.success'));
      setContentPopupInfo(POPUP_DEFAULT_VALUES);
    } catch (error) {
      toast.error(getTranslation(t, 'bookProfileType.error'));
      console.log(error);
    } finally {
      formik.resetForm();
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: bookProfileTypeSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: formSubmitHandler
  });

  return (
    <form onSubmit={formik.handleSubmit} className="mt-5 block w-full mx-auto px-5">
      <h1
        className="text-2xl font-bold text-center text-cblack-primary mb-2.5"
        dangerouslySetInnerHTML={{ __html: getTranslation(t, 'bookProfileType.h1') }}
      />
      <p
        className="text-center text-gray-primary mb-2 leading-7"
        dangerouslySetInnerHTML={{ __html: getTranslation(t, 'bookProfileType.h2') }}
      />
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full mt-6">
        {fieldEnaled('date-time') && (
          <>
            <AdminInput
              label={getTranslation(t, 'bookProfileType.fields.date')}
              placeholder={getTranslation(t, 'bookProfileType.placeholder.date')}
              name="date"
              type={'date'}
              min={new Date().toISOString().split('T')[0]}
              defaultMargin={'mb-4 w-full'}
              formik={formik}
            />
            <InputSelect
              containerClassName="w-full"
              className={'mt-7'}
              name="time"
              value={formik.values.time}
              options={getTimesList().map((time) => ({ value: time, label: time }))}
              menuPlacement={'top'}
              touched={formik.touched.time}
              error={t(formik.errors.time)}
              onChange={(value) => formik.setFieldValue('time', value)}
            />
          </>
        )}
        {fieldEnaled('location') && (
          <AdminInput
            label={getTranslation(t, 'bookProfileType.fields.location')}
            placeholder={getTranslation(t, 'bookProfileType.placeholder.location')}
            name="location"
            defaultMargin={'mb-4 w-full col-span-2'}
            formik={formik}
          />
        )}
        {fieldEnaled('profile-profession-name') && (
          <AdminInput
            label={getTranslation(t, 'bookProfileType.fields.professionName')}
            placeholder={getTranslation(t, 'bookProfileType.placeholder.professionName')}
            name="professionName"
            defaultMargin={'mb-4 w-full col-span-2'}
            formik={formik}
          />
        )}
        {fieldEnaled('comments') && (
          <AdminTextArea
            label={getTranslation(t, 'bookProfileType.fields.comments')}
            subtext={getTranslation(t, 'bookProfileType.subtext.comments')}
            placeholder={getTranslation(t, 'bookProfileType.placeholder.comments')}
            maxCharacters={100}
            name="comments"
            defaultMargin={'mb-4 w-full col-span-2'}
            formik={formik}
          />
        )}
      </div>

      <div className="text-center mt-6 mb-2 flex justify-center mx-auto">
        <Button
          type="submit"
          buttonType="primary"
          loading={formik.isSubmitting}
          loadingColor={'#fff'}
          disabled={formik.isSubmitting || Object.keys(formik.errors)?.length > 0}
          data-testid="submit-button">
          {getTranslation(t, 'bookProfileType.button')}
        </Button>
      </div>
    </form>
  );
};
export default BookProfileTypeForm;
