import React, { useContext } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { AdminContext } from '../../contexts/admin.context';
import { getTranslation } from '../../utils/translation';

export default function AdminTextArea({
  name,
  label,
  subtext,
  error,
  formik,
  isDark = false,
  className,
  type,
  defaultMargin = 'mb-8',
  focus = true,
  maxCharacters,
  ...props
}) {
  const { themeConfig: { buttonRadius } = {} } = useContext(AdminContext);

  const onChange = formik?.handleChange;
  const fieldValue = formik?.values[name];
  const fieldTouched = formik?.touched[name];
  const fieldError = formik?.errors[name];

  const { t } = useTranslation();

  return (
    <div className={`${defaultMargin} signupinwrap`}>
      {label && (
        <label className="block mb-1 text-base font-bold text-cblack-primary">{label}</label>
      )}
      {subtext && <p className="text-sm text-gray-primary mb-2 leading-5">{subtext}</p>}
      <div className="relative">
        <textarea
          className={clsx(
            `px-4 py-3.5 w-full outline-none text-gray-primary text-base bg-gray-100 min-h-[140px] rounded-md ${className}`,
            {
              'bg-input': isDark
            },
            {
              'focus:ring-1': focus
            },
            {
              'focus:ring-black': focus
            },
            { 'bg-red-100': fieldError && fieldTouched }
          )}
          // style={{ borderRadius: buttonRadius }}
          id={name}
          value={fieldValue}
          onChange={(e) => {
            const value = e.target.value;
            if (maxCharacters && value.length > maxCharacters) return;
            onChange(e);
          }}
          {...props}
        />

        {maxCharacters && (
          <div className="flex w-full justify-between items-center text-sm text-gray-primary">
            <span
              className="text-black"
              dangerouslySetInnerHTML={{ __html: getTranslation(t, 'textArea.maxCharacters') }}
            />
            <span>
              {fieldValue.length}/{maxCharacters}
            </span>
          </div>
        )}
      </div>
      {fieldTouched && fieldError && (
        <p className="ml-1 text-sm text-red-600">{getTranslation(t, `error.${fieldError}`)}</p>
      )}
    </div>
  );
}

AdminTextArea.defaultProps = {
  className: ''
};
