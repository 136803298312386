import { useTranslation } from 'react-i18next';
import { RightArrow } from '../icons';
import { getTranslation } from '../../utils/translation';

const LandingContact = () => {
  const { t } = useTranslation();

  var contact = t('landing.contact', { returnObjects: true });

  const primary = '#005F61';

  const validImagePathOrFullURL = (value) => {
    if (value) {
      return value.startsWith('http') || value.startsWith('/');
    }
    return false;
  };

  return (
    <div className="my-12 px-12 m-auto w-full max-w-[1000px] font-bold grid grid-cols-1 sm:grid-cols-2 gap-10">
      {validImagePathOrFullURL(getTranslation(t, 'landing.contact.image')) && (
        <img
          src={getTranslation(t, 'landing.contact.image')}
          alt="contact"
          className="block w-full"
        />
      )}
      <div className="w-full">
        <div
          className="!leading-[2.5rem] md:!leading-[3rem] text-2xl sm:text-4xl md:text-start w-full pb-2 mb-5 font-extrabold text-cblack-secondary"
          dangerouslySetInnerHTML={{ __html: getTranslation(t, 'landing.contact.info') }}
        />
        <a
          className="mb-3 flex items-center gap-2 w-full bg-left-bottom bg-no-repeat pb-1"
          style={{
            color: primary,
            backgroundImage: `linear-gradient(${primary} 0 0)`,
            backgroundSize: '20% 2px'
          }}
          href={`mailto:${getTranslation(t, 'landing.contact.email.value')}`}>
          <span
            dangerouslySetInnerHTML={{ __html: getTranslation(t, 'landing.contact.email.value') }}
          />
          <span style={{ color: primary }}>
            <RightArrow />
          </span>
        </a>

        <a
          className="mb-2 flex items-center gap-2 w-full bg-left-bottom bg-no-repeat pb-1"
          style={{
            color: primary,
            backgroundImage: `linear-gradient(${primary} 0 0)`,
            backgroundSize: '20% 2px'
          }}
          href={`tel:${getTranslation(t, 'landing.contact.phone.value')}`}>
          <span
            dangerouslySetInnerHTML={{ __html: getTranslation(t, 'landing.contact.phone.value') }}
          />
          <span style={{ color: primary }}>
            <RightArrow />
          </span>
        </a>
      </div>
    </div>
  );
};

export default LandingContact;
