export const getUserId = (user, theme, team, type) => {
  const loginPath = theme === 'ch' ? '/ch/login/' + team : '/login';
  if (!user) {
    window.location.href = loginPath;
    return;
  }

  try {
    if (type === 'account') {
      const { accounts } = user;
      return accounts[0].id;
    }

    const { id: userId } = user;
    return userId;
  } catch (error) {
    console.error('Error parsing user data:', error);
    window.location.href = loginPath;
  }
};
