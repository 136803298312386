import React, { useState } from 'react';
import i18next from 'i18next';
import { getTranslation } from '../../utils/translation';

const ClassTable = ({
  headers,
  rows,
  headerBgDark = '#000000',
  headerBg = '#ffffff',
  pagination = 0
}) => {
  const { t } = i18next;

  const [page, setPage] = useState(0);

  const changePage = (change) => {
    if (page + change < 0) return;
    if (page + change > rows.length / pagination) return;

    setPage(page + change);
  };

  return (
    <>
      {pagination > 0 && rows.length / pagination > 1 && (
        <div className="flex gap-2 items-center mb-6 sticky top-0 left-0 z-20">
          <p>
            {getTranslation(t, 'class.table.page')} {page + 1} /{' '}
            {Math.ceil(rows.length / pagination)}
          </p>
          <button
            className="border bg-transparent text-base p-2 rounded-md"
            onClick={() => changePage(-1)}>
            {getTranslation(t, 'class.table.previous')}
          </button>
          <button
            className="border bg-transparent text-base p-2 rounded-md"
            onClick={() => changePage(1)}>
            {getTranslation(t, 'class.table.next')}
          </button>
        </div>
      )}

      <table className={`w-full table-auto border-separate border-spacing-0 whitespace-nowrap`}>
        <thead>
          <tr>
            {headers.map((header, index) => {
              return (
                <th
                  key={index}
                  className={`sticky top-0 ${
                    index === 0 ? 'left-0 z-20' : ''
                  } color-[#515151] text-lg font-semibold border-[1px] ${
                    index === 0 ? 'rounded-tl-md' : ''
                  } ${index === headers?.length - 1 ? 'rounded-tr-md' : ''} ${
                    index !== 0 ? 'border-l-0' : ''
                  } border-[#606060] p-4`}
                  style={{ background: index === 0 ? headerBgDark : headerBg }}>
                  {header}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="bg-white">
          {rows.map((row, rowIndex) => {
            const numberOfRowsToShow = pagination ? pagination : rows.length;
            if (rowIndex < page * numberOfRowsToShow) return null;
            if (rowIndex >= (page + 1) * numberOfRowsToShow) return null;

            return (
              <tr key={rowIndex}>
                {row.map((rowData, tdIndex) => {
                  if (tdIndex === 0)
                    return (
                      <th
                        key={tdIndex}
                        className={`sticky left-0 z-20 text-lg border-[0.5px] border-t-0 ${
                          tdIndex !== 0 ? 'border-l-0' : ''
                        } ${rowIndex === rows?.length - 1 ? 'rounded-bl-md' : ''} ${
                          rowIndex === rows?.length - 1 && tdIndex === row?.length - 1
                            ? 'rounded-br-md'
                            : ''
                        } border-[#606060] p-4 text-left break-words`}
                        style={{ background: headerBgDark }}>
                        {rowData}
                      </th>
                    );

                  return (
                    <td
                      key={tdIndex}
                      className={`text-lg border-[0.5px] border-t-0 ${
                        tdIndex !== 0 ? 'border-l-0' : ''
                      } ${
                        rowIndex === rows?.length - 1 && tdIndex === row?.length - 1
                          ? 'rounded-br-md'
                          : ''
                      } border-[#606060] p-4 text-left break-words`}>
                      {rowData}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default ClassTable;
