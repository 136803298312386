import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import OverlapHeading from '../OverlapHeading';
import Slider from './slider';
import { getTranslation } from '../../utils/translation';
import { storeStats } from '../../service';

export default function Tools({ themeId, teamType, tRegion, setImagePopup }) {
  const [slideIndex, setSlideIndex] = useState(0);

  const { t } = useTranslation();

  const storeSlideStats = () => {
    let div = document.createElement('div');
    div.innerHTML = getTranslation(t, `landing.Tools.title_data`, true, slideIndex);

    storeStats({
      application: 'teacher_registration',
      event: 'slide_view',
      pageURL: window?.location?.pathname,
      pageTitle: document.title,
      orgPrefix: themeId?.toLowerCase(),
      teamType: teamType?.toLowerCase(),
      slideViewIndex: slideIndex + 1,
      slideTitle: div.textContent
    });
  };

  const updateTitle = (slideIndex) => {
    setSlideIndex(slideIndex);
  };

  useEffect(storeSlideStats, [slideIndex]);

  return (
    <div className="md:pt-0 pt-28 px-4 sm:px-14 lg:px-0">
      <OverlapHeading
        backgroundHeading={getTranslation(t, 'landing.Tools.background_title')}
        foreGroundHeading={getTranslation(t, `landing.Tools.title_data`, true, slideIndex)}
        backgroundHeadingColor={'text-swiss-150 md:!leading-[9.5rem] !leading-[3rem]'}
        foreGroundHeadingColor={'text-cblack-secondary'}
        foregroundHeadingClassName={'md:text-3xl lg:!text-5xl !md-leading-[4rem] font-extrabold'}
        backgroundHeadingClassName={
          'lg:!text-[200px] lg:leading-[156px] !opacity-60 bg !text-swiss-400'
        }
        isBackgroundHeadingUpperCase={false}
        foreheadHeightCenter={false}
        showBackgroundHeadingonMobile={true}
        center={true}
        hasTopMargin={false}
        bigBackground={true}
      />
      <Slider
        themeId={themeId}
        tRegion={tRegion}
        setImagePopup={setImagePopup}
        updateTitle={updateTitle}
      />
      {/* Added below because md:leading-tight was not being found otherwise */}
      <span className="hidden md:leading-tight" />
    </div>
  );
}
