export default function Header({ logo, heading, subheading }) {
  return (
    <>
      <div className="flex flex-col gap-5 items-center justify-center w-full mb-4">
        {logo && (
          <img
            src={process.env.REACT_APP_AWS_URL + logo}
            alt="Logo"
            className="max-h-full h-14 px-3"
          />
        )}
        {(heading || subheading) && (
          <div className="block">
            {heading && <p className="text-xl font-bold text-gray-900">{heading}</p>}
            {subheading && <p className="text-lg text-gray-400">{subheading}</p>}
          </div>
        )}
      </div>
    </>
  );
}
