import React from 'react';
import { RxCross2 } from 'react-icons/rx';

export default function CardPopup({ children, showPopup, popupDisplayHandler }) {
  if (!showPopup) {
    return <></>;
  }
  return (
    <div className="z-30 fixed top-0 left-0 w-full h-full flex items-center justify-center">
      <div className="mx-2 bg-white p-6 rounded-lg relative  sm:h-auto h-[100vh] overflow-auto m-0">
        <button
          className={'text-swiss-300 text-3xl bg-swiss-100 p-2 rounded-full  mb-5 right-6 top-6'}
          onClick={() => {
            popupDisplayHandler();
          }}>
          <RxCross2 />
        </button>
        <div>{children}</div>
      </div>
    </div>
  );
}
