import React, { useEffect, useState, useContext } from 'react';
import i18next from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  Outlet,
  useParams,
  useNavigate,
  useLocation
} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga4';
import { AdminContext } from './contexts/admin.context';

import { DashboardLayout, BugReport, MaintenanceScreen, CookieBanner } from './components/index';

import { fetchTranslations, getPortalDetails, storeStats, teacherApiDirect } from './service';
import {
  GenericLogin,
  AboutUs,
  ClassProject,
  Contact,
  Downloads,
  ForgetPassword,
  Introduction,
  ManageExistingProject,
  MissionEvent,
  MissionFuture,
  Overview,
  PasswordResetSuccess,
  Profile,
  SecurityCode,
  Welcome,
  RegistrationLandingPage,
  TransferStudent
} from './pages';
import SwissLanding from './pages/SwissLanding';

import 'react-toastify/dist/ReactToastify.css';

import { WorkshopProvider } from './contexts/workshop.context';
import Signup from './pages/signup';
import WorkshopInfo from './pages/WorkshopInfo';
import Workshops from './pages/Workshops';

import FullScreenLoader from './components/common/fullScreenLoader';
import { setOrgPrefix, setRegion, setTranslationTeamType } from './utils/translation';
import { getProjectTypes } from './utils';

import './App.css';
// let translationFile = {};

function App() {
  const location = window?.location;

  const [translationsLoading, setTranslationsLoading] = useState(true);

  const { themeId: theme, teamType: team, portalConfig } = useContext(AdminContext);
  const basePath = `/${theme}/${team}`;

  const originDomain = window.location.origin;

  const isSwissDomain = originDomain?.toLowerCase()?.includes('mission-future.swiss-skills.ch');

  useEffect(() => {
    if (!translationsLoading) return;

    i18next.init({
      interpolation: { escapeValue: false },
      saveMissing: false,
      returnEmptyString: true
    });
    i18next.languages = ['en', 'fr', 'de', 'it'];
    i18next.use(initReactI18next).init({
      fallbackLng: 'en',
      lng: 'fr',
      ns: ['translations'],
      defaultNS: 'translations'
    });

    // i18next.on('missingKey', function (lng, ns, key, fallbackValue) {
    //   const keyToSave = key.replace('ch.team.default.', '');
    //   const exists = _.get(translationFile, keyToSave);

    //   if (!exists) {
    //     _.set(translationFile, keyToSave, '');
    //   }

    //   // console.log('To Save', translationFile);
    // });

    const loadTranslations = async () => {
      const { data: { en, de, fr, it } = {} } = await fetchTranslations();

      i18next.addResourceBundle('en', 'translations', en);
      i18next.addResourceBundle('fr', 'translations', fr);
      i18next.addResourceBundle('de', 'translations', de);
      i18next.addResourceBundle('it', 'translations', it);

      let localLang = undefined;

      try {
        localLang = localStorage?.getItem('lang');
      } catch (error) {
        console.log('LS unavailable');
      }

      const oldLang = new URLSearchParams(window.location.search).get('lang') ?? localLang;
      if (['en', 'fr', 'de', 'it'].includes(oldLang)) {
        i18next.changeLanguage(oldLang);

        try {
          localStorage.setItem('lang', oldLang);
        } catch (error) {
          console.log('LS unavailable');
        }
      } else {
        i18next.changeLanguage('fr'); // fallback
      }

      setTranslationsLoading(false);
    };

    loadTranslations();
  }, [translationsLoading]);

  useEffect(() => {
    if (!location) return;

    try {
      storeStats({
        application: 'teachers_portal',
        event: 'view',
        pageURL: location.pathname,
        pageTitle: document.title,
        orgPrefix: isSwissDomain
          ? 'ch'
          : theme
          ? theme?.toLowerCase()
          : location.pathname?.includes('/fr')
          ? 'fr'
          : 'ch',
        teamType: team
          ? team?.toLowerCase()
          : location.pathname?.includes('/school')
          ? 'school'
          : 'team'
      });
    } catch (error) {
      console.log('Error storing stats', error);
    }
  }, [location]);

  useEffect(() => {
    if (!team) return;

    try {
      const cookiesAccepted = Cookies.get('mf-ssk-cookies') === 'true';

      if (isSwissDomain && cookiesAccepted) {
        if (team === 'school') {
          ReactGA.initialize('G-303V1X68RV');
        }
        if (team === 'team') {
          ReactGA.initialize('G-8PWP2R2JH9');
        }
      }
    } catch (error) {
      console.log('Error initializing analytics');
    }
  }, [team]);

  if (translationsLoading) return <FullScreenLoader />;

  return (
    <>
      <ToastContainer hideProgressBar={true} />
      <Router>
        <Routes>
          <Route path="/:orgPrefix/:team" element={<BaseContainer />}>
            <Route path="login" element={<GenericLogin />} />
            <Route path="forget-password" element={<ForgetPassword />} />
            <Route path="security-code" element={<SecurityCode />} />
            <Route path="password-reset-success" element={<PasswordResetSuccess />} />
            <Route path="landing" element={<RegistrationLandingPage />} />
            <Route path="signup" element={<Signup />} />
            <Route path="transfer-student" element={<TransferStudent />} />
            <Route path="transfer-student/:aid" element={<TransferStudent />} />

            {/* Dashboard links */}
            <Route path="" element={<DashboardLayout basePath={basePath} />}>
              {DashboardRoutes.map(({ path, element }, index) => {
                return <Route key={index} path={path} element={element} />;
              })}

              <Route
                path="workshops"
                element={
                  portalConfig?.hasWorkshopPortal ? (
                    <Workshops />
                  ) : (
                    <Navigate to={`${basePath}/welcome`} />
                  )
                }
              />
              <Route
                path="workshops/:id"
                element={
                  portalConfig?.hasWorkshopPortal ? (
                    <WorkshopProvider>
                      <WorkshopInfo />
                    </WorkshopProvider>
                  ) : (
                    <Navigate to={`${basePath}/welcome`} />
                  )
                }
              />
            </Route>
          </Route>

          <Route
            path="/school/:lang"
            element={
              !isSwissDomain ? (
                <Navigate replace to="/fr/school/login" />
              ) : (
                <BaseContainer paramOrg={'ch'} paramTeam={'school'}>
                  <SwissLanding themeId={'ch'} teamType={'school'} />
                </BaseContainer>
              )
            }
          />
          <Route
            path="/team/:lang"
            element={
              !isSwissDomain ? (
                <Navigate replace to="/fr/team/login" />
              ) : (
                <BaseContainer paramOrg={'ch'} paramTeam={'team'}>
                  <SwissLanding themeId={'ch'} teamType={'team'} />
                </BaseContainer>
              )
            }
          />
          <Route
            path="/school"
            element={
              !isSwissDomain ? (
                <Navigate replace to="/fr/school/login" />
              ) : (
                <BaseContainer paramOrg={'ch'} paramTeam={'school'}>
                  <SwissLanding themeId={'ch'} teamType={'school'} />
                </BaseContainer>
              )
            }
          />
          <Route
            path="/team"
            element={
              !isSwissDomain ? (
                <Navigate replace to="/fr/team/login" />
              ) : (
                <BaseContainer paramOrg={'ch'} paramTeam={'team'}>
                  <SwissLanding themeId={'ch'} teamType={'team'} />
                </BaseContainer>
              )
            }
          />
          {/* Old link of swiss team landing page to redirect to new page */}
          <Route path="/ch/landing" element={<Navigate replace to="/ch/team/landing" />} />
          {/* Old link of swiss team landing page to redirect to new page */}

          {/* Not found */}
          <Route path="/not-found" element={<NotFound />} />
          <Route
            path="/"
            element={
              !isSwissDomain ? (
                <Navigate replace to="/fr/school/login" />
              ) : (
                <Navigate replace to="/ch/school/login" />
              )
            }
          />
          <Route path="*" element={<Navigate replace to="/not-found" />} />
        </Routes>
      </Router>

      {isSwissDomain && <CookieBanner team={team} />}

      {process.env.NODE_ENV !== 'development' && <BugReport />}
    </>
  );
}

const DashboardRoutes = [
  { path: 'account', element: <Profile type="account" /> },
  { path: 'password', element: <Profile type="password" /> },
  { path: 'welcome', element: <Welcome /> },
  { path: 'guide', element: <Introduction /> },
  { path: 'mission-future-class', element: <MissionFuture /> },
  { path: 'mission-future-event', element: <MissionEvent /> },
  { path: 'create-project', element: <ClassProject /> },
  { path: 'projects', element: <ManageExistingProject /> },
  { path: 'purpose-and-objectives', element: <Overview /> },
  { path: 'contact', element: <Contact /> },
  { path: 'about-us', element: <AboutUs /> },
  { path: 'downloads', element: <Downloads /> }
];

const BaseContainer = ({ paramOrg, paramTeam, children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { i18n } = useTranslation();

  const {
    user,
    setThemeConfig,
    setThemeId,
    setTeamType,
    setTRegion,
    portalConfig,
    setPortalConfig
  } = useContext(AdminContext);

  const { orgPrefix: urlOrg, team: urlTeam } = useParams();

  const orgPrefix = urlOrg || paramOrg;
  const team = urlTeam || paramTeam;

  const [load, setLoad] = useState(false);
  const [maintenanceModeConfig, setMaintenanceModeConfig] = useState(false);
  const [navigationHistory, setNavigationHistory] = useState([location.pathname]);
  const [startTime, setStartTime] = useState(Date.now());

  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const {
          data: {
            masterOrg: {
              customConfigurations,
              favicon,
              schoolFavicon,
              teamFavicon,
              languages,
              teacherPortalHTMLTitle,
              logo,
              schoolLogo,
              teamLogo
            } = {},
            portalDetails,
            regions,
            establishments,
            academies,
            service: {
              name,
              isActive,
              hasAdminPortal,
              hasClassStudentSetup,
              hasRegistrationFormIframe,
              hasRegistrationWebpage,
              hasWorkshopPortal
            } = {}
          } = {}
        } = await getPortalDetails(orgPrefix, team);

        // if (!isActive) {
        //   navigate(`/not-found`);
        //   return;
        // }

        try {
          // Check if corrent language is applied
          const lang = i18n.language;
          const localLang = localStorage.getItem('lang');
          if (localLang !== lang || !languages.includes(lang)) {
            i18n.changeLanguage(languages[0]);
          }
        } catch (error) {
          console.log('Error setting language');
        }

        let selectedFavicon = '';

        if (team === 'school' && schoolFavicon) {
          selectedFavicon = schoolFavicon;
        } else if (team === 'team' && teamFavicon) {
          selectedFavicon = teamFavicon;
        } else {
          selectedFavicon = favicon;
        }

        if (selectedFavicon) {
          changeFavicon(process.env.REACT_APP_AWS_URL + selectedFavicon);
        }

        if (teacherPortalHTMLTitle) document.title = teacherPortalHTMLTitle;

        setThemeConfig({
          ...customConfigurations?.colors?.mf,
          buttonRadius: customConfigurations?.buttonRadius ?? '0px'
        });

        setPortalConfig({
          ...portalDetails,
          name,
          languages,
          logo,
          schoolLogo,
          teamLogo,
          regions,
          establishments,
          academies,
          isActive,
          hasAdminPortal,
          hasClassStudentSetup,
          hasRegistrationFormIframe,
          hasRegistrationWebpage,
          hasWorkshopPortal
        });
        setThemeId(orgPrefix);
        setTeamType(team);
        setTRegion('');

        // For translation
        setOrgPrefix(orgPrefix);
        setTranslationTeamType(team);
        setRegion('');

        // Direct teacher api url set
        teacherApiDirect.defaults.baseURL = portalDetails?.serverBaseUrl;

        if (portalDetails?.maintenanceMode?.enabled) {
          setMaintenanceModeConfig(portalDetails?.maintenanceMode);
        }

        setLoad(true);
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data || 'Portal not found');
        navigate(`/not-found`);
      }
    })();
  }, [orgPrefix, team]);

  useEffect(() => {
    const defaultProjectTypes = portalConfig?.classProjectTypes;
    const regionalProjectTypes = portalConfig?.regionalProjectTypes;
    const teacherRegion = user?.address?.[0]?.street;

    if (!teacherRegion && !(defaultProjectTypes || regionalProjectTypes)) return;

    let projectTypes = [];

    if (defaultProjectTypes || regionalProjectTypes) {
      if (teacherRegion && regionalProjectTypes) {
        const regionalPortalSetup = regionalProjectTypes?.[teacherRegion];
        if (regionalPortalSetup) {
          projectTypes = Object.keys(regionalPortalSetup).map((variant) => {
            return {
              id: variant,
              title: regionalPortalSetup?.[variant]?.title,
              enabled: regionalPortalSetup?.[variant]?.enabled
            };
          });
        }
      }

      if (!projectTypes.length && defaultProjectTypes)
        projectTypes = Object.keys(defaultProjectTypes)?.map((variant) => {
          return {
            id: variant,
            title: defaultProjectTypes[variant]?.title,
            enabled: defaultProjectTypes[variant]?.enabled
          };
        });
    } else {
      projectTypes = getProjectTypes(orgPrefix, team); // load locally
    }

    setPortalConfig((prev) => ({
      ...prev,
      projectTypes
    }));
  }, [user]);

  useEffect(() => {
    try {
      document.documentElement.lang = i18n.language;
    } catch (error) {
      console.log('Error setting language');
    }
  }, [i18n.language]);

  useEffect(() => {
    if (!location || !load) return;

    const lastLocation = navigationHistory[navigationHistory.length - 1];
    setNavigationHistory((prev) => [...prev, location.pathname]);

    if (lastLocation) {
      storeStats({
        application: 'teachers_portal',
        event: 'view',
        pageURL: lastLocation,
        pageTitle: document.title,
        orgPrefix: orgPrefix?.toLowerCase(),
        teamType: team?.toLowerCase(),
        timeSpent: (Date.now() - startTime) / 1000
      });

      setStartTime(Date.now());
    }

    try {
      storeStats({
        application: 'teachers_portal',
        event: 'view',
        pageURL: location.pathname,
        pageTitle: document.title,
        orgPrefix: orgPrefix?.toLowerCase(),
        teamType: team?.toLowerCase()
      });

      const storeClickStats = (e) => {
        const target = e.target;
        let targetText = target?.textContent;

        if (!targetText && target?.tagName === 'IMG') {
          targetText = target?.alt;
        }

        if (!targetText) {
          // Then find in its parent up to 5 levels
          let parent = target.parentElement;
          let count = 0;
          while (!targetText && count < 5) {
            targetText = parent?.textContent;
            parent = parent?.parentElement;
            count++;
          }
        }

        let targetURL = target.href;

        if (!targetURL && target.tagName === 'IMG') {
          targetURL = target.src;
        }

        if (!targetURL) {
          // Then find in its parent up to 5 levels
          let parent = target.parentElement;
          let count = 0;
          while (!targetURL && count < 5) {
            targetURL = parent?.href;
            if (!targetText && targetURL) targetText = parent?.title;
            parent = parent?.parentElement;
            count++;
          }
        }

        if (
          target.tagName === 'A' ||
          target.tagName === 'BUTTON' ||
          target.tagName === 'IMG' ||
          target.tagName === 'SPAN' ||
          target.tagName === 'P' ||
          target.tagName === 'svg'
        ) {
          if (!targetText && !targetURL) return;

          storeStats({
            application: 'teachers_portal',
            event: 'click',
            clickTarget: targetText,
            clickURL: targetURL,
            pageURL: location.pathname,
            pageTitle: document.title,
            orgPrefix: orgPrefix?.toLowerCase(),
            teamType: team?.toLowerCase()
          });
        }
      };

      const storeVimeoStats = () => {
        const vimeoIframes = document.querySelectorAll('iframe[src*="vimeo.com"]');
        vimeoIframes.forEach((iframe) => {
          if (!window.Vimeo) return;

          try {
            const player = new window.Vimeo.Player(iframe);
            player.on('play', function () {
              storeStats({
                application: 'teachers_portal',
                event: 'video_play',
                clickTarget: 'Vimeo Video',
                clickURL: iframe.src,
                pageURL: location.pathname,
                pageTitle: document.title,
                orgPrefix: orgPrefix?.toLowerCase(),
                teamType: team?.toLowerCase()
              });
            });
          } catch (error) {
            console.log('Error storing Vimeo stats', error);
          }
        });
      };

      const storeTimeSpent = () => {
        window.addEventListener('beforeunload', function () {
          storeStats({
            application: 'teachers_portal',
            event: 'view',
            timeSpent: (Date.now() - startTime) / 1000,
            pageURL: location.pathname,
            pageTitle: document.title,
            orgPrefix: orgPrefix?.toLowerCase(),
            teamType: team?.toLowerCase()
          });
        });
      };

      // Add overall buttons and links clicks stats
      document.addEventListener('click', storeClickStats);

      setTimeout(storeVimeoStats, 2000);

      storeTimeSpent();

      return () => {
        document.removeEventListener('click', storeClickStats);
      };
    } catch (error) {
      console.log('Error storing stats', error);
    }
  }, [load, location]);

  if (!load) return <FullScreenLoader />;

  if (maintenanceModeConfig?.enabled)
    return (
      <MaintenanceScreen
        showLogo={maintenanceModeConfig?.showLogo}
        html={maintenanceModeConfig.html}
      />
    );

  if (children) return children;

  return <Outlet />;
};

const NotFound = () => {
  return (
    <div className="z-50 backdrop-blur-sm fixed top-0 left-0 w-full h-full flex items-center justify-center">
      <p className="text-black text-2xl">Not found</p>
    </div>
  );
};

export default App;
