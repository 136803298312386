import React from 'react';

import { useTranslation } from 'react-i18next';
const Overview = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="px-20 pt-10">
        <div className="ml-4 mt-4">
          <div className="page-wrapper w-full block">
            <div className="w-full block border-b border-cblack-primary pb-5 mb-7">
              <h1 className="text-36p font-bold text-cblack-primary w-3/4 lineHeight-52 ">
                {t('rational-and-overview')}
              </h1>
            </div>
            <div>
              <h1 className="text-20p text-cblack-primary font-bold mt-7 mb-2.5">
                {t('rational-and-overview')}
              </h1>
              <p className="text-18p font-normal text-gray-primary w-full leading-7">
                {t('overview-para')}
                <br />
                <h1 className="text-20p text-cblack-primary font-bold mt-7 mb-2.5">
                  {t('objective')} :
                </h1>
                <ul className="list-disc ml-7 mt-2.5">
                  <li className="text-18p font-normal text-gray-primary w-full leading-7">
                    {t('overview-list-1')}
                  </li>
                  <li className="text-18p font-normal text-gray-primary w-full leading-7">
                    {t('overview-list-2')} :
                  </li>
                  <ul className="list-disc ml-7 mt-2.5">
                    <li className="text-18p font-normal text-gray-primary w-full leading-7">
                      {t('overview-list-3')}
                    </li>
                    <li className="text-18p font-normal text-gray-primary w-full leading-7">
                      {t('overview-list-4')}
                    </li>
                  </ul>
                  <li className="text-18p font-normal text-gray-primary w-full leading-7">
                    {t('overview-list-5')}
                  </li>
                  <li className="text-18p font-normal text-gray-primary w-full leading-7">
                    {t('overview-list-6')}
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
