import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminContext } from '../contexts/admin.context';
import { trackEvent } from '../utils';
import UserGuide from '../components/userGuide/userGuide';
import { getTranslation } from '../utils/translation';

const Introduction = () => {
  const { t } = useTranslation();
  const { themeId } = useContext(AdminContext);

  useEffect(() => {
    trackEvent('page_view', {
      organization: themeId === 'ch' ? 'SSK' : 'WSF'
    });
  }, []);

  return (
    <>
      <div className="flex flex-col items-start h-calc-vh overflow-y-auto">
        <div className="mb-5 w-full">
          <h1
            className="text-2xl font-bold text-left text-cblack-primary mb-2.5"
            dangerouslySetInnerHTML={{ __html: getTranslation(t, 'guide.header1') }}
          />
        </div>
        <UserGuide />
      </div>
    </>
  );
};

export default Introduction;
