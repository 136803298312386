import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AdminContext } from '../../contexts/admin.context';

import DownloadItem from '../common/DownloadItem';

const DownloadSection = ({ name, children, textSize = 'text-xl' }) => {
  return (
    <section className="mb-8">
      <h3
        className={`text-gray-primary mb-2 ${textSize}`}
        dangerouslySetInnerHTML={{
          __html: name
        }}
      />
      {children}
    </section>
  );
};

const PDFDownloads = () => {
  const { t, i18n } = useTranslation();

  const { themeId, teamType, tRegion } = useContext(AdminContext);

  const [list, setList] = useState([]);
  const [showText, setShowText] = useState(0);

  useEffect(() => {
    setList(t(`${teamType}.pdfDownloads`, { returnObjects: true })?.list);
  }, [i18n.language]);

  return (
    <>
      {list?.map &&
        list?.map((item, mainIndex) => {
          // Check if this download section is disabled here
          if (item?.heading?.[tRegion] !== undefined && item?.heading?.[tRegion] === '')
            return null;
          if (item?.heading?.[themeId] !== undefined && item?.heading?.[themeId] === '')
            return null;

          return (
            <DownloadSection
              key={mainIndex}
              textSize={'text-lg font-semibold'}
              name={
                item?.heading?.[tRegion]
                  ? item?.heading?.[tRegion]
                  : item?.heading?.[themeId]
                  ? item?.heading?.[themeId]
                  : item?.heading?.default
              }>
              {item?.downloads?.constructor === Array &&
                item?.downloads?.length > 0 &&
                item?.downloads?.map((download, index) => {
                  const infoItemIndex = `${mainIndex}.${index}`;

                  // Disabling by just not defining the org specific var and not even default var
                  let downloadDisabled =
                    (download?.name?.default == undefined &&
                      download?.name?.[tRegion] == undefined &&
                      download?.name?.[themeId] == undefined) ||
                    (download?.name?.[tRegion] !== undefined && download?.name?.[tRegion] === '') ||
                    (download?.name?.[themeId] !== undefined && download?.name?.[themeId] === '') ||
                    (download?.name?.default !== undefined && download?.name?.default === '');

                  if (downloadDisabled) return null;

                  return (
                    <>
                      <DownloadItem
                        key={infoItemIndex}
                        name={
                          download?.name?.[tRegion]
                            ? download?.name?.[tRegion]
                            : download?.name?.[themeId]
                            ? download?.name?.[themeId]
                            : download?.name?.default
                        }
                        link={
                          download?.link?.[tRegion]
                            ? download?.link?.[tRegion]
                            : download?.link?.[themeId]
                            ? download?.link?.[themeId]
                            : download?.link?.default
                        }
                        setShowText={setShowText}
                        directLink={true}
                        index={infoItemIndex}
                        isInfo={true}
                      />
                      {showText === infoItemIndex && (
                        <p
                          className={`text-gray-primary mb-2`}
                          dangerouslySetInnerHTML={{
                            __html: download?.info?.[tRegion]
                              ? download?.info?.[tRegion]
                              : download?.info?.[themeId]
                              ? download?.info?.[themeId]
                              : download?.info?.default
                          }}></p>
                      )}
                    </>
                  );
                })}
            </DownloadSection>
          );
        })}
    </>
  );
};

export default PDFDownloads;
