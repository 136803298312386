export function hexToRgba(hex, opacity) {
  // Remove the # symbol from the hex code
  hex = hex.replace('#', '');

  // Convert the hex code to RGB values
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Convert the opacity percentage to a decimal value
  const alpha = parseInt(opacity) / 100;

  // Create the RGBA string
  const rgba = `rgba(${r}, ${g}, ${b}, ${alpha})`;

  return rgba;
}
