import React from 'react';

const QuantityField = ({ qty, onIncrement, onDecrement, primary }) => {
  return (
    <div className="qty_field_container flex ml-5 mt-1 mb-1">
      <div className="text-lg text-black mr-2 font-semibold slashed-zero tabular-nums min-w-[30px] flex items-center shadow-none">
        {qty}
      </div>

      <button
        className="qty_field_btn small drop-shadow-md flex w-8 h-8 bg-gray-100 text-[#006367] items-center justify-center text-[16px] ml-2 rounded-full text-center active:drop-shadow"
        onClick={onIncrement}>
        <svg
          style={{ fill: '#006367' }}
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="">
          <path
            d="M2 8.5C1.17157 8.5 0.5 9.17157 0.5 10C0.5 10.8284 1.17157 11.5 2 11.5V8.5ZM18 11.5C18.8284 11.5 19.5 10.8284 19.5 10C19.5 9.17157 18.8284 8.5 18 8.5V11.5ZM11.5 2C11.5 1.17157 10.8284 0.5 10 0.5C9.17157 0.5 8.5 1.17157 8.5 2L11.5 2ZM8.5 18C8.5 18.8284 9.17157 19.5 10 19.5C10.8284 19.5 11.5 18.8284 11.5 18H8.5ZM2 11.5H18V8.5H2V11.5ZM8.5 2L8.5 18H11.5L11.5 2L8.5 2Z"
            fill={primary}
          />
        </svg>
      </button>

      <button
        className="qty_field_btn small drop-shadow-md w-8 h-8 bg-gray-100 items-center justify-center flex text-[#006367] text-[16px] ml-3 rounded-full text-center active:drop-shadow"
        onClick={onDecrement}>
        <svg
          style={{ fill: '#006367' }}
          width="20"
          height="4"
          viewBox="0 0 20 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="">
          <path
            d="M2 0.5C1.17157 0.5 0.5 1.17157 0.5 2C0.5 2.82843 1.17157 3.5 2 3.5V0.5ZM18 3.5C18.8284 3.5 19.5 2.82843 19.5 2C19.5 1.17157 18.8284 0.5 18 0.5V3.5ZM2 3.5H18V0.5H2V3.5Z"
            fill={primary}
          />
        </svg>
      </button>
    </div>
  );
};

export default QuantityField;
