import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../common/Button';
import { getTranslation } from '../../utils/translation';

export default function TargetGroup({ handlePopUp = () => {} }) {
  const { t } = useTranslation();

  var targetGroupData = t('landing.TargetGroup', { returnObjects: true });

  return (
    <div className="md:pt-0 pt-14">
      <h2
        className="sm:text-4xl text-2xl font-extrabold mb-5 text-center text-cblack-secondary"
        dangerouslySetInnerHTML={{ __html: getTranslation(t, 'landing.TargetGroup.title1') }}
      />
      <p
        className="text-center md:pb-6 pb-10 mt-[20px] text-base md:text-lg text-cblack-secondary"
        dangerouslySetInnerHTML={{ __html: getTranslation(t, 'landing.TargetGroup.newTitle') }}
      />
      <div className="text-center mb-2 flex justify-center mx-auto" onClick={() => handlePopUp()}>
        <Button
          type="submit"
          buttonType="ch-landing"
          loadingColor={'#fff'}
          data-testid="submit-button">
          <span
            dangerouslySetInnerHTML={{
              __html: getTranslation(t, 'landing.TargetGroup.button_content')
            }}
          />
        </Button>
      </div>
    </div>
  );
}
