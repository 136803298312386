import React from 'react';
import clsx from 'clsx';
import { getTranslation } from '../../utils/translation';

export const TimeSlotButton = ({
  timeSlot,
  index,
  selectTimeSlot,
  display,
  translationFn,
  primary,
  secondary
}) => {
  return (
    <button
      style={{
        display,
        color: primary,
        backgroundColor: timeSlot.isStartOfWorkshop
          ? primary
          : timeSlot.isReserved ||
            timeSlot.isBooked ||
            (timeSlot.isFirstClick && !timeSlot.isStartOfWorkshop)
          ? secondary
          : '',
        color:
          timeSlot.isStartOfWorkshop ||
          timeSlot.isReserved ||
          timeSlot.isBooked ||
          (timeSlot.isFirstClick && !timeSlot.isStartOfWorkshop)
            ? 'white'
            : ''
      }}
      key={index}
      onClick={() => selectTimeSlot(index)}
      className={clsx(
        'block w-full px-3 py-1.5 bg-gray-100 shadow text-[0.975rem] leading-6 appearance-none rounded-md text-center mb-4 hover:!font-normal transition-all'
      )}>
      {timeSlot.isStartOfWorkshop
        ? getTranslation(
            translationFn,
            'workshops.newWorkshop.selectDateAndTime.confirmButtonLabel'
          )
        : timeSlot.showTime}
    </button>
  );
};
