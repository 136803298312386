import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { getUserId } from '../utils/user';
import { changePassword, updateUser } from '../service';
import { AdminContext } from '../contexts/admin.context';
import { ChangePasswordSchema } from '../schema/profile.schema';

import Button from '../components/common/Button';
import { ThumbsUpIcon } from '../components/icons';
import AdminInput from '../components/common/AdminInput';
import { getTranslation } from '../utils/translation';

const ChangePassword = ({ isPopupDisplay = false }) => {
  const { setPopupInfo, setContentPopupInfo, themeId, user, teamType, setUser } =
    useContext(AdminContext);

  const { t } = useTranslation();

  const handleSubmit = async (formValues) => {
    const userId = getUserId(user, themeId, teamType);

    try {
      const response = await changePassword(
        {
          uid: userId,
          oldPwd: formValues.oldPwd,
          newPwd: formValues.newPwd
        },
        themeId,
        teamType
      );

      if (response.status === 200 && response.data.status === 200) {
        // hide if password is being updated in the popup
        if (isPopupDisplay) {
          setContentPopupInfo({ isVisible: false });

          // update user value in local storage

          user.specs.showPasswordUpdateRecommendation = true;
          setUser(user);
          try {
            localStorage.setItem('user', JSON.stringify(user));
          } catch (error) {
            console.log(error);
          }

          // update user info API
          try {
            await updateUser({
              ...user,
              spec: {
                ...user.specs,
                showPasswordUpdateRecommendation: true
              }
            });
          } catch (error) {
            toast.error(getTranslation(t, 'changePassword.errors.unableChangePassword'));
            console.error('Failed to update user', error);
          }
        }

        // show confirmation popup
        setPopupInfo({
          isVisible: true,
          icon: ThumbsUpIcon,
          heading: getTranslation(t, 'changePassword.passwordChanged')
        });

        formik.resetForm();
      }
    } catch (e) {
      toast.error(getTranslation(t, 'changePassword.errors.unableChangePassword'));
    }
  };

  const formik = useFormik({
    initialValues: {
      oldPwd: '',
      newPwd: '',
      confirmPwd: ''
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: handleSubmit
  });

  return (
    <>
      <div className={`md:mt-5 max-w-xl`}>
        <form onSubmit={formik.handleSubmit}>
          <AdminInput
            label={getTranslation(t, `changePassword.profile.password.formFields.oldPwd.label`)}
            placeholder={getTranslation(
              t,
              `changePassword.profile.password.formFields.oldPwd.placeholder`
            )}
            type="password"
            name="oldPwd"
            className={isPopupDisplay ? 'rounded-full bg-input-pop' : ''}
            isDark={isPopupDisplay}
            formik={formik}
          />
          <AdminInput
            label={getTranslation(t, `changePassword.profile.password.formFields.newPwd.label`)}
            subtext={getTranslation(t, `changePassword.profile.password.formFields.newPwd.subtext`)}
            placeholder={getTranslation(
              t,
              `changePassword.profile.password.formFields.newPwd.placeholder`
            )}
            type="password"
            name="newPwd"
            className={isPopupDisplay ? 'rounded-full bg-input-pop' : ''}
            isDark={isPopupDisplay}
            formik={formik}
          />
          <AdminInput
            label={getTranslation(t, `changePassword.profile.password.formFields.confirmPwd.label`)}
            placeholder={getTranslation(
              t,
              `changePassword.profile.password.formFields.confirmPwd.placeholder`
            )}
            type="password"
            name="confirmPwd"
            className={isPopupDisplay ? 'rounded-full bg-input-pop' : ''}
            isDark={isPopupDisplay}
            formik={formik}
          />
          <Button buttonType="primary" type="submit">
            {getTranslation(t, `changePassword.profile.password.actionButtons.save`)}
          </Button>
        </form>
      </div>
    </>
  );
};

export default ChangePassword;
