import React, { useState } from 'react';
import CardPopup from './common/CardPopup';
import { useTranslation } from 'react-i18next';
import { getTranslation } from '../utils/translation';

export default function SignUpPopup({ showPopup, setShowPopup, isStatus }) {
  const { t } = useTranslation();
  const popupDisplayHandler = () => {
    setShowPopup(!showPopup);
  };
  return (
    <section data-testid="signup-popup">
      <CardPopup showPopup={showPopup} popupDisplayHandler={popupDisplayHandler}>
        <div
          className="flex flex-col justify-center items-center text-center"
          data-testid="signup-popup">
          <div className="font-bold text-2xl my-9">
            {getTranslation(t, `signup.popup.${isStatus}.title`)}
          </div>
          <div className="my-4">{getTranslation(t, `signup.popup.${isStatus}.text1`)}</div>
          <div className="font-bold mb-9">
            {getTranslation(t, `signup.popup.${isStatus}.text2`)}
          </div>
        </div>
      </CardPopup>
    </section>
  );
}
