import React, { useState, useContext, useEffect } from 'react';
import { useFormik } from 'formik';

import { PersonalInfoSchema } from '../../schema/profile.schema';
import { useTranslation } from 'react-i18next';
import AdminInput from '../common/AdminInput';
import Button from '../common/Button';
import { updateUser } from '../../service';
import { toast } from 'react-toastify';
import { AdminContext } from '../../contexts/admin.context';
import { ThumbsUpIcon } from '../icons';
import { getTranslation } from '../../utils/translation';

const PersonalInfo = () => {
  const {
    setPopupInfo,
    user,
    setUser,
    portalConfig: { languages, registrationForm } = {},
    themeConfig: { buttonRadius } = {}
  } = useContext(AdminContext);
  const [genderOption, setGenderOption] = useState([]);

  const { t, i18n } = useTranslation();

  const fieldEnabled = (fieldName) => {
    return registrationForm.find((field) => field.id === fieldName)?.enabled;
  };

  const handleSubmit = async (formValues) => {
    const updatedValues = {
      ...user,
      id: formValues.email,
      salutation: formValues.title,
      firstname: formValues.firstName,
      lastname: formValues.lastName,
      phone: formValues.tel,
      languageId: formValues.language ?? 'fr',
      specs: {
        ...user.specs,
        jsonFunction: formValues.jsonFunction
      }
    };

    try {
      const response = await updateUser(updatedValues);
      setUser(updatedValues);
      try {
        localStorage.setItem('user', JSON.stringify(updatedValues));
      } catch (error) {
        console.log(error);
      }
      // show error
      if (response.status !== 200 || response.data.status !== 200) {
        throw new Error();
      }

      // show succcess popup
      setPopupInfo({
        isVisible: true,
        icon: ThumbsUpIcon,
        heading: getTranslation(t, 'personalInfo.changesUpdated')
      });
    } catch (error) {
      toast.error(getTranslation(t, 'personalInfo.errors.unableUploadInfo'));
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      title: '',
      firstName: '',
      lastName: '',
      tel: '',
      language: '',
      jsonFunction: ''
    },
    validationSchema: PersonalInfoSchema,
    onSubmit: handleSubmit
  });

  // Set current values
  useEffect(() => {
    const chGender = [
      { id: 'mr', name: 'male' },
      { id: 'mrs', name: 'female' },
      { id: '-', name: 'diverse' }
    ];

    const genOptions = chGender;
    setGenderOption(genOptions);

    const formValues = {
      email: user && user.id ? user.id : '',
      title: user && user.salutation ? user.salutation : '',
      firstName: user && user.firstname ? user.firstname : '',
      lastName: user && user.lastname ? user.lastname : '',
      jsonFunction: user && user.specs?.jsonFunction ? user.specs?.jsonFunction : '',
      language: user && user.languageId ? user.languageId : i18n.language,
      tel: user && user.phone ? user.phone : ''
    };

    formik.setValues(formValues, false);
  }, [user]);

  return (
    <>
      <div className="mt-5 max-w-xl">
        <div className="mb-5 w-full">
          <h1
            className="text-2xl font-bold text-left text-cblack-primary mb-2.5"
            dangerouslySetInnerHTML={{
              __html: getTranslation(t, `personalInfo.profile.personalInfo.title`)
            }}
          />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <AdminInput
            label={getTranslation(
              t,
              `personalInfo.profile.personalInfo.formFields.emailAddress.label`
            )}
            placeholder={getTranslation(
              t,
              `personalInfo.profile.personalInfo.formFields.emailAddress.placeholder`
            )}
            type="email"
            name="email"
            formik={formik}
          />
          {fieldEnabled('gender') && (
            <div className="my-8">
              <p className="text-base font-bold text-cblack-primary mb-2">
                {getTranslation(t, `personalInfo.profile.personalInfo.formFields.title.label`)}
              </p>
              <select
                className={`bg-gray-100 px-4 py-3.5 text-base text-gray-primary outline-none border-none w-full rounded-md`}
                style={{ borderRadius: buttonRadius }}
                id="title"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}>
                {genderOption.map((langOption) => (
                  <option value={langOption.id} key={langOption.id}>
                    {getTranslation(t, `personalInfo.gender.${langOption.name}`)}
                  </option>
                ))}
              </select>
            </div>
          )}
          <AdminInput
            label={getTranslation(
              t,
              `personalInfo.profile.personalInfo.formFields.firstName.label`
            )}
            placeholder={getTranslation(
              t,
              `personalInfo.profile.personalInfo.formFields.firstName.placeholder`
            )}
            type="text"
            name="firstName"
            formik={formik}
          />
          <AdminInput
            label={getTranslation(t, `personalInfo.profile.personalInfo.formFields.surname.label`)}
            placeholder={getTranslation(
              t,
              `personalInfo.profile.personalInfo.formFields.surname.placeholder`
            )}
            type="text"
            name="lastName"
            formik={formik}
          />

          {fieldEnabled('functionTask') && (
            <AdminInput
              label={getTranslation(t, `personalInfo.profile.personalInfo.formFields.role.label`)}
              placeholder={getTranslation(
                t,
                `personalInfo.profile.personalInfo.formFields.role.placeholder`
              )}
              type="text"
              name="jsonFunction"
              formik={formik}
            />
          )}
          {languages?.length > 1 && (
            <>
              <div className="my-8">
                <p className="text-base font-bold text-cblack-primary mb-2">
                  {getTranslation(t, `personalInfo.profile.personalInfo.formFields.language.label`)}
                </p>
                <select
                  className={`bg-gray-100 px-4 py-3.5 text-base text-gray-primary outline-none border-none w-full rounded-md`}
                  style={{ borderRadius: buttonRadius }}
                  id="language"
                  name="language"
                  value={formik.values.language}
                  onChange={formik.handleChange}>
                  {languages
                    .map((language) => {
                      return { id: language, name: getTranslation(t, `language.${language}`) };
                    })
                    .map((listlanguage) => (
                      <option value={listlanguage.id} key={listlanguage.id}>
                        {listlanguage.name}
                      </option>
                    ))}
                </select>
              </div>
            </>
          )}

          <AdminInput
            label={getTranslation(t, `personalInfo.profile.personalInfo.formFields.tel.label`)}
            placeholder={getTranslation(
              t,
              `personalInfo.profile.personalInfo.formFields.tel.placeholder`
            )}
            type="tel"
            name="tel"
            formik={formik}
          />
          <Button buttonType="primary" type="submit">
            {getTranslation(t, `personalInfo.profile.personalInfo.actionButtons.save`)}
          </Button>
        </form>
      </div>
    </>
  );
};

export default PersonalInfo;
