import React, { useContext } from 'react';
import Spinner from './Spinner';
import { AdminContext } from '../../contexts/admin.context';

export default function Button({
  children,
  buttonType = 'outline',
  bg = '',
  loading,
  disabled,
  loadingColor = '#fff',
  link,
  isExternalLink = false,
  extraClass = '',
  ...props
}) {
  const { themeConfig: { buttonRadius, primary, secondary } = {} } = useContext(AdminContext);

  let buttonClasses = `py-2 font-semibold text-md text-white hover:opacity-80 outline-none ${extraClass}`;

  switch (buttonType) {
    case 'outline':
      buttonClasses += ' border text-cblack-primary px-4 !bg-[transparent]';
      break;

    case 'primary':
    case 'customBG':
      buttonClasses += ' px-4';
      break;

    case 'login':
      buttonClasses += ' px-16 py-3 flex items-center justify-center gap-2';
      break;

    case 'ch-landing':
      buttonClasses +=
        ' bg-swiss-300 hover:bg-swiss-button-600 border-2 border-transparent hover:border-swiss-300 font-extrabold px-[25px] py-[12px] text-white rounded-full flex items-center justify-center gap-2 shadow-swissButton';
      break;

    case 'ch-landing-secondary':
      buttonClasses +=
        ' bg-swiss-button-secondary-500 px-[25px] py-[12px] text-white rounded-full flex items-center justify-center gap-2 font-extrabold hover:bg-swiss-button-secondary-600 hover:border-swiss-button-secondary-700';
      break;

    default:
      break;
  }
  return isExternalLink ? (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className={'block disabled:opacity-60 px-4 ' + buttonClasses + ' inline-flex'}
      style={{
        backgroundColor: buttonType.includes('ch-landing')
          ? ''
          : buttonType === 'customBG'
          ? bg
          : primary,
        borderRadius: buttonType.includes('ch-landing') ? '' : buttonRadius
      }}
      disabled={disabled}
      {...props}>
      {children}
    </a>
  ) : (
    <button
      className={'block disabled:opacity-60 ' + buttonClasses}
      style={{
        backgroundColor: buttonType.includes('ch-landing')
          ? ''
          : buttonType === 'customBG'
          ? bg
          : buttonType === 'outline'
          ? secondary
          : primary,
        borderColor: buttonType.includes('ch-landing')
          ? ''
          : buttonType === 'outline'
          ? primary
          : primary,
        color: buttonType.includes('ch-landing')
          ? ''
          : buttonType === 'customBG'
          ? '#515151'
          : buttonType === 'outline'
          ? primary
          : '',
        borderRadius: buttonType.includes('ch-landing') ? '' : buttonRadius
      }}
      disabled={disabled}
      {...props}>
      {loading && <Spinner color={buttonType === 'outline' ? primary : loadingColor} />} {children}
    </button>
  );
}
