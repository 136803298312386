import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PDFDownloads from '../components/downloads/pdfDownloads';
import { AdminContext } from '../contexts/admin.context';
import { trackEvent } from '../utils';
import { getTranslation } from '../utils/translation';

const Downloads = () => {
  const { t } = useTranslation();
  const { themeId } = useContext(AdminContext);

  useEffect(() => {
    trackEvent('page_view', {
      organization: themeId === 'ch' ? 'SSK' : 'WSF'
    });
  }, []);

  return (
    <>
      <div className={`flex flex-col items-start h-calc-vh overflow-y-auto max-w-100`}>
        <div className="mb-5 w-full">
          <>
            <h1
              className="text-2xl font-bold text-left text-cblack-primary mb-2.5"
              dangerouslySetInnerHTML={{ __html: getTranslation(t, 'pdfDownloads.header') }}
            />
          </>
        </div>
        <div className="overflow-y-auto w-full mini-scrollbar pr-4">
          <div className="mb-6">
            <b
              className="text-lg text-left mb-4"
              dangerouslySetInnerHTML={{ __html: getTranslation(t, 'pdfDownloads.heading2') }}></b>
            <p
              className="text-left text-gray-primary mb-2 leading-7"
              dangerouslySetInnerHTML={{ __html: getTranslation(t, 'pdfDownloads.desc') }}></p>
          </div>

          <PDFDownloads />
        </div>
      </div>
    </>
  );
};

export default Downloads;
