import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router';
import { AuthHeader, CrossBackButton } from '../components';
import { AdminContext } from '../contexts/admin.context';

import { WksMFLogo } from '../assets/logos';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getTranslation } from '../utils/translation';

const PasswordResetSuccess = () => {
  const {
    themeId: theme,
    teamType,
    themeConfig: { primary, buttonRadius } = {}
  } = useContext(AdminContext);
  const basePath = `/${theme}/${teamType}`;

  const location = useLocation();
  const email = location?.state?.email;

  const { t } = useTranslation();

  return (
    <>
      {/* <AuthHeader theme={theme} /> */}
      <div className={`flex flex-col md:flex-row`}>
        <CrossBackButton />
        <div className="mx-2 md:mx-auto md:max-w-3xl relative min-h-screen">
          <div className="bg-cblack mt-32 pb-2 mb-3">
            <h1 className="text-4xl font-bold md:text-cback mb-4">
              {getTranslation(t, 'passwordResetSuccess.title')}
            </h1>
            <p>
              {getTranslation(t, 'passwordResetSuccess.description1')} {email}
              {getTranslation(t, 'passwordResetSuccess.description2')}
            </p>
            <p className="mt-10">{getTranslation(t, 'passwordResetSuccess.helper')}</p>
          </div>
          <Link
            to={basePath + '/login'}
            className={`px-8 text-white py-3`}
            style={{ backgroundColor: primary, borderRadius: buttonRadius }}>
            {getTranslation(t, 'passwordResetSuccess.button')}
          </Link>
        </div>
      </div>
    </>
  );
};

export default PasswordResetSuccess;
