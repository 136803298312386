export const trackEvent = (event, data) => {
  if (typeof window.gtag === 'function') {
    window.gtag('event', event, data);
  }
};

export const getSortedList = (list, lang) => {
  return [...list]
    .filter((item) => item?.[`name_${lang}`])
    .sort((a, b) => a?.[`name_${lang}`].localeCompare(b?.[`name_${lang}`], lang));
};

export const getProjectTypes = (org, teamType) => {
  if (org === 'fr')
    return teamType === 'school'
      ? [
          { title: { fr: '[01] Projet de base' }, id: 'base' },
          {
            title: {
              fr: '[02] Visite WorldSkills 2024 A (préparation avec des classes différentes)'
            },
            id: 'special-a'
          },
          {
            title: { fr: '[03] Visite WorldSkills 2024 B (préparation avec la même classe)' },
            id: 'special-b'
          }
        ]
      : [
          {
            title: { fr: '[01] Visite WorldSkills 2024 (event type)' },
            id: 'special-a'
          }
        ];

  if (org == 'ch')
    return teamType === 'school'
      ? [
          {
            title: {
              en: '[1] Basic project: SwissSkills mission future School',
              de: '[1] Basisprojekt: SwissSkills mission future School',
              fr: '[1] Projec de base : SwissSkills mission future School',
              it: '[1] Progetto di base: SwissSkills mission future School'
            },
            id: 'base'
          }
        ]
      : [
          {
            title: {
              en: '[1] Basic project: SwissSkills mission future Team',
              de: '[1] Basisprojekt: SwissSkills mission future Team',
              fr: '[1] Projec de base : SwissSkills mission future Team',
              it: '[1] Progetto di base: SwissSkills mission future Team'
            },
            id: 'base'
          }
        ];
};
