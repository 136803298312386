import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminContext } from '../contexts/admin.context';
import { trackEvent } from '../utils';
import { getTranslation } from '../utils/translation';
import { RightArrow } from '../components/icons';

const Contact = () => {
  const { t } = useTranslation();

  const { themeConfig: { primary } = {} } = useContext(AdminContext);

  useEffect(() => {
    trackEvent('page_view', {
      organization: 'SSK'
    });
  }, []);

  const validImagePathOrFullURL = (value) => {
    if (value) {
      return value.startsWith('http') || value.startsWith('/');
    }
    return false;
  };

  return (
    <div className="w-full block mini-scrollbar overflow-y-auto">
      <div className="w-fit block">
        <h1 className="text-2xl text-cblack-primary font-bold mb-2.5">
          {getTranslation(t, 'contact.title')}
        </h1>
        <p className="text-gray-primary mb-2.5">{getTranslation(t, 'contact.subtitle')}</p>
      </div>
      <div className="mt-8 flex flex-row flex-wrap gap-5 w-full">
        {validImagePathOrFullURL(getTranslation(t, 'contact.image')) && (
          <img
            src={getTranslation(t, 'contact.image')}
            alt="contact"
            className="block w-auto max-h-72 max-w-1/2"
          />
        )}
        <div className="w-auto">
          <div
            className="mb-8"
            dangerouslySetInnerHTML={{ __html: getTranslation(t, 'contact.info') }}
          />

          <a
            className="mb-3 flex items-center gap-2 w-full bg-left-bottom bg-no-repeat pb-1"
            style={{
              color: primary,
              backgroundImage: `linear-gradient(${primary} 0 0)`,
              backgroundSize: '20% 2px'
            }}
            href={`mailto:${getTranslation(t, 'contact.email.value')}`}>
            <span dangerouslySetInnerHTML={{ __html: getTranslation(t, 'contact.email.value') }} />
            <span style={{ color: primary }}>
              <RightArrow />
            </span>
          </a>

          <a
            className="mb-2 flex items-center gap-2 w-full bg-left-bottom bg-no-repeat pb-1"
            style={{
              color: primary,
              backgroundImage: `linear-gradient(${primary} 0 0)`,
              backgroundSize: '20% 2px'
            }}
            href={`tel:${getTranslation(t, 'contact.phone.value')}`}>
            <span dangerouslySetInnerHTML={{ __html: getTranslation(t, 'contact.phone.value') }} />
            <span style={{ color: primary }}>
              <RightArrow />
            </span>
          </a>

          {/* <div className="flex gap-2">
            <p>{getTranslation(t, 'contact.website.label')}</p>
            <a
              className="text-swiss-300 underline"
              target={'_blank'}
              href={getTranslation(t, 'contact.website.value')}
              rel="noreferrer">
              {getTranslation(t, 'contact.website.value')}
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Contact;
