import React from 'react';
import { fetchCorrectTranslation } from '../../../utils/translation';

export default function SlideImage({ data, setImagePopup }) {
  return (
    <div>
      <div className="container mx-auto max-w-[100%]">
        <div className="img-slide sm:w-4/5 w-full mx-auto text-left py-6">
          <div className="flex flex-col md:flex-row items-center lg:gap-12 md:gap-10 gap-0 ">
            <div className="flex h-fit my-auto sm:my-0 align-center z-50 w-[85%] sm:w-[90%] lg:w-[70%] max-w-md mb-4 sm:mb-0 flex-col">
              <div
                className="bg-swiss-150 text-white p-4 md:p-8 mr-0 md:-mr-5 text-[12px] md:text-[1.125rem] leading-6 md:leading-7 rounded-sm md:rounded-md"
                dangerouslySetInnerHTML={{
                  __html: fetchCorrectTranslation(data.content)
                }}></div>
              {data.slide_button && (
                <div className="my-5 px-3 text-center justify-center flex md:hidden">
                  <button
                    onClick={() => {
                      if (data?.redirection_link) {
                        return window.open(
                          fetchCorrectTranslation(data.redirection_link),
                          '_blank'
                        );
                      }

                      if (data?.pdfUrl) {
                        return window.open(fetchCorrectTranslation(data.pdfUrl), '_blank');
                      }

                      setImagePopup(fetchCorrectTranslation(data.button_image_mobile));
                    }}
                    className="font-bold text-md border bg-swiss-300 px-8 py-4 text-white rounded-full flex items-center justify-center gap-2<">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: fetchCorrectTranslation(data.button_content)
                      }}
                    />
                  </button>
                </div>
              )}
            </div>

            <div className="px-0 md:px-0 sm:text-center">
              <img src={fetchCorrectTranslation(data.img)} className="img-desktop" alt="" />
              <img src={fetchCorrectTranslation(data.mbImg)} className="img-mobile" alt="" />
              {data.slide_button && (
                <div className="my-5 px-3 text-center justify-center hidden md:flex">
                  {data.redirection_link ? (
                    <button
                      onClick={() => {
                        window.open(fetchCorrectTranslation(data.redirection_link), '_blank');
                      }}
                      className={`font-bold text-md border px-8 py-4 text-white rounded-full flex items-center justify-center gap-2  ${
                        fetchCorrectTranslation(data.linkBlue)
                          ? 'bg-swiss-300 hover:!bg-swiss-200'
                          : 'bg-swiss-360 hover:!bg-swiss-380'
                      } `}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: fetchCorrectTranslation(data.button_content)
                        }}
                      />
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        data.pdfUrl
                          ? window.open(fetchCorrectTranslation(data.pdfUrl), '_blank')
                          : setImagePopup(fetchCorrectTranslation(data.button_image));
                      }}
                      className="font-bold text-md border bg-swiss-300 px-8 py-4 text-white rounded-full flex items-center justify-center gap-2 hover:!bg-swiss-200">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: fetchCorrectTranslation(data.button_content)
                        }}
                      />
                    </button>
                  )}
                </div>
                //
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
