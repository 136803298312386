import { createContext, useState } from 'react';

import { CONTENT_POPUP_DEFAULT_VALUES, POPUP_DEFAULT_VALUES } from '../data/default-values';

export const AdminContext = createContext();

export const AdminProvider = (props) => {
  const [themeId, setThemeId] = useState();
  const [teamType, setTeamType] = useState(); // school || team
  const [tRegion, setTRegion] = useState();
  const [user, setUser] = useState(null);
  const [themeConfig, setThemeConfig] = useState(null);
  const [portalConfig, setPortalConfig] = useState(null);
  const [popupInfo, setPopupInfo] = useState(POPUP_DEFAULT_VALUES);
  const [contentPopupInfo, setContentPopupInfo] = useState(CONTENT_POPUP_DEFAULT_VALUES);

  return (
    <AdminContext.Provider
      value={{
        popupInfo,
        setPopupInfo,
        contentPopupInfo,
        setContentPopupInfo,
        themeId,
        setThemeId,
        teamType,
        setTeamType,
        tRegion,
        setTRegion,
        user,
        setUser,
        themeConfig,
        setThemeConfig,
        portalConfig,
        setPortalConfig
      }}>
      {props.children}
    </AdminContext.Provider>
  );
};
