import ToggleCard from './ToggleCard';
import { useTranslation } from 'react-i18next';
import OverlapHeading from '../OverlapHeading';
import { fetchCorrectTranslation, getTranslation } from '../../utils/translation';

function WorkFlow() {
  const { t } = useTranslation();

  const workflowCards = getTranslation(t, 'landing.workflow.data', false, 0, 0, true);

  return (
    <div className="md:pt-12">
      <div className="mb-2">
        <OverlapHeading
          foreGroundHeading={getTranslation(t, 'landing.workflow.title')}
          backgroundHeadingColor={'text-swiss-150'}
          foreGroundHeadingColor={'text-cblack-secondary'}
          foregroundHeadingClassName={'sm:!text-4xl !text-2xl !md-leading-[4rem] font-extrabold'}
          backgroundHeadingClassName={
            'lg:!text-[200px] lg:leading-[156px] !opacity-30  !text-swiss-400'
          }
          isBackgroundHeadingUpperCase={false}
          foreheadHeightCenter={false}
          showBackgroundHeadingonMobile={true}
          center={true}
          hasTopMargin
        />
      </div>
      <div className="w-full p-0 sm:p-10 md:p-0 md:w-4/4 mx-auto heading-content">
        <div className="w-full py-10 sm:p-0 md:p-0 lg:w-2/4 md:w-3/4 mt-20 mx-auto heading-content">
          {Array.isArray(workflowCards) &&
            workflowCards.map((question, i) => {
              return (
                <ToggleCard
                  key={i}
                  data={{
                    q: fetchCorrectTranslation(question.q),
                    url: fetchCorrectTranslation(question.url)
                  }}
                  number={i + 1}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default WorkFlow;
