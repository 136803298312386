import React, { useContext } from 'react';

import { AdminContext } from '../../contexts/admin.context';

const MaintenanceScreen = ({ showLogo, html }) => {
  const { teamType, portalConfig: { schoolLogo, teamLogo } = {} } = useContext(AdminContext);

  console.log(teamLogo, schoolLogo, teamType);

  return (
    <div className="h-screen w-full flex items-center justify-center">
      <div className="w-full sm:w-2/3 p-2">
        {showLogo && (
          <img
            src={process.env.REACT_APP_AWS_URL + (teamType === 'school' ? schoolLogo : teamLogo)}
            alt="logo"
            className="h-40 block mb-5 mx-auto"
          />
        )}
        <div className="text-center" dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </div>
  );
};

export default MaintenanceScreen;
