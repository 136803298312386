import * as yup from 'yup';

export const PersonalInfoSchema = yup.object().shape({
  email: yup.string().email('validEmail').required('email'),
  title: yup.string().required('title'),
  firstName: yup.string().required('firstName'),
  lastName: yup.string().required('lastName'),
  tel: yup.string().optional(),
  jsonFunction: yup.string().optional()
});

export const AccountInfoSchema = yup.object().shape({
  accountId: yup.string(),
  establishment: yup.string(),
  academy: yup.string(),
  region: yup.string(),
  postalCode: yup.string(),
  city: yup.string()
});

export const ChangePasswordSchema = yup.object().shape({
  oldPwd: yup.string().required('oldPwd'),
  newPwd: yup
    .string()
    .required('newPwd')
    .min(10, 'minCharacters')
    .matches(/[a-z]/, 'smallCase')
    .matches(/[A-Z]/, 'upperCase')
    .matches(/[0-9]/, 'digit')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{}[\]|;:'",.<>/?]).{10,}$/,
      'specialCharacter'
    ),
  confirmPwd: yup
    .string()
    .required('confirmPwd')
    .oneOf([yup.ref('newPwd')], 'changePasswordMatch')
});
