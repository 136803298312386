import React from 'react';
import { useTranslation } from 'react-i18next';
import { fetchCorrectTranslation, getTranslation } from '../../../utils/translation';

export default function SlideIcons() {
  const { t } = useTranslation();

  const iconsList = getTranslation(t, 'landing.slider.iconData', false, 0, 0, true);

  return (
    <div>
      {iconsList.map((item, i) => {
        if (!fetchCorrectTranslation(item.svg) || !fetchCorrectTranslation(item.content))
          return null;

        return (
          <div
            key={i}
            className="flex flex-col space-around w-10/12 md:w-[80%] lg:w-[72%] items-center md:flex-row mx-auto sm:w-2/4 sm:mb-6 mb-5">
            <div className=" w-24 md:w-12 text-right relative">
              <div className="flex flex-row justify-center mb-5 md:mb-0 md:justify-end">
                <div
                  className=" border-1 border-swiss-300 w-[35px] h-[35px] rounded-full bg-white flex justify-center items-center"
                  dangerouslySetInnerHTML={{
                    __html: fetchCorrectTranslation(item.svg)
                  }}></div>
              </div>
            </div>
            <div
              className="grow sm:text-left text-center
               md:w-1/3 pl-0 md:pl-5 mb-6 md:mb-0">
              <p
                className="text-base text-black text-center md:text-left"
                dangerouslySetInnerHTML={{
                  __html: fetchCorrectTranslation(item.content)
                }}></p>
            </div>
          </div>
        );
      })}
    </div>
  );
}
