import React, { useContext } from 'react';
import { AdminContext } from '../../contexts/admin.context';
import { XIcon } from '../icons';

export default function ContentPopup({ onHide, maxWidth, content }) {
  const { themeConfig: { primary, buttonRadius } = {} } = useContext(AdminContext);
  return (
    <div className="z-50 backdrop-blur-sm fixed top-0 left-0 right-0 bottom-0 w-full flex items-center justify-center p-4">
      <div
        className={`p-4 bg-white w-full h-auto lg:w-10k flex flex-col items-center rounded shadow-lg max-h-[80%] overflow-y-auto ${maxWidth}`}>
        <button
          className="p-2 rounded-full text-white self-start shadow-lg"
          style={{ backgroundColor: primary, borderRadius: buttonRadius }}
          onClick={onHide}>
          <XIcon className="w-3 h-3" color="white" />
        </button>
        {content}
      </div>
    </div>
  );
}
