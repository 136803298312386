import React, { useContext, useEffect } from 'react';

import PersonalInfo from '../components/profile/PersonalInfo';
import AccountInfo from '../components/profile/AccountInfo';
import ChangePassword from './ChangePassword';
import { AdminContext } from '../contexts/admin.context';
import { useTranslation } from 'react-i18next';
import { trackEvent } from '../utils';
import { getTranslation } from '../utils/translation';

const Profile = ({ type }) => {
  const { t } = useTranslation();
  const { themeId } = useContext(AdminContext);

  useEffect(() => {
    trackEvent('page_view', {
      organization: themeId === 'ch' ? 'SSK' : 'WSF'
    });
  }, []);

  const accountInfoComponents = (params) => {
    return (
      <>
        <PersonalInfo />
        <AccountInfo />
      </>
    );
  };

  return (
    <>
      <div className="flex flex-col items-start h-calc-vh overflow-y-auto">
        <div className="overflow-y-auto mini-scrollbar w-full mb-4 px-1">
          {type === 'password' && (
            <div className="mb-5 w-full">
              <h1
                className="text-2xl font-bold text-left text-cblack-primary mb-2.5"
                dangerouslySetInnerHTML={{ __html: getTranslation(t, 'changePassword.title') }}
              />
            </div>
          )}
          {type === 'password' ? <ChangePassword /> : accountInfoComponents()}
        </div>
      </div>
    </>
  );
};

export default Profile;
