import { ThumbsUpIcon } from '../components/icons';
import timeSlot from '../components/common/timeSlot';
export const STATIC_CONTENT_LINK =
  'https://worldskills-videos.s3.eu-central-1.amazonaws.com/teachers-portal-static/';

export const DEFAULT_LANGUAGE = 'fr';

// TODO: remove all values
export const POPUP_DEFAULT_VALUES = {
  isVisible: false,
  icon: ThumbsUpIcon,
  heading: 'Your changes have been saved!',
  description: '',
  action: {
    text: '',
    type: '',
    onClick: null
  },
  textBox: {
    show: false,
    text: ''
  },
  footerNotes: null,
  actionList: null,
  actionListClick: null
};

export const CONTENT_POPUP_DEFAULT_VALUES = {
  isVisible: false,
  content: null
};

export const PDF_TEMPLATE_NAMES = {
  ACCESS_CODES: 'WSF-Enseignant',
  STRENGTH_PROFILES: { WSF: 'WSFMF-Strengths', SSK: 'MFTSSK_Strenghts' },
  PROFESSIONAL_ROLES_PROFILES: { WSF: 'WSFMF-Roles', SSK: 'MFTSSK_Roles' }
};

/**
 * Configuration for the different profile types. Includes the title and any other
 * necessary parameters for the download function.
 */
export const PROFILE_PDF_DOWNLOADS_CONFIG_WSF = {
  vip: {
    title: 'VIP-Profiles'
  },
  strength: {
    title: 'Strength-Profiles',
    tpl: PDF_TEMPLATE_NAMES.STRENGTH_PROFILES.WSF
  },
  roles: {
    title: 'Professional-Roles-Profiles',
    tpl: PDF_TEMPLATE_NAMES.PROFESSIONAL_ROLES_PROFILES.WSF
  },
  accessCodes: 'WSF-Enseignant'
};
export const PROFILE_PDF_DOWNLOADS_CONFIG_SSK = {
  vip: {
    title: 'VIP-Profiles'
  },
  strength: {
    title: 'Strength-Profiles',
    tpl: PDF_TEMPLATE_NAMES.STRENGTH_PROFILES.SSK
  },
  roles: {
    title: 'Professional-Roles-Profiles',
    tpl: PDF_TEMPLATE_NAMES.PROFESSIONAL_ROLES_PROFILES.SSK
  },
  accessCodes: 'SSKMF-Teacher2'
};

export const WORKSHOP_FIXED_VALUES = {
  workshopDuration: '1',
  maxParticipants: 18
};

export const LANGUAGE_STRINGS = {
  en: 'ENG',
  fr: 'FRA',
  de: 'GER',
  it: 'ITA'
};

export const WORKSHOP_DEFAULT_VALUE = {
  type: 'create-event',
  languageId: 'de',
  teamName: '',
  groupSize: 8,
  estimated_duration: '1 hours'
};

export const WORKSHOP_LIST_TYPE = {
  UPCOMING: 'UPCOMING',
  PAST: 'PAST'
};

export const TIME_SLOTS = [
  new timeSlot('00:00', '00:00'),
  new timeSlot('00:30', '00:30'),
  new timeSlot('01:00', '01:00'),
  new timeSlot('01:00', '01:30'),
  new timeSlot('02:00', '02:00'),
  new timeSlot('02:00', '02:30'),
  new timeSlot('03:00', '03:00'),
  new timeSlot('03:00', '03:30'),
  new timeSlot('04:00', '04:00'),
  new timeSlot('04:00', '04:30'),
  new timeSlot('05:00', '05:00'),
  new timeSlot('05:00', '05:30'),
  new timeSlot('06:00', '06:00'),
  new timeSlot('06:00', '06:30'),
  new timeSlot('07:00', '07:00'),
  new timeSlot('07:30', '07:30'),
  new timeSlot('08:00', '08:00'),
  new timeSlot('08:30', '08:30'),
  new timeSlot('09:00', '09:00'),
  new timeSlot('09:30', '09:30'),
  new timeSlot('10:00', '10:00'),
  new timeSlot('10:30', '10:30'),
  new timeSlot('11:00', '11:00'),
  new timeSlot('11:30', '11:30'),
  new timeSlot('12:00', '12:00'),
  new timeSlot('12:30', '12:30'),
  new timeSlot('13:00', '13:00'),
  new timeSlot('13:30', '13:30'),
  new timeSlot('14:00', '14:00'),
  new timeSlot('14:30', '14:30'),
  new timeSlot('15:00', '15:00'),
  new timeSlot('15:30', '15:30'),
  new timeSlot('16:00', '16:00'),
  new timeSlot('16:30', '16:30'),
  new timeSlot('17:00', '17:00'),
  new timeSlot('17:30', '17:30'),
  new timeSlot('18:00', '18:00'),
  new timeSlot('18:30', '18:30'),
  new timeSlot('19:00', '19:00'),
  new timeSlot('19:30', '19:30'),
  new timeSlot('20:00', '20:00'),
  new timeSlot('20:30', '20:30'),
  new timeSlot('21:00', '21:00'),
  new timeSlot('21:30', '21:30'),
  new timeSlot('22:00', '22:00'),
  new timeSlot('22:30', '22:30'),
  new timeSlot('23:00', '23:00'),
  new timeSlot('23:30', '23:30')
];
